import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  getLineGroups,
  getLineGroupsSuccess,
  deleteLineGroup,
  deleteLineGroupSuccess,
  addLineGroup,
  addLineGroupSuccess,
  setDefaultLineGroup,
  setDefaultLineGroupSuccess,
} from './slice';

function* getLineGroupsSaga() {
  const response = yield call(httpClient.instance.get, '/regroupements');
  if (response.status !== undefined && response.status === 200) {
    yield put(getLineGroupsSuccess(response.data));
  }
}

function* addLineGroupSaga(action) {
  const data = action.payload;
  try {
    const response = yield call(httpClient.instance.post, `/regroupements`, {
      dossier: data.dossier,
      typeLigne: data.lineType,
      typeDoc: data.docType,
    });
    if (response.status !== undefined && response.status === 200) {
      yield put(addLineGroupSuccess(response.data));
    }
  } catch (err) {
    console.error('ADD_LINE_GROUP_ERROR:', err);
  }
}

function* deleteLineGroupSaga(action) {
  const { dossier, lineType, docType } = action.payload;
  const response = yield call(
    httpClient.instance.delete,
    `/regroupements/${dossier}/${lineType}/${docType}`,
  );
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteLineGroupSuccess(action.payload));
  }
}

function* setDefaultLineGroupSaga(action) {
  const { dossier, lineType, docType } = action.payload;
  const response = yield call(
    httpClient.instance.put,
    `/regroupements/setdefaut/${dossier}/${lineType}/${docType}`,
  );
  if (response.status !== undefined && response.status === 200) {
    yield put(setDefaultLineGroupSuccess(action.payload));
  }
}

export default function* lineGroupsSaga() {
  yield takeLatest(getLineGroups, getLineGroupsSaga);
  yield takeLatest(addLineGroup, addLineGroupSaga);
  yield takeLatest(deleteLineGroup, deleteLineGroupSaga);
  yield takeLatest(setDefaultLineGroup, setDefaultLineGroupSaga);
}
