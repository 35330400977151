import {
  useTable,
  useRowSelect,
  useSortBy,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter,
} from 'react-table';
import PropTypes from 'prop-types';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { addSelected } from '../../store/payments/slice';
import { Button, Col, Input, Row, Table } from 'reactstrap';
import { Filter } from '../../components/filters';

function PaymentsTable({ columns, data, className, buttons }) {
  const dispatch = useDispatch();

  const selectedRowIds = []
  for (const [i, row] of data.entries()) {
    if (!row.alert) {
      selectedRowIds[i] = true;
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        selectedRowIds,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          // eslint-disable-next-line react/prop-types
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // eslint-disable-next-line react/prop-types
          Cell: ({ row }) => (
            <div>
              {/* eslint-disable-next-line react/prop-types */}
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    dispatch(addSelected(selectedFlatRows.map((d) => d.original)));
  }, [selectedFlatRows.length, selectedRowIds]);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Row className='mb-2'>
        <Col md={1}>
          <select className='form-select' value={pageSize} onChange={onChangeInSelect}>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {buttons && (
          <Col sm='7'>
            <div className='text-sm-end'>{buttons}</div>
          </Col>
        )}
      </Row>

      <div className='table-responsive react-table'>
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className='table-light table-nowrap'>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id}>
                    <div className='mb-2' {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {generateSortingIndicator(column)}
                      <Filter column={column} />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(
              (row) =>
                prepareRow(row) || (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                ),
            )}
          </tbody>
        </Table>
      </div>

      <Row className='justify-content-md-end justify-content-center align-items-center'>
        <Col className='col-md-auto'>
          <div className='d-flex gap-1'>
            <Button color='primary' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </Button>
            <Button color='primary' onClick={previousPage} disabled={!canPreviousPage}>
              {'<'}
            </Button>
          </div>
        </Col>
        <Col className='col-md-auto d-none d-md-block'>
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>
        </Col>
        <Col className='col-md-auto'>
          <Input
            type='number'
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className='col-md-auto'>
          <div className='d-flex gap-1'>
            <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
              {'>'}
            </Button>
            <Button color='primary' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Fragment>
      <Col md={4}>
        <div className='search-box me-xxl-2 my-3 my-xxl-0 d-inline-block'>
          <div className='position-relative'>
            <label htmlFor='search-bar-0' className='search-label'>
              <span id='search-bar-0-label' className='sr-only'>
                Search this table
              </span>
              <input
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id='search-bar-0'
                type='text'
                className='form-control'
                placeholder={`${count} enregistrements...`}
                value={value || ''}
              />
            </label>
            <i className='bx bx-search-alt search-icon'></i>
          </div>
        </div>
      </Col>
    </Fragment>
  );
}

PaymentsTable.defaultProps = {
  selectedRowIds: {},
};

PaymentsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  selectedRowIds: PropTypes.object,
  className: PropTypes.string,
  buttons: PropTypes.elementType,
};

export default PaymentsTable;
