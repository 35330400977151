import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getToBeConfirmed } from '../../store/payments/slice';

function Payments() {
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.payments.toBeConfirmed);
  useEffect(() => {
    dispatch(getToBeConfirmed());
  }, []);

  const url = payments.length > 0 ? '/payments/confirm' : '/payments/create';
  return <Redirect to={url} />;
}

export default Payments;
