import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import profile1 from '../../assets/images/profile-img2.png';
import { isoToFullName, formateDate } from '../../utility';
// import TableContainer from '../TableContainer';
import ApexRevenue from './ApexRevenue';
import PropTypes from 'prop-types';

import SimpleBar from 'simplebar-react';
import FactureModal from './FactureModal';
import { fetchGet } from '../../utility/apiFetch';
import EnCoursAFacture from './EnCoursAFacture';
import ComptesMonetairesModal from './ComptesMonetairesModal';
import { getContributorLastBillingDate } from "../../utility/contributorFunc";

// import moment from "moment";

function ProfileContent(props) {
  const [userProfile, setuserProfile] = useState(props.user);
  const [userFactures, setuserFactures] = useState([]);
  const [comptesMonetaires, setcomptesMonetaires] = useState([]);
  const [showFactureModal, setshowFactureModal] = useState(false);
  const [showComptseMonetairesModal, setshowComptseMonetairesModal] = useState(false);
  const [factureToShow, setfactureToShow] = useState();
  const [revenueFacture, setrevenueFacture] = useState(0);
  const [enCoursaFacturer, setenCoursaFacturer] = useState(0);
  const toggleFactureModal = () => setshowFactureModal(!showFactureModal);
  const toggleCompteMonetaireModal = () =>
    setshowComptseMonetairesModal(!showComptseMonetairesModal);

  const setSommeEnCoursFacture = (val) => setenCoursaFacturer(val);

  const [apexX, setapexX] = useState([]);
  const [apexY, setapexY] = useState([]);
  const [apexY2, setapexY2] = useState([]);
  const [apexY3, setapexY3] = useState([]);

  const [dateDebut, setdateDebut] = useState('');
  const [dateFin, setdateFin] = useState('');
  const [derniereDateFacturation, setderniereDateFacturation] = useState('');

  const onFactureClick = (facture) => {
    setfactureToShow(facture);
    toggleFactureModal();
  };

  useEffect(() => {
    setdateFin(props.dateFin);
  }, [props.dateFin]);

  useEffect(() => {
    setdateDebut(props.dateDebut);
  }, [props.dateDebut]);

  useEffect(() => {
    getContributorLastBillingDate(props.user).then(date => setderniereDateFacturation(date))
  }, [props.user]);

  useEffect(() => {
    setrevenueFacture(
      userFactures.reduce((s, data) => {
        if (
          data.dateDemande &&
          data.dateDemande >= props.dateDebut &&
          data.dateDemande <= props.dateFin
        ) {
          return s + data.montantRetire;
        } else {
          return s;
        }
      }, 0),
    );
  }, [userFactures, props.dateDebut, props.dateFin]);

  useEffect(() => {
    setuserProfile(props.user);
  }, [props.user]);

  useEffect(() => {
    fetchGet('/payments/user/' + props.user.username)
      .then((res) => setuserFactures(res))
      .catch((err) => console.log(err));
  }, [props.user]);

  useEffect(() => {
    fetchGet('/comptes/user/' + props.user.username)
      .then((res) => setcomptesMonetaires(res))
      .catch((err) => console.log(err));
  }, [props.user]);

  useEffect(() => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const date = new Date(props.dateFin);
    const list = Array();
    for (let i = 1; i <= 12; i++) {
      list.push(
        months[(i + date.getMonth()) % 12] +
          ' ' +
          (i + date.getMonth() > 11 ? date.getFullYear() : date.getFullYear() - 1),
      );
    }
    setapexX(list);

    const list2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    userFactures.forEach((value, index) => {
      const d = new Date(value.dateDemande);
      if (d.getFullYear() === date.getFullYear()) {
        list2[d.getMonth() + (12 - (date.getMonth() + 1))] += value.montantRetire.toFixed(2);
      }
      if (d.getFullYear() === date.getFullYear() - 1 && d.getMonth() > date.getMonth()) {
        list2[d.getMonth() - (date.getMonth() + 1)] += value.montantRetire.toFixed(2);
      }
    });
    setapexY(list2);
  }, [userFactures, props.dateFin]);

  return (
    <React.Fragment>
      <Row>
        <Col xl={4}>
          <Card className='overflow-hidden'>
            <div className='bg-secondary bg-opacity-50'>
              <Row>
                <Col xs='5'>
                  <div className='text-primary p-3'>
                    <h5 className='text-primary'>Bienvenue sur votre compte !</h5>
                    <p>
                      Consultez votre historique et votre en-cours pour faciliter votre facturation.
                    </p>
                  </div>
                </Col>
                <Col xs='7' className='align-self-end'>
                  <img src={profile1} alt='' className='img-fluid' />
                </Col>
              </Row>
            </div>
            <CardBody className='pt-0'>
              <Row>
                <Col sm='4'>
                  <div className='avatar-md profile-user-wid mb-4'>
                    <span className='avatar-title rounded-circle h4'>
                      {userProfile.prenom.charAt(0) + userProfile.nom.charAt(0)}
                    </span>
                  </div>

                  <h5 className='font-size-15 text-truncate'>
                    {userProfile.prenom + ' ' + userProfile.nom}
                  </h5>
                  <p className='text-muted mb-0 text-truncate'>Contributeur</p>
                </Col>

                <Col sm={8}>
                  <div className='pt-4'>
                    <Row>
                      <Col xs='6'>
                        <h5 className='font-size-15'>{userFactures.length}</h5>
                        <p className='text-muted mb-0'>Nombre de factures</p>
                      </Col>
                      <Col xs='6'>
                        <h5 className='font-size-15'>{revenueFacture.toFixed(2)} €</h5>
                        <p className='text-muted mb-0'>Revenu Facturé</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CardTitle className='mb-4'>Information Contributeur</CardTitle>
              <p className='text-muted mb-4'>{userProfile.personalDetail}</p>
              <div className='table-responsive'>
                <Table className='mb-0'>
                  <tbody>
                    <tr>
                      <th scope='row'>Nom d'utilisateur </th>
                      <td>{userProfile.username}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Nom complet </th>
                      <td>{userProfile.prenom + ' ' + userProfile.nom}</td>
                    </tr>
                    <tr>
                      <th scope='row'>E-mail personnel </th>
                      <td>{userProfile.mailPerso}</td>
                    </tr>
                    <tr>
                      <th scope='row'>E-mail Lemissa </th>
                      <td>{userProfile.mailLemissa}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Mobile </th>
                      <td>{userProfile.mobile}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Adresse </th>
                      <td>
                        {userProfile.adresse +
                          ', ' +
                          userProfile.ville +
                          ', ' +
                          isoToFullName(userProfile.pays)}
                      </td>
                    </tr>
                    {comptesMonetaires.length > 0 ? (
                      <>
                        <tr>
                          <th>Compte Monetaire</th>
                          <td>
                            <div className='float-end ms-2'>
                              <button className='btn p-0' onClick={toggleCompteMonetaireModal}>
                                <i className='bx bx-dots-horizontal-rounded text-primary font-size-24 align-middle me-1'></i>
                              </button>
                            </div>

                            <tr>{comptesMonetaires[0].typeCompte}</tr>
                            <tr>{comptesMonetaires[0].numeroCompte}</tr>
                            <tr>{comptesMonetaires[0].etablissement}</tr>
                            {comptesMonetaires[0].referenceEtablissement ? (
                              <tr>{comptesMonetaires[0].referenceEtablissement}</tr>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CardTitle className='mb-5'>Historique Factures</CardTitle>
              <div>
                <SimpleBar style={{ maxHeight: '300px' }}>
                  <ul className='verti-timeline list-unstyled'>
                    {userFactures.map((facture, i) => (
                      <li
                        className={facture.status ? 'event-list active' : 'event-list'}
                        key={'_exp_' + i}
                      >
                        <div className='event-timeline-dot'>
                          <i
                            className={
                              facture.numCompte
                                ? 'bx bx-right-arrow-circle bx-fade-right'
                                : 'bx bx-right-arrow-circle'
                            }
                          />
                        </div>
                        <div className='d-flex'>
                          <div className='me-3'>
                            <i
                              className={
                                facture.numCompte
                                  ? 'fa-file-invoice text-primary'
                                  : 'fas fa-file-signature text-primary'
                              }
                            />
                          </div>
                          <div className='flex-grow-1'>
                            <div>
                              <h5 className='font-size-15'>
                                <Link
                                  to='#'
                                  className='text-dark'
                                  onClick={() => onFactureClick(facture)}
                                >
                                  {facture.factureCorrespondante}
                                </Link>
                              </h5>
                              <span className='text-primary'>
                                Du {formateDate(facture.periodeFactureDebut, 'DD/MM/YYYY')} au{' '}
                                {formateDate(facture.periodeFactureFin, 'DD/MM/YYYY')}
                              </span>{' '}
                              <br />
                              <span className='text-primary'>
                                Montant retiré : {facture.montantRetire.toFixed(2) + ' €'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </SimpleBar>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={8}>
          <Row>
            <Col md='3'>
              <Card className='mini-stats-wid'>
                <CardBody>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <p className='text-muted fw-medium mb-2'>Revenu facturé</p>
                      <h4 className='mb-0 font-size-18'>
                        {revenueFacture ? revenueFacture.toFixed(2) : '0'} €
                      </h4>
                    </div>
                    <div className='mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary'>
                      <span className='avatar-title'>
                        <i className={'bx ' + 'bx-file' + ' font-size-24'} />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md='3'>
              <Card className='mini-stats-wid'>
                <CardBody>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <p className='text-muted fw-medium mb-2'>Premier jour à facturer</p>
                      <h4 className='mb-0 font-size-18'>
                        {derniereDateFacturation
                          ? formateDate(derniereDateFacturation, 'DD/MM/YYYY')
                          : ''}
                      </h4>
                    </div>
                    <div className='mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary'>
                      <span className='avatar-title'>
                        <i className={'bx ' + 'bx-calendar-check' + ' font-size-24'} />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md='3'>
              <Card className='mini-stats-wid'>
                <CardBody>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <p className='text-muted fw-medium mb-2'>En cours à facturer</p>
                      <h4 className='mb-0 font-size-18'>
                        {enCoursaFacturer ? enCoursaFacturer.toFixed(2) : '0'} €
                      </h4>
                    </div>
                    <div className='mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary'>
                      <span className='avatar-title'>
                        <i className={'bx ' + 'bx-sync' + ' font-size-24'} />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md='3'>
              <Card className='mini-stats-wid'>
                <CardBody>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <p className='text-muted fw-medium mb-2'>Revenu généré</p>
                      <h4 className='mb-0 font-size-18'>
                        {revenueFacture || enCoursaFacturer
                          ? (revenueFacture + enCoursaFacturer).toFixed(2)
                          : '0'}{' '}
                        €
                      </h4>
                    </div>
                    <div className='mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary'>
                      <span className='avatar-title'>
                        <i className={'bx ' + 'bx-credit-card' + ' font-size-24'} />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <CardTitle className='mb-4'>Revenus sur 1 an glissant</CardTitle>
              <div id='revenue-chart'>
                <ApexRevenue
                  x={apexX}
                  yFacture={apexY}
                  yEnCours={[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, enCoursaFacturer.toFixed(2)]}
                />
              </div>
            </CardBody>
          </Card>
          {dateDebut &&
          dateFin &&
          derniereDateFacturation >= dateDebut &&
          derniereDateFacturation <= dateFin ? (
            <EnCoursAFacture
              user={userProfile}
              setTotalFunc={setSommeEnCoursFacture}
              dateFin={dateFin}
              dateDebut={derniereDateFacturation}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <FactureModal
        infoFacture={factureToShow}
        title={'Synthèse de la facture'}
        isOpen={showFactureModal}
        toggle={toggleFactureModal}
      />
      <ComptesMonetairesModal
        comptesMonetaires={comptesMonetaires}
        title={'Liste des comptes de ' + userProfile.prenom + ' ' + userProfile.nom}
        isOpen={showComptseMonetairesModal}
        toggle={toggleCompteMonetaireModal}
      />
    </React.Fragment>
  );
}

ProfileContent.propTypes = {
  user: PropTypes.any,
  dateDebut: PropTypes.any,
  dateFin: PropTypes.any,
};

export default ProfileContent;
