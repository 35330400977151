import axios from 'axios';

export class HttpClient {
  constructor() {
    this.instance = axios.create({ baseURL: process.env.REACT_APP_API_HOST });
    this.instance.interceptors.request.use(
      async (config) => {
        const token = await this.getToken();
        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        };
      },
      (error) => {
        Promise.reject(error);
      },
    );
    return this;
  }

  setTokenGenerator(tokenGenerator) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  getToken() {
    return this.tokenGenerator();
  }
}

export default new HttpClient();
