import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import TableContainer from '../../../components/TableContainer';
import ValidationPopup from '../../../components/ValidationPopup';
import { deleteTransferService, getTransferServices } from '../../../store/transferServices/slice';
import AddTransferService from './AddTransferService';
import UpdateTransferService from './UpdateTransferService';

function TransferServices() {
  const dispatch = useDispatch();
  const operators = useSelector((state) => state.transferServices.items);

  const [validationOpen, setvalidationOpen] = useState(false);
  const [validationDeleteId, setvalidationDeleteId] = useState();
  const [validationText, setvalidationText] = useState('');

  const [refresh, setRefresh] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [transferService, setTransferService] = useState();

  const toggleValidation = () => setvalidationOpen(!validationOpen);
  const onDelete = () => dispatch(deleteTransferService(validationDeleteId));

  useEffect(() => {
    dispatch(getTransferServices());
  }, [refresh]);

  const columns = useMemo(
    () => [
      {
        Header: 'Service ID Orange',
        accessor: 'service_id',
        disableFilters: true,
      },
      {
        Header: 'Nom du Réseau',
        accessor: 'PaymentNetwork.name',
        disableFilters: true,
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <div
                className='text-success'
                onClick={() => {
                  setTransferService(cellProps.row.original);
                  toggleEditModal();
                }}
              >
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Modifier
                </UncontrolledTooltip>
              </div>
              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationText(
                    `Etes vous sur de vouloir supprimer ${cellProps.row.original.service_id}`,
                  );
                  setvalidationDeleteId(cellProps.row.original.id);
                  toggleValidation();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  const toggleAddNewModal = () => setAddNewModal(!addNewModal);
  const toggleEditModal = () => setEditModal(!editModal);
  const toggleRefresh = () => setRefresh(!refresh);

  return (
    <>
      <Row>
        <Col xs='12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <h3 className='mb-0 font-size-14'>Correspondance Réseaux Mobiles</h3>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs='12'>
          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={operators}
                isGlobalFilter={true}
                isAddUserList={true}
                handleUserClick={toggleAddNewModal}
                addUserName='Ajouter Réseau mobile'
                customPageSize={10}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={addNewModal} toggle={toggleAddNewModal} size='xl'>
        <ModalHeader toggle={toggleAddNewModal} tag='h4'>
          Ajouter de Réseau mobile
        </ModalHeader>
        <ModalBody>
          <AddTransferService
            onSubmit={() => {
              toggleAddNewModal();
              toggleRefresh();
            }}
            onCancel={() => toggleAddNewModal()}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={editModal} toggle={toggleEditModal} size='xl'>
        <ModalHeader toggle={toggleEditModal} tag='h4'>
          Modifier de Réseau mobile
        </ModalHeader>
        <ModalBody>
          <UpdateTransferService
            transferService={transferService}
            onSubmit={() => {
              toggleEditModal();
              toggleRefresh();
            }}
            onCancel={() => toggleEditModal()}
          />
        </ModalBody>
      </Modal>

      <ValidationPopup
        isOpen={validationOpen}
        toogle={toggleValidation}
        fct={onDelete}
        text={validationText}
      />
    </>
  );
}

export default TransferServices;
