import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Form, FormFeedback, FormGroup, Input } from 'reactstrap';

import { fetchPost } from '../../../utility/apiFetch';
import PaymentNetworksSelect from '../../../components/Select/PaymentNetworksSelect';
import { paymentAccountType } from '../../../constants/payment';
import { useSelector } from 'react-redux';
import PasswordModal from './components/PasswordModal';
import PropTypes from 'prop-types';
import * as yup from 'yup';

const TRANSFER_SERVICE_STATUS_ACTIVE = 'ACTIVE';

function AddTransferService(props) {
  const [paymentNetworkId, setPaymentNetworkId] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [paymentNetworkErrors, setPaymentNetworkErrors] = useState([]);
  const [serviceIdErrors, setServiceIdErrors] = useState([]);

  yup.setLocale({
    mixed: {
      required: 'Ceci est un champ obligatoire',
    },
  });

  async function validatePaymentNetwork(value) {
    try {
      await yup.string().required().validate(value);
      setPaymentNetworkErrors([]);
      return true;
    } catch (err) {
      setPaymentNetworkErrors(err.errors);
    }
    return false;
  }
  async function validateServiceId(value) {
    try {
      await yup.string().required().validate(value);
      setServiceIdErrors([]);
      return true;
    } catch (err) {
      setServiceIdErrors(err.errors);
    }
    return false;
  }

  async function onSend() {
    if ((await validatePaymentNetwork(paymentNetworkId)) && (await validateServiceId(serviceId))) {
      const transferService = {
        service_id: serviceId,
        payment_network_id: paymentNetworkId,
      };
      fetchPost('/transfer-services', transferService)
        .then((res) => props.onSubmit())
        .catch((err) => console.log(err));
    }
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <FormGroup className='form-floating mb-3'>
                <PaymentNetworksSelect
                  networkType={paymentAccountType.MOBILE_MONEY}
                  value={paymentNetworkId}
                  onChange={(e) => {
                    setPaymentNetworkId(e.target.value);
                    validatePaymentNetwork(e.target.value);
                  }}
                  invalid={paymentNetworkErrors.length > 0 ? true : undefined}
                />
                {paymentNetworkErrors.map((error) => (
                  <FormFeedback type='invalid' key={error}>
                    {error}
                  </FormFeedback>
                ))}
                <label htmlFor='floatingtypeOperateurInput'>Nom du Réseau</label>
              </FormGroup>
            </Col>
          </Row>
          <ServiceIdOrangeInput
            paymentNetworkId={paymentNetworkId}
            onChange={(e) => {
              setServiceId(e.target.value);
              validateServiceId(e.target.value);
            }}
            invalid={serviceIdErrors.length > 0 ? true : undefined}
            serviceIdErrors={serviceIdErrors}
          />
          <div className='d-flex justify-content-end'>
            <button onClick={props.onCancel} className='btn btn-secondary w-md m-2'>
              Annuler
            </button>
            <button onClick={onSend} className='btn btn-primary w-md m-2'>
              Valider
            </button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

function ServiceIdOrangeInput(props) {
  const paymentNetworks = useSelector((state) => state.paymentNetworks.items);
  const [transferServiceOpt, setTransferServiceOpt] = useState([]);
  const [passwordModal, setPasswordModal] = useState(false);

  let paymentNetwork = null;
  if (props.paymentNetworkId) {
    paymentNetwork = paymentNetworks.find((pn) => pn.id === parseInt(props.paymentNetworkId));
  }

  useEffect(() => {
    setTransferServiceOpt([]);
  }, [props.paymentNetworkId]);

  const openPassModal = () => {
    setPasswordModal(true);
  };

  const submitPassModal = (password) => {
    const route =
      paymentNetwork && paymentNetwork.country
        ? `/orange-money/transfer-services/${paymentNetwork.country}`
        : '/orange-money/transfer-services';
    fetchPost(route, { password })
      .then((res) => {
        if (res.data) {
          const opt = [];
          res.data.map(
            (ts) =>
              ts.status === TRANSFER_SERVICE_STATUS_ACTIVE &&
              opt.push({ id: ts.id, name: ts.name }),
          );
          setTransferServiceOpt(opt.sort((a, b) => (a.name > b.name ? 1 : -1)));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setPasswordModal(false));
  };

  return (
    <Row>
      <Col md={10}>
        <FormGroup className='form-floating mb-3'>
          {(transferServiceOpt.length > 0 && (
            <Input type='select' id='floatingnomOperateurInput' className='form-control' {...props}>
              <option key='_none' value=''>
                - choisir -
              </option>
              {transferServiceOpt.map((opt) => (
                <option key={opt.id} value={opt.id}>{`${opt.name} (${opt.id})`}</option>
              ))}
            </Input>
          )) || (
            <Input
              type='text'
              className='form-control'
              id='floatingnomOperateurInput'
              placeholder='Service ID Orange'
              defaultValue=''
              {...props}
            />
          )}
          {props.serviceIdErrors.map((error) => (
            <FormFeedback type='invalid' key={error}>
              {error}
            </FormFeedback>
          ))}
          <label htmlFor='floatingnomOperateurInput'>Service ID Orange</label>
        </FormGroup>
      </Col>
      <Col md={2} className='text-center'>
        <button className='btn btn-primary w-md h-75' onClick={() => openPassModal()}>
          Liste services
        </button>
      </Col>
      <PasswordModal
        text='Entrez la clé de sécurité pour valider la demande :'
        show={passwordModal}
        onSubmitClick={submitPassModal}
        onCloseClick={() => setPasswordModal(false)}
      />
    </Row>
  );
}

ServiceIdOrangeInput.propTypes = {
  paymentNetworkId: PropTypes.number,
};

export default AddTransferService;
