import React, { useEffect } from 'react';

import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from './routes';
import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';

import Authmiddleware from './routes/route';

import VerticalLayout from './components/VerticalLayout';
import NonAuthLayout from './components/NonAuthLayout';
import Loading from './components/loading';
import httpClient from './utility/httpClient';

import './assets/scss/theme.scss';

function App(props) {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    httpClient.setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              component={route.component}
              key={idx}
              layout={NonAuthLayout}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              component={route.component}
              key={idx}
              layout={VerticalLayout}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  layout: state.Layout,
});

export default connect(mapStateToProps, null)(App);
