import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Row, CardTitle, Form, Table } from 'reactstrap';
// import { Formik, Field, Form, ErrorMessage } from "formik"; // si besoin d'un système de validation
// import * as Yup from "yup"; // idem
import Breadcrumbs from '../components/Breadcrumb';
import { fetchGet, fetchPost, fetchPut } from '../utility/apiFetch';
import { formateDate, formatEurFr, isoToFullName, actualDay } from '../utility';
import { PENDING, CANCELLED, TERMINATED } from '../constants/paymentStatus';

function AddFacture(props) {
  const history = useHistory();
  const location = useLocation();

  // Variable de selection et variables complexes
  const [userProfile, setuserProfile] = useState(location.state.user);
  const [comptes, setComptes] = useState([]);
  const [operateursPaiement, setoperateursPaiement] = useState([]);
  const [lignesSpeciales, setlignesSpeciales] = useState([]);
  const [ligneStandard, setligneStandard] = useState([]);
  const [facturerLigneStandard, setfacturerLigneStandard] = useState(true);
  const [lastInvoice, setlastInvoice] = useState();
  const [total, setmo] = useState(0);

  // Variable de statut pour MAJ
  const [compte, setCompte] = useState('');
  const [numFacture, setnumFacture] = useState('');
  const [dateDemande, setdateDemande] = useState('');
  const [dateDebut, setdateDebut] = useState('');
  const [dateFin, setdateFin] = useState('');
  const [montantRetire, setmontantRetire] = useState(0);
  const [dateexecution, setdateexecution] = useState('');
  const [nomOperateur, setnomOperateur] = useState('');
  const [montantTransfere, setmontantTransfere] = useState(0);
  const [fraisTransfert, setfraisTransfert] = useState(0);
  const [montantRemis, setmontantRemis] = useState('');
  const [monnaie, setmonnaie] = useState('');
  const [referenceOperation, setreferenceOperation] = useState('');
  const [status, setstatus] = useState('');
  const [compteUser, setcompteUser] = useState('');
  const [modePaiement, setmodePaiement] = useState('');
  const [commentaire, setcommentaire] = useState('');

  // Initialisation des variables

  useEffect(() => {
    const date = new Date();
    setdateDemande(formateDate(date, 'YYYY-MM-DD'));
  }, []);

  useEffect(() => {
    setuserProfile(location.state.user);
  }, [location.state.user]);

  useEffect(() => {
    setligneStandard(location.state.ligneStandard);
  }, [location.state.ligneStandard]);

  useEffect(() => {
    setlignesSpeciales(
      location.state.lignesSpeciales.map((value) => Object.assign(value, { aFacturer: true })),
    );
  }, [location.state.lignesSpeciales]);

  useEffect(() => {
    if (facturerLigneStandard) {
      setdateDebut(location.state.dateDebut);
      setdateFin(location.state.dateFin);
    } else {
      setdateDebut('');
      setdateFin('');
    }
  }, [facturerLigneStandard]);

  useEffect(() => {
    let tot = 0;
    if (facturerLigneStandard == true) {
      tot += ligneStandard.reduce((s, data) => (s = s + data.nbUnite * data.tarifUnitaire), 0);
    }
    tot += lignesSpeciales.reduce((s, data) => {
      if (data.aFacturer) return (s = s + data.quantite * data.tarifUnitaire);
      else return s;
    }, 0);
    const totFixed = Math.round(tot * 100) / 100;
    setmontantRetire(totFixed);
    setmontantTransfere(totFixed);
  }, [ligneStandard, facturerLigneStandard, lignesSpeciales]);

  useEffect(() => {
    fetchGet('/comptes/user/' + location.state.user.username)
      .then((res) => setComptes(res))
      .catch((err) => console.log(err));
  }, [location.state.user]);

  useEffect(() => {
    fetchGet('/operateurs')
      .then((res) => setoperateursPaiement(res))
      .catch((err) => console.log(err));
  }, []);

  // Récupération de la dernière facture du contributeur - En cours !
  useEffect(() => {
    fetchGet('/payments/user/lastpaiement/' + location.state.user.username)
      .then((res) => setlastInvoice(res))
      .catch((err) => console.log(err));
  }, [location.state.user]);

  // Initialisation valeurs par défaut
  useEffect(() => {
    if (lastInvoice) {
      setmonnaie(lastInvoice.monnaie);
      setnomOperateur(lastInvoice.nomOperateur);
      setfraisTransfert(lastInvoice.fraisTransfert);
      const transferAmount = montantRetire - lastInvoice.fraisTransfert;
      setmontantTransfere(Math.round(transferAmount * 100) / 100);
      setmodePaiement(lastInvoice.modePaiement);
      setcompteUser(lastInvoice.compteMonetaireId);
    }
  }, [lastInvoice]);

  const onSend = () => {
    if (
      userProfile &&
      numFacture !== '' &&
      montantRetire !== '' &&
      compteUser !== '' &&
      dateDemande !== ''
    ) {
      const facture = {
        username: userProfile.username,
        dateDemande,
        factureCorrespondante: numFacture,
        modePaiement: modePaiement || null,
        compteMonetaireId: compteUser || null,
        dateExecution: dateexecution || formateDate(actualDay(), 'YYYY-MM-DD'),
        nomOperateur: nomOperateur || null,
        referencePaiement: referenceOperation || null,
        montantRetire,
        montantTransfere: montantTransfere,
        fraisTransfert: fraisTransfert,
        montantRemis: montantRemis || null,
        monnaie: monnaie || null,
        periodeFactureDebut: dateDebut || null,
        periodeFactureFin: dateFin || null,
        status: status || PENDING,
        commentaire: commentaire || null,
      };

      // Création de la facture
      fetchPost('/payments', facture)
        .then((res) => {
          // MAJ des lignes spéciales avec le nouveau n° de facture créé
          const currentInvoice = res;
          lignesSpeciales.forEach((value) => {
            if (value.aFacturer) {
              fetchPut('/lignesspec/' + value.id, {
                paiementId: currentInvoice.data.id,
                reglee: true,
              })
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
            }
          });
          history.push('/factures');
        })
        .catch((err) => console.log(err));
    } else {
      console.log("can't log");
    }
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Admin Lemissa' breadcrumbItem='Ajouter une Facture' />
        <Card>
          <CardBody>
            <CardTitle className='h4 mb-4'>Info Contributeur</CardTitle>
            <Row>
              <Col md={6}>
                <div className='table-responsive font-size-12'>
                  <Table className='table-nowrap mb-0'>
                    <tbody>
                      <tr>
                        <th scope='row'>Nom d'utilisateur </th>
                        <td>{userProfile.username}</td>
                      </tr>
                      <tr>
                        <th scope='row'>E-mail personnel </th>
                        <td>{userProfile.mailPerso}</td>
                      </tr>
                      <tr>
                        <th scope='row'>Mobile </th>
                        <td>{userProfile.mobile}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={6}>
                <div className='table-responsive font-size-12'>
                  <Table className='table-nowrap mb-0'>
                    <tbody>
                      <tr>
                        <th scope='row'>Nom complet </th>
                        <td>{userProfile.prenom + ' ' + userProfile.nom}</td>
                      </tr>
                      <tr>
                        <th scope='row'>E-mail Lemissa </th>
                        <td>{userProfile.mailLemissa}</td>
                      </tr>
                      <tr>
                        <th scope='row'>Adresse </th>
                        <td>
                          {userProfile.adresse +
                            ', ' +
                            userProfile.ville +
                            ', ' +
                            isoToFullName(userProfile.pays)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle className='h4 card-title-desc'>Formulaire d'ajout</CardTitle>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Row className='mb-3'>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control'
                          id='floatingnumFactureInput'
                          placeholder='Numéro de facture'
                          defaultValue=''
                          onChange={(event) => setnumFacture(event.target.value)}
                        />
                        <label htmlFor='floatingnumFactureInput'>Numéro de facture</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <select
                          defaultValue={PENDING}
                          className='form-select'
                          id='selectStatusInput'
                          placeholder='Choisissez le Statut'
                          onChange={(event) => setstatus(event.target.value)}
                        >
                          <option key='_none' value=''>
                            - Choisissez le statut -{' '}
                          </option>
                          <option key={CANCELLED} value={CANCELLED}>
                            {CANCELLED}
                          </option>
                          <option key={PENDING} value={PENDING}>
                            {PENDING}
                          </option>
                          <option key={TERMINATED} value={TERMINATED}>
                            {TERMINATED}
                          </option>
                        </select>
                        <label htmlFor='floatingStatus'>Statut</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='date'
                          className='form-control'
                          value={dateDemande}
                          id='floatingdateDemandeInput'
                          placeholder='Date de Demande'
                          onChange={(event) => setdateDemande(event.target.value)}
                        />
                        <label htmlFor='floatingdateDemandeInput'>Date de Demande</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='number'
                          value={montantRetire}
                          className='form-control'
                          id='floatingmontantRetireInput'
                          placeholder='Montant retiré - EUR'
                          onChange={(event) => {
                            setmontantRetire(event.target.value);
                            const val = isNaN(parseFloat(event.target.value))
                              ? 0 - fraisTransfert
                              : event.target.value - fraisTransfert;
                            setmontantTransfere(Math.round((val) * 100) / 100);
                          }}
                        />
                        <label htmlFor='floatingmontantRetireInput'>Montant retiré - EUR</label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='date'
                          readOnly
                          value={dateDebut}
                          className='form-control'
                          id='floatingdebutInput'
                          placeholder='Date de debut de période'
                          onChange={(event) => setdateDebut(event.target.value)}
                        />
                        <label htmlFor='floatingdebutInput'>Date de debut de période</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='date'
                          readOnly
                          value={dateFin}
                          className='form-control'
                          id='floatingfinInput'
                          placeholder='Date de fin de période'
                          onChange={(event) => setdateFin(event.target.value)}
                        />
                        <label htmlFor='floatingfinInput'>Date de fin de période</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <select
                          value={compteUser}
                          className='form-select'
                          onChange={(event) => setcompteUser(event.target.value)}
                        >
                          <option key='_none' value=''>
                            - aucun compte sélectionné -
                          </option>
                          {/* {(comptes || []).map((compte,id) => <option value={id}>Compte {id+1}</option>)} */}
                          {(comptes || []).map((compte, id) => (
                            <option key={compte.id} value={compte.id}>
                              {compte.numeroCompte}
                            </option>
                          ))}
                        </select>
                        <label htmlFor='floatingSelectGrid'>Choisir le Compte</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        {/* <input readOnly value={nomOperateur}></input> */}
                        <select
                          value={nomOperateur}
                          className='form-select'
                          onChange={(event) => setnomOperateur(event.target.value)}
                        >
                          <option key='_none' value=''>
                            - aucun opérateur sélectionné -
                          </option>
                          {(operateursPaiement || []).map((operateurPaiement, id) => (
                            <option
                              key={operateurPaiement.nomOperateur}
                              value={operateurPaiement.nomOperateur}
                            >
                              {operateurPaiement.nomOperateur}
                            </option>
                          ))}
                        </select>
                        <label htmlFor='floatingSelectGrid'>Choisir l'opérateur de paiement</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='number'
                          value={montantTransfere}
                          className='form-control'
                          id='floatingmontantTransfereInput'
                          placeholder='Montant Transféré - EUR'
                          readOnly
                        />
                        <label htmlFor='floatingmontantTransfereInput'>
                          Montant transféré - EUR
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='number'
                          className='form-control'
                          id='floatingfraisdetransfertInput'
                          placeholder='Frais de ransfert - EUR'
                          value={fraisTransfert}
                          onChange={(event) => {
                            setfraisTransfert(event.target.value);
                            const val = isNaN(parseFloat(event.target.value))
                              ? montantRetire
                              : montantRetire - event.target.value;
                            setmontantTransfere(Math.round((val) * 100) / 100);
                          }}
                        />
                        <label htmlFor='floatingfraisdetransfertInput'>
                          Frais de transfert - EUR
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control'
                          id='floatingmontantRemisInput'
                          placeholder='Montant remis'
                          defaultValue=''
                          onChange={(event) => setmontantRemis(event.target.value)}
                        />
                        <label htmlFor='floatingmontantRemisInput'>
                          Montant remis - en monnaie locale
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        {/* <input readOnly value={monnaie}></input> */}
                        <select
                          value={monnaie}
                          className='form-select'
                          id='floatingMonnaieInput'
                          placeholder='Monnaie'
                          onChange={(event) => setmonnaie(event.target.value)}
                        >
                          <option key='_none'>- Choisissez la monnaie -</option>
                          <option key='EUR' value='EUR'>
                            EUR
                          </option>
                          <option key='MGA' value='MGA'>
                            MGA
                          </option>
                          <option key='USD' value='USD'>
                            USD
                          </option>
                          <option key='XAF' value='XAF'>
                            XAF
                          </option>
                          <option key='XOF' value='XOF'>
                            XOF
                          </option>
                        </select>
                        <label htmlFor='floatingMonnaieSelect'>Monnaie</label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='date'
                          className='form-control'
                          id='floatingdateExecutionInput'
                          placeholder="Date d'exécution"
                          defaultValue={formateDate(actualDay(), 'YYYY-MM-DD')}
                          onChange={(event) => setdateexecution(event.target.value)}
                        />
                        <label htmlFor='floatingdateExecutionInput'>Date d'exécution</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control'
                          id='floatingrefOperationInput'
                          placeholder='Référence opération'
                          defaultValue=''
                          onChange={(event) => setreferenceOperation(event.target.value)}
                        />
                        <label htmlFor='floatingrefOperationInput'>Référence de l'opération</label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          readOnly
                          type='text'
                          className='form-control'
                          id='floatingModePaiement'
                          value={modePaiement}
                          onChange={(event) => setmodePaiement(event.target.value)}
                        />
                        <label htmlFor='floatingModePaiement'>Mode de paiement</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-floating mb-3'>
                        <input
                          readOnly
                          type='text'
                          className='form-control'
                          id='floatingEtablissement'
                          value={compte.referenceEtablissement && ' - ' && compte.etablissement}
                        />
                        <label htmlFor='floatingEtablissement'>Etablissement</label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control'
                          id='floatingcommentaireInput'
                          placeholder='Commentaire'
                          defaultValue=''
                          onChange={(event) => setcommentaire(event.target.value)}
                        />
                        <label htmlFor='floatingcommentaireInput'>Commentaire</label>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={6}>
                  <Row className='mb-5'>
                    <div>
                      <Col>
                        <div className='mb-4 font-size-12'>
                          <h5 className='font-size-15 mb-4'>
                            <i className='mdi mdi-format-list-bulleted-square text-primary mr-1'></i>{' '}
                            Lignes à facturer
                          </h5>
                          <div className='form-check mb-2'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='defaultCheck1'
                              defaultChecked
                              onChange={(event) => setfacturerLigneStandard(event.target.checked)}
                            />
                            <label className='form-check-label' htmlFor='defaultCheck1'>
                              Lignes Standards - De {formateDate(location.state.dateDebut)} à{' '}
                              {formateDate(location.state.dateFin)}
                            </label>
                          </div>
                          {lignesSpeciales.map(
                            (
                              { dossier, typeLigne, periodeDateDebut, periodeDateFin, aFacturer },
                              id,
                            ) => (
                              <div className='form-check form-check-end mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='defaultCheck2'
                                  defaultChecked
                                  onChange={(event) => {
                                    const tab = [...lignesSpeciales];
                                    // tab.reduce()
                                    tab[id].aFacturer = event.target.checked;
                                    setlignesSpeciales(tab);
                                  }}
                                />
                                <label className='form-check-label' htmlFor='defaultCheck2'>
                                  {dossier +
                                    '-' +
                                    typeLigne +
                                    ' - De ' +
                                    formateDate(periodeDateDebut) +
                                    ' à ' +
                                    formateDate(periodeDateFin)}
                                </label>
                              </div>
                            ),
                          )}
                        </div>
                      </Col>
                    </div>

                    <div className='table-responsive font-size-12'>
                      <Table className='mb-0'>
                        <tbody>
                          <tr>
                            <th></th>
                            <th>Dossier</th>
                            <th>Type de Ligne</th>
                            <th>du</th>
                            <th>au</th>
                            <th>Nbre d'UO</th>
                            <th>Tarif UO</th>
                            <th>Montant</th>
                          </tr>
                          {facturerLigneStandard &&
                            (ligneStandard || []).map((line) => (
                              <tr>
                                <td>
                                  <i className='far fa-star'></i>{' '}
                                </td>
                                <td>{line.dossier}</td>
                                <td>{line.typeLigne}</td>
                                <td>{formateDate(location.state.dateDebut, 'DD-MM-YY')}</td>
                                <td>{formateDate(location.state.dateFin, 'DD-MM-YY')}</td>
                                <td>{line.nbUnite}</td>
                                <td>{line.tarifUnitaire.toFixed(3)} €</td>
                                <td>{(line.nbUnite * line.tarifUnitaire).toFixed(2)} €</td>
                              </tr>
                            ))}

                          {(lignesSpeciales || []).map((line) => {
                            if (line.aFacturer) {
                              return (
                                <tr>
                                  <td>
                                    <i className='fas fa-star'></i>{' '}
                                  </td>
                                  <td>{line.dossier}</td>
                                  <td>{line.typeLigne}</td>
                                  <td>{formateDate(line.periodeDateDebut, 'DD-MM-YY')}</td>
                                  <td>{formateDate(line.periodeDateFin, 'DD-MM-YY')}</td>
                                  <td>{line.quantite}</td>
                                  <td>{line.tarifUnitaire.toFixed(3)} €</td>
                                  <td>{(line.quantite * line.tarifUnitaire).toFixed(2)} €</td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </Col>
              </Row>

              <div className='d-flex justify-content-center'>
                <Link
                  to='/factures'
                  className='mx-2'
                  // ref='annuler'
                >
                  <button
                    onClick={(event) => {
                      this.refs.annuler.click();
                    }}
                    className='btn btn-secondary w-md'
                  >
                    Annuler
                  </button>
                </Link>

                <button onClick={onSend} className='btn btn-primary w-md'>
                  Valider
                </button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AddFacture;
