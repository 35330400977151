import { createSlice } from '@reduxjs/toolkit';

const dossierSlice = createSlice({
  name: 'dossier',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getDossiers(state) {
      state.isLoading = true;
    },
    getDossiersSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    addDossier(state) {
      state.isLoading = true;
    },
    addDossierSuccess(state, action) {
      state.isLoading = false;
      state.items.push(action.payload);
    },
    deleteDossier(state) {
      state.isLoading = true;
    },
    deleteDossierSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter((dossier) => dossier.dossier !== action.payload);
    },
  },
});

export const {
  getDossiers,
  getDossiersSuccess,
  addDossier,
  addDossierSuccess,
  deleteDossier,
  deleteDossierSuccess,
} = dossierSlice.actions;
export default dossierSlice;
