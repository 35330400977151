import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Form, Row, Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../store/login/actions';
import PropTypes from 'prop-types';
import LoginButton from '../components/login-button';

const Login = (props) => {
  const dispatch = useDispatch();
  // meta title
  document.title = 'Login ';
  // console.log(props.history)
  const error = useSelector((state) => state.Login.error);
  console.log(localStorage.getItem('authUser'));

  return (
    <React.Fragment>
      <div>
        <Container fluid className='p-0'>
          <Row className='g-0'>
            <Col xl={9}>
              <div className='auth-full-bg pt-lg-5 p-4'>
                <div className='w-100'>
                  <div className='bg-overlay'></div>
                  <div className='d-flex h-100 flex-column'>
                    <div className='p-4 mt-auto'>
                      <div className='row justify-content-center'>
                        <div className='col-lg-7'>
                          <div className='text-center'>
                            <h4 className='mb-3'>
                              <i className='bx bxs-quote-alt-left text-primary h1 align-middle me-3'></i>
                              <span className='text-primary'>250 +</span> Contributeurs !
                            </h4>
                            <div dir='ltr'>
                              <div>
                                <div className='item'>
                                  <div className='py-3'>
                                    <p className='font-size-16 mb-4'>
                                      Bienvenue sur votre compte Contributeur Lemissa ! Consultez
                                      votre historique de production et de facturation. Appuyez vous
                                      sur ce relevé pour faciliter la génération de vos factures.
                                    </p>

                                    <div>
                                      <h4 className='font-size-16 text-primary'>
                                        L'équipe Lemissa.
                                      </h4>
                                      <p className='font-size-14 mb-0'></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className='item'>
                                  <div className='py-3'>
                                    <p className='font-size-16 mb-4'>
                                      Remontez vos remarques, suggestions ou réclamations à
                                      l'adresse de facturation habituelle :
                                      <a href='factures.prestations@lemissa.com'>
                                        {' '}
                                        factures.prestations@lemissa.com
                                      </a>
                                    </p>

                                    <div>
                                      <h4 className='font-size-16 text-primary'></h4>
                                      <p className='font-size-14 mb-0'>Merci !</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={3}>
              <div className='auth-full-page-content p-md-5 p-4'>
                <div className='w-100'>
                  <div className='d-flex flex-column h-100'>
                    <div className='mb-4 mb-md-5'></div>
                    <div className='my-auto'>
                      <div>
                        <h5 className='text-primary text-center'> Heureux de vous revoir !</h5>
                        <p className='text-muted text-center'>
                          Connectez-vous pour accéder à votre compte.
                        </p>
                      </div>

                      <div className='mt-4'>
                        <Form
                          className='form-horizontal'
                          onSubmit={(e) => {
                            e.preventDefault();
                            dispatch(
                              loginUser(
                                {
                                  userId: 'contact',
                                },
                                props.history,
                              ),
                            );
                            return false;
                          }}
                        >
                          <div className='mt-3 d-grid'>
                            <LoginButton />
                          </div>
                        </Form>

                        {error ? <Alert color='danger'>{error}</Alert> : null}
                      </div>
                    </div>

                    <div className='mt-4 mt-md-5 text-center'>
                      <p className='mb-0'>© {new Date().getFullYear()} Lemissa.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Login);
