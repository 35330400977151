import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { allCountriesAlpha3 } from "../../../utility";
import { togglePaymentsModal } from "../../../store/payments/slice";
import { IN_PROGRESS, TO_BE_CONFIRMED } from "../../../constants/paymentStatus";
import { Card, CardBody, Col, Container, Row, Tooltip } from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumb";
import ReportCard from "./ReportCard";
import TotalAmount from "./TotalAmount";
import TotalPayments from "./TotalPayments";
import PaymentsTable from "../PaymentsTable";

const API_AUTHORIZATION_ERROR_CODE = 1002;
const OTHER_TRANSACTION_IN_PROGRESS_ERROR_CODE = 4025;
const ALLOWED_OPERATORS = ['Orange Money'];

function PaymentsInitializedContent({ title, payments, status }) {
  const dispatch = useDispatch();
  const columns = useMemo(
    () => [
      {
        Header: 'Prénom NOM',
        accessor: 'last_first_name',
      },
      {
        Header: 'N° Compte',
        accessor: 'account_num',
      },
      {
        Header: 'Pays',
        accessor: 'country',
      },
      {
        Header: 'Montant Transféré',
        accessor: 'transfer_amount',
      },
      {
        Header: 'Frais Op.',
        accessor: 'expected_fees',
      },
      {
        Header: 'Montant attendu',
        accessor: 'expected_total',
      },
      {
        Header: 'Frais confirmés',
        accessor: 'confirmed_fees',
      },
      {
        Header: 'Montant Confirmé',
        accessor: 'confirmed_total',
      },
      {
        Header: 'Alerte',
        accessor: 'alert',
        Cell: (cellProps) => <div className='text-center'>{cellProps.value}</div>,
      },
      {
        Header: 'N° transfert',
        accessor: 'transfer_number',
      },
    ],
    [],
  );

  const data = [];
  const operators = [];
  for (const p of payments) {
    data.push({
      id: p.id,
      network: p.nomOperateur,
      amount: p.montantTransfere, // Not sure
      username: p.username,
      last_first_name: getUserName(p.User),
      account_num: p.CompteMonetaire ? p.CompteMonetaire.numero_compte : null,
      country: getCountryName(p),
      transfer_amount: p.montantTransfere,
      expected_fees: p.fraisTransfert,
      expected_total: p.montantRetire,
      confirmed_fees: p.confirmedFees,
      confirmed_total: getConfirmedTotalAmount(p),
      alert: getAlert(p),
      transfer_number: p.referencePaiement,
      error_code: p.errorCode,
      allowPayment: ALLOWED_OPERATORS.includes(p.nomOperateur),
      originPaymentObj: p,
    });
    if (!operators.includes(p.nomOperateur)) {
      operators.push(p.nomOperateur);
    }
  }

  function getUserName(user) {
    const parts = [];
    if (user && user.prenom) {
      parts.push(user.prenom);
    }
    if (user && user.nom) {
      parts.push(user.nom);
    }
    return parts.join(' ');
  }

  function getCountryName(product) {
    const country =
      product.User && product.User.pays
        ? allCountriesAlpha3.find((country) => country.value === product.User.pays)
        : null;
    return country ? country.name : null;
  }

  function getAlert(product) {
    const alert = checkAlert(product);
    return alert && alert.message
      ? <Alert id={product.id} text={alert.message} critical={alert.critical} />
      : null;
  }

  let buttons = '';
  const cancelButton = (<button
    type='button'
    className='btn btn-secondary btn-rounded mb-2 me-2'
    onClick={() => dispatch(togglePaymentsModal('cancel'))}
  >
    <i className='mdi mdi-close me-1'></i>
    Annuler les paiements
  </button>);
  if (status === TO_BE_CONFIRMED) {
    buttons = (
      <>
        <ConfirmButton onClick={() => dispatch(togglePaymentsModal('confirm'))} />
        { cancelButton }
      </>
    );
  }
  if (status === IN_PROGRESS) {
    buttons = (
      <>
        <button
          type='button'
          className='btn btn-success btn-rounded mb-2 me-2'
          onClick={() => dispatch(togglePaymentsModal('refresh'))}
        >
          <i className='mdi mdi-refresh me-1'></i>
          Rafraîchir
        </button>
        <CancelInProgressButton onClick={() => dispatch(togglePaymentsModal('cancel'))} />
      </>
    );
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <Breadcrumbs
          title='Admin Lemissa'
          breadcrumbItem={title}
        />
        <Row>
          <Col lg={4}>
            <ReportCard
              title='Opérateur sélectionné'
              value={operators.join(', ')}
              iconClass='bx-globe'
            />
          </Col>

          <Col lg={4}>
            <TotalAmount />
          </Col>
          <Col lg={4}>
            <TotalPayments />
          </Col>
        </Row>

        <Row>
          <Col xs='12'>
            <Card>
              <CardBody>
                <PaymentsTable
                  columns={columns}
                  data={data}
                  buttons={buttons}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function ConfirmButton(props) {
  const selected = useSelector((state) => state.payments.selected);
  const hasError = selected.some(
    (row) => {
      const alert = row.originPaymentObj && checkAlert(row.originPaymentObj);
      return alert && alert.message
        && (
          alert.errorCode !== API_AUTHORIZATION_ERROR_CODE
          && alert.errorCode !== OTHER_TRANSACTION_IN_PROGRESS_ERROR_CODE
        )
    }
  );

  return (<ButtonWithTooltip
    label="Confirmer les paiements"
    disabledDesc="Des paiements invalides sélectionnés ! La confirmation n'est pas autorisée."
    disabled={hasError}
    className="btn-success"
    {...props}
  />)
}

function CancelInProgressButton(props) {
  const selected = useSelector((state) => state.payments.selected);
  const noError = selected.some(
    (row) => {
      const alert = row.originPaymentObj && checkAlert(row.originPaymentObj);
      return alert && !alert.message
    }
  );

  return (<ButtonWithTooltip
    label="Annuler les paiements"
    disabledDesc="Des paiements invalides sélectionnés ! L'annuler n'est pas autorisée."
    disabled={noError}
    className="btn-secondary"
    {...props}
  />)
}

function ButtonWithTooltip(props) {
  const tooltipId="disabled-btn-tooltip"
  const [tooltip, setTooltip] = useState(false);
  return (
    <>
      <span id={tooltipId} data-tip-disable={false}>
        <button
          {...props}
          type='button'
          className={"btn btn-rounded mb-2 me-2 " + props.className}
        >
          <i className='mdi mdi-check me-1'></i>
          { props.label }
        </button>
      </span>
      {props.disabled && (
        <Tooltip
          placement='left'
          isOpen={tooltip}
          target={tooltipId}
          toggle={() => setTooltip(!tooltip)}
        >
          { props.disabledDesc }
        </Tooltip>
      )}
    </>
  );
}

function Alert({ id, text, critical }) {
  const [tooltip, setTooltip] = useState(false);
  const tooltipId = 'payment-tooltip-' + id;
  const iconClasses = critical ? 'bxs-error-alt text-danger' : 'bxs-error text-warning';
  return (
    <>
      <Tooltip
        placement='top'
        isOpen={tooltip}
        target={tooltipId}
        toggle={() => setTooltip(!tooltip)}
      >
        {text}
      </Tooltip>
      <i id={tooltipId} className={`bx font-size-20 ${iconClasses}`}></i>
    </>
  );
}

function getConfirmedTotalAmount(product) {
  const confirmedAmount =  product.montantRemis && product.confirmedRate
    ? product.montantRemis / product.confirmedRate
    : 0;
  return Math.round((confirmedAmount + product.confirmedFees) * 100) / 100;
}

function checkAlert(product) {
  const { montantRetire, fraisTransfert, confirmedFees, errorCode, ErrorMessageConfig } =
    product;
  let message = null;
  let critical = false;
  if (montantRetire !== getConfirmedTotalAmount(product)) {
    message = 'Le montant confirmés est différent du montant attendu !';
  }
  if (fraisTransfert !== confirmedFees) {
    message = 'Le montant des frais confirmés est différent de celui attendu !';
  }
  if (errorCode) {
    message = `Erreur ${errorCode}`;
    if (ErrorMessageConfig && ErrorMessageConfig.message) {
      message += `: ${ErrorMessageConfig.message}`;
    }
    critical = true;
  }
  return { message, critical, errorCode };
}

export default PaymentsInitializedContent;
