import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToBeConfirmed } from "../../store/payments/slice";
import { Redirect } from 'react-router-dom';
import PaymentsModal from './Modals/PaymentsModal';
import { TO_BE_CONFIRMED } from "../../constants/paymentStatus";
import PaymentsInitializedContent from "./components/PaymentsInitializedContent";

function PaymentsConfirm() {
  const dispatch = useDispatch();
  const toBeConfirmed = useSelector((state) => state.payments.toBeConfirmed);
  const refresh = useSelector((state) => state.payments.refresh);
  const isLoading = useSelector((state) => state.payments.isLoading);

  useEffect(() => {
    dispatch(getToBeConfirmed());
  }, [refresh]);

  if (!isLoading && toBeConfirmed.length === 0) {
    return <Redirect to='/payments/create' />;
  }
  return (
    <>
      <PaymentsInitializedContent
        title='Paiements – Etape 2 : Confirmation ou Annulation des paiements créés'
        payments={toBeConfirmed}
        status={TO_BE_CONFIRMED}
      />
      <PaymentsModal />
    </>
  );
}

export default PaymentsConfirm;
