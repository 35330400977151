import { useSelector } from 'react-redux';
import ReportCard from './ReportCard';

function TotalAmount() {
  const selected = useSelector((state) => state.payments.selected);
  const totalAmount = selected.reduce((sum, row) => sum + row.expected_total, 0);
  return (
    <ReportCard
      title='Montant total'
      value={`${Math.round(totalAmount * 100) / 100} €`}
      iconClass='bx-euro'
    />
  );
}

export default TotalAmount;
