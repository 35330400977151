import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumb';
import { Card, CardBody, Col, Row, CardTitle, Table } from 'reactstrap';

import { fetchGet, fetchPut } from '../utility/apiFetch';
import { formateDate, isoToFullName } from '../utility';
import { PENDING, CANCELLED, TERMINATED } from '../constants/paymentStatus';
import PropTypes from 'prop-types';

function ModifierFacture() {
  document.title = 'Modifier Facture | Parametres';
  const location = useLocation();
  const history = useHistory();

  const paymentId = location.state.paiementId;
  const userId = location.state.userId;

  return (
    <div className='page-content'>
      <div className='container-fluid font-size-14'>
        <Breadcrumbs title='Paramètres' breadcrumbItem='Modifier Facture' />
        <PaymentEditForm
          paymentId={paymentId}
          username={userId}
          onSubmit={() => history.push('/factures')}
          onCancel={() => history.push('/factures')}
        />
      </div>
    </div>
  );
}

export function PaymentEditForm(props) {
  // Déclaration de variable contentant l'objet à modifier
  const [facture, setFacture] = useState();

  // Déclaration variables-objets pour MAJ Listes aide selects
  const [user, setUser] = useState();
  const [comptes, setComptes] = useState([]);
  const [operateursPaiement, setoperateursPaiement] = useState([]);

  // Déclaration variables des champs à mettre à jour
  const [dateDemande, setdateDemande] = useState('');
  const [factureCorrespondante, setfactureCorrespondante] = useState('');
  const [modePaiement, setmodePaiement] = useState('');
  const [dateExecution, setdateExecution] = useState('');
  const [nomOperateur, setnomOperateur] = useState('');
  const [referencePaiement, setreferencePaiement] = useState('');
  const [montantRetire, setmontantRetire] = useState('');
  const [montantTransfere, setmontantTransfere] = useState('');
  const [fraisTransfert, setfraisTransfert] = useState('');
  const [montantRemis, setmontantRemis] = useState('');
  const [monnaie, setmonnaie] = useState('');
  const [periodeFactureDebut, setperiodeFactureDebut] = useState('');
  const [periodeFactureFin, setperiodeFactureFin] = useState('');
  const [status, setstatus] = useState('');
  const [commentaire, setcommentaire] = useState('');
  const [compteId, setcompteId] = useState('');
  const [compte, setCompte] = useState('');

  // * MAJ des listes nécessaires pour les formulaires selects

  // Initialisation de l'objet Facture à partir de l'id récupérée sur l'écran précédent
  useEffect(() => {
    fetchGet('/payments/' + props.paymentId)
      .then((res) => setFacture(res))
      .catch((err) => console.log(err));
  }, [props.paymentId]);

  // Initialisation de l'objet User (contributeur) à partir de l'id récupérée sur l'écran précédent
  useEffect(() => {
    fetchGet('/users/user/' + props.username)
      .then((res) => setUser(res))
      .catch((err) => console.log(err));
  }, [props.username]);

  // Liste des comptes du contributeur
  useEffect(() => {
    if (user) {
      fetchGet('/comptes/user/' + props.username)
        .then((res) => {
          setComptes(res);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  // Liste des opérateurs de paiement
  useEffect(() => {
    fetchGet('/operateurs')
      .then((res) => setoperateursPaiement(res))
      .catch((err) => console.log(err));
  }, []);

  // Initialisation à la volée de toutes variables de la facture dès retour de l'API
  useEffect(() => {
    if (facture) {
      setdateDemande(facture.dateDemande);
      setfactureCorrespondante(facture.factureCorrespondante);
      setmodePaiement(facture.modePaiement);
      setcompteId(facture.compteMonetaireId);
      setdateExecution(facture.dateExecution);
      setnomOperateur(facture.nomOperateur);
      setreferencePaiement(facture.referencePaiement);
      setmontantRetire(facture.montantRetire);
      setmontantTransfere(facture.montantTransfere);
      setfraisTransfert(facture.fraisTransfert);
      setmontantRemis(facture.montantRemis);
      setmonnaie(facture.monnaie);
      setperiodeFactureDebut(facture.periodeFactureDebut);
      setperiodeFactureFin(facture.periodeFactureFin);
      setstatus(facture.status);
      setcommentaire(facture.commentaire);
      if (facture.compteMonetaireId) {
        fetchGet('/comptes/' + facture.compteMonetaireId).then((res) => setCompte(res));
      }
    }
  }, [facture]);

  // MAJ post-initialisation pour gérer les affichages sur changement
  useEffect(() => {
    if (compteId) {
      fetchGet('/comptes/' + compteId)
        .then((res) => setCompte(res))
        .catch((err) => console.log(err));
    }
  }, [compteId]);

  // Fonction de déclenchement de la mise à jour consécutive au click sur bouton 'Valider'
  const onModif = () => {
    const updatePaiement = {
      dateDemande: dateDemande || facture.dateDemande,
      factureCorrespondante: factureCorrespondante || facture.factureCorrespondante,
      modePaiement: modePaiement || facture.modePaiement,
      compteMonetaireId: compteId || facture.compteMonetaireId,
      dateExecution: dateExecution || facture.dateExecution,
      nomOperateur: nomOperateur || facture.nomOperateur,
      referencePaiement: referencePaiement || facture.referencePaiement,
      montantRetire: montantRetire || facture.montantRetire,
      montantTransfere: montantTransfere || facture.montantTransfere,
      fraisTransfert: fraisTransfert || facture.fraisTransfert,
      montantRemis: montantRemis || facture.montantRemis,
      monnaie: monnaie || facture.monnaie,
      periodeFactureDebut: periodeFactureDebut || facture.periodeFactureDebut,
      periodeFactureFin: periodeFactureFin || facture.periodeFactureFin,
      status: status || facture.status,
      commentaire: commentaire || null,
    };

    fetchPut('/payments/' + props.paymentId, updatePaiement)
      .then((res) => props.onSubmit())
      .catch((err) => console.log(err));
  };

  const onAmountChange = (e) => {
    setmontantRetire(e.target.value);
    const transferAmount = isNaN(parseFloat(e.target.value))
      ? 0
      : Math.round((e.target.value - fraisTransfert) * 100) / 100;
    setmontantTransfere(transferAmount);
  };

  const onFeesChange = (e) => {
    setfraisTransfert(e.target.value);
    const transferAmount = isNaN(parseFloat(e.target.value))
      ? montantRetire
      : Math.round((montantRetire - e.target.value) * 100) / 100;
    setmontantTransfere(transferAmount);
  };

  return (
    <>
      <Row>
        {user && facture ? (
          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle className='h4 mb-4'>Info Contributeur</CardTitle>
                  <div className='table-responsive'>
                    <Table className='table-nowrap mb-0'>
                      <tbody>
                        <tr>
                          <th scope='row'>Nom d'utilisateur </th>
                          <td>{user.username}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Nom complet </th>
                          <td>{user.prenom + ' ' + user.nom}</td>
                        </tr>
                        <tr>
                          <th scope='row'>E-mail personnel </th>
                          <td>{user.mailPerso}</td>
                        </tr>
                        <tr>
                          <th scope='row'>E-mail Lemissa </th>
                          <td>{user.mailLemissa}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Mobile </th>
                          <td>{user.mobile}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Adresse </th>
                          <td>
                            {user.adresse + ', ' + user.ville + ', ' + isoToFullName(user.pays)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className='h4 mb-4'>Mode de paiement & Commentaires</CardTitle>
                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Compte</label>
                    <div className='col-md-9'>
                      <select
                        value={compteId}
                        className='form-select'
                        onChange={(event) => setcompteId(event.target.value)}
                      >
                        <option key='_none' value=''>
                          - aucun compte sélectionné -
                        </option>
                        {(comptes || []).map((compte, id) => (
                          <option key={compte.id} value={compte.id}>
                            {compte.numeroCompte}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Opérateur</label>
                    <div className='col-md-9'>
                      <select
                        value={nomOperateur}
                        className='form-select'
                        onChange={(e) => setnomOperateur(e.target.value)}
                      >
                        <option key='_none' value=''>
                          - choisir -
                        </option>
                        {operateursPaiement.map((value, id) => (
                          <option key={value.nomOperateur} value={value.nomOperateur}>
                            {value.nomOperateur}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Mode</label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='text'
                        value={facture.modePaiement}
                        disabled
                        readOnly
                      />
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Banque</label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='text'
                        value={compte.etablissement}
                        readOnly
                        disabled
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Code Ets</label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='text'
                        value={compte.referenceEtablissement}
                        readOnly
                        disabled
                      />
                    </div>
                  </Row>
                  <Row></Row>

                  <Row className='mb-3'>
                    <label htmlFor='commentaire-input' className='col-md-3 col-form-label'>
                      Commentaire
                    </label>
                    <div className='col-md-9'>
                      <textarea
                        className='form-control'
                        rows='3'
                        placeholder='Ajouter un commentaire'
                        defaultValue={facture.commentaire}
                        onChange={(e) => setcommentaire(e.target.value)}
                      />
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle className='h4 mb-4'>En-tête Facture</CardTitle>
                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>N° Facture</label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='text'
                        defaultValue={facture.factureCorrespondante}
                        onChange={(e) => setfactureCorrespondante(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='date-demande-input' className='col-md-3 col-form-label'>
                      Date Facture
                    </label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='date'
                        defaultValue={formateDate(facture.dateDemande, 'YYYY-MM-DD')}
                        id='date-demande-input'
                        onChange={(e) => setdateDemande(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label
                      htmlFor='example-datetime-local-input'
                      className='col-md-3 col-form-label'
                    >
                      Début Période
                    </label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='date'
                        readOnly
                        disabled
                        defaultValue={formateDate(facture.periodeFactureDebut, 'YYYY-MM-DD')}
                        id='example-datetime-local-input'
                        onChange={(e) => setperiodeFactureDebut(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label
                      htmlFor='example-datetime-local-input'
                      className='col-md-3 col-form-label'
                    >
                      Fin période
                    </label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='date'
                        readOnly
                        disabled
                        defaultValue={formateDate(facture.periodeFactureFin, 'YYYY-MM-DD')}
                        id='example-datetime-local-input'
                        onChange={(e) => setperiodeFactureFin(e.target.value)}
                      />
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Montant - EUR</label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='number'
                        defaultValue={facture.montantRetire}
                        onChange={onAmountChange}
                      />
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className='h4 mb-4'>Info paiement</CardTitle>
                  <Row className='mb-3'>
                    <label
                      htmlFor='example-datetime-local-input'
                      className='col-md-3 col-form-label'
                    >
                      Date exécution
                    </label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='datetime-local'
                        id='example-datetime-local-input'
                        defaultValue={ facture.dateExecution
                          ? formateDate(facture.dateExecution, 'YYYY-MM-DDTHH:mm:ss')
                          : null
                        }
                        onChange={(e) => setdateExecution(e.target.value)}
                      />
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Réf. opération</label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='text'
                        defaultValue={facture.referencePaiement}
                        onChange={(e) => setreferencePaiement(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Frais Op. - EUR</label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='number'
                        defaultValue={facture.fraisTransfert}
                        onChange={onFeesChange}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Transféré - EUR</label>
                    <div className='col-md-9'>
                      <input
                        disabled
                        className='form-control'
                        type='number'
                        value={montantTransfere}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Remis</label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        type='text'
                        defaultValue={Intl.NumberFormat('fr-FR').format(facture.montantRemis)}
                        onChange={(e) => setmontantRemis(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Monnaie</label>
                    <div className='col-md-9'>
                      <select
                        defaultValue={facture.monnaie}
                        className='form-select'
                        id='floatingMonnaieInput'
                        placeholder='Monnaie'
                        onChange={(event) => setmonnaie(event.target.value)}
                      >
                        <option key='_none' value=''>
                          Choisissez la monnaie
                        </option>
                        <option key='EUR' value='EUR'>
                          EUR
                        </option>
                        <option key='MGA' value='MGA'>
                          MGA
                        </option>
                        <option key='USD' value='USD'>
                          USD
                        </option>
                        <option key='XAF' value='XAF'>
                          XAF
                        </option>
                        <option key='XOF' value='XOF'>
                          XOF
                        </option>
                      </select>
                    </div>
                  </Row>

                  <Row className='mb-3'>
                    <label className='col-md-3 col-form-label'>Statut</label>
                    <div className='col-md-9'>
                      <select
                        defaultValue={facture.status}
                        className='form-select'
                        id='selectStatusInput'
                        placeholder='Choisissez le Statut'
                        onChange={(event) => setstatus(event.target.value)}
                      >
                        <option key='_none' value=''>
                          {facture.status}
                        </option>
                        <option key={CANCELLED} value={CANCELLED}>
                          {CANCELLED}
                        </option>
                        <option key={PENDING} value={PENDING}>
                          {PENDING}
                        </option>
                        <option key={TERMINATED} value={TERMINATED}>
                          {TERMINATED}
                        </option>
                      </select>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Row>
      <Row className='px-4'>
        <Card>
          <CardBody>
            <div className='d-flex justify-content-center '>
              <button onClick={() => props.onCancel()} className='btn btn-secondary w-md m-2'>
                Annuler
              </button>
              <button onClick={onModif} className='btn btn-primary w-md m-2'>
                Modifier
              </button>
            </div>
          </CardBody>
        </Card>
      </Row>
    </>
  );
}

PaymentEditForm.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
};

PaymentEditForm.propTypes = {
  paymentId: PropTypes.number,
  username: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ModifierFacture;
