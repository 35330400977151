import React, { useMemo, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { formateDate } from '../utility';

import Breadcrumbs from '../components/Breadcrumb';
import TableContainer from '../components/TableContainer';
import { fetchGet } from '../utility/apiFetch';
import userEvent from '@testing-library/user-event';
import { useAuth0 } from '@auth0/auth0-react';

function ProductionsUser(props) {
  document.title = 'Table des Lignes Production Jour | Admin Lemissa';
  const [liste, setListe] = useState([]);
  const nickname = useAuth0().user?.nickname ?? null;

  useEffect(() => {
    fetchGet('/lignesprod/user/' + nickname)
      .then((res) => setListe(res))

      .catch((err) => console.log(err));
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Créée le',
        accessor: 'createdAt',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Dossier',
        accessor: 'dossier',
      },
      {
        Header: 'Date de production',
        accessor: 'date',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Type de Ligne',
        accessor: 'typeDoc',
      },
      {
        Header: 'Quantité',
        accessor: 'quantite',
      },
    ],
    [],
  );

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs
          title='Admin Lemissa'
          breadcrumbItem='Liste des lignes de Production standard'
        />
        <TableContainer
          columns={columns}
          data={liste}
          isGlobalFilter={true}
          customPageSize={50}
          className='custom-header-css'
        />
      </div>
    </div>
  );
}

export default ProductionsUser;
