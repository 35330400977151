import { createSlice } from '@reduxjs/toolkit';

const paymentNetworksSlice = createSlice({
  name: 'paymentNetworks',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getPaymentNetworks(state) {
      state.isLoading = true;
    },
    getPaymentNetworksSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    deletePaymentNetwork(state) {
      state.isLoading = true;
    },
    deletePaymentNetworkSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter((paymentNetwork) => paymentNetwork.id !== action.payload);
    },
  },
});

export const {
  getPaymentNetworks,
  getPaymentNetworksSuccess,
  deletePaymentNetwork,
  deletePaymentNetworkSuccess,
} = paymentNetworksSlice.actions;
export default paymentNetworksSlice;
