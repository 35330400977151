import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import { $CombinedState } from 'redux';
import { fetchGet } from '../../utility/apiFetch';
import PropTypes from 'prop-types';
import { formateDate } from '../../utility';

function DetailsLignes({ facture, user }) {
  const [ligneStandards, setligneStandards] = useState([]);
  const [lignesSpeciales, setlignesSpeciales] = useState([]);
  const [showLigneStandards, setshowLigneStandards] = useState(false);

  useEffect(() => {
    if (facture) {
      if (facture.periodeFactureDebut && facture.periodeFactureFin) {
        setshowLigneStandards(true);
        fetchGet(
          '/ligneprodjour/user/' +
            facture.username +
            '/' +
            facture.periodeFactureDebut +
            '/' +
            facture.periodeFactureFin,
        )
          .then((res) => {
            const fct = async () => {
              const results = Array();
              for (let i = 0; i < res.length; i++) {
                const tarif = await fetchGet(
                  '/tarifs/typepays/' + res[i].typeLigne + '/' + (user.pays ? user.pays : 'None'),
                );
                results.push(Object.assign(res[i], { tarifUnitaire: tarif.prix }));
              }

              return results;
            };
            fct().then((res) => setligneStandards(res));
          })
          .catch((err) => console.log(err));
      }

      fetchGet('/lignespeciales/facture/' + facture.id)
        .then((res) => {
          const fct = async () => {
            const results = Array();
            for (let i = 0; i < res.length; i++) {
              const tarif = await fetchGet(
                '/tarifs/typepays/' + res[i].typeLigne + '/' + (user.pays ? user.pays : 'None'),
              );
              results.push(Object.assign(res[i], { tarifUnitaire: tarif.prix }));
            }

            return results;
          };
          fct().then((res) => setlignesSpeciales(res));
        })
        .catch((err) => console.log(err));
    }
  }, [facture]);
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className='mb-4'>Détail lignes</CardTitle>
              <div className='table-responsive font-size-16'>
                <Table className='mb-0'>
                  <tbody>
                    <tr>
                      <th></th>
                      <th>Dossier</th>
                      <th>Type de Ligne</th>
                      <th>Du</th>
                      <th>Au</th>
                      <th>Nombre d'UO</th>
                      <th>Tarif UO</th>
                      <th>Montant</th>
                    </tr>

                    {showLigneStandards &&
                      ligneStandards.map((line) => (
                        <tr>
                          <td>
                            <i className='far fa-star'></i>{' '}
                          </td>
                          <td>{line.dossier}</td>
                          <td>{line.typeLigne}</td>
                          <td>{formateDate(facture.periodeFactureDebut)}</td>
                          <td>{formateDate(facture.periodeFactureFin)}</td>
                          <td>{line.nbUnite}</td>
                          <td>{line.tarifUnitaire.toFixed(3)} €</td>
                          <td>{(line.nbUnite * line.tarifUnitaire).toFixed(2)} €</td>
                        </tr>
                      ))}

                    {lignesSpeciales.map((line) => (
                      <tr>
                        <td>
                          <i className='fas fa-star'></i>{' '}
                        </td>
                        <td>{line.dossier}</td>
                        <td>{line.typeLigne}</td>
                        <td>{formateDate(line.periodeDateDebut)}</td>
                        <td>{formateDate(line.periodeDateFin)}</td>
                        <td>{line.quantite}</td>
                        <td>{line.tarifUnitaire.toFixed(3)} €</td>
                        <td>{(line.quantite * line.tarifUnitaire).toFixed(2)} €</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

DetailsLignes.propTypes = {
  facture: PropTypes.any,
};

export default DetailsLignes;
