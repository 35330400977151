import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getPaymentNetworks } from '../../store/paymentNetworks/slice';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

function PaymentNetworksSelect(props) {
  const dispatch = useDispatch();
  let paymentNetworks = useSelector((state) => state.paymentNetworks.items);

  useEffect(() => {
    dispatch(getPaymentNetworks());
  }, []);

  if (props.networkType) {
    paymentNetworks = paymentNetworks.filter(
      (paymentNetwork) => paymentNetwork.type === props.networkType,
    );
  }

  return (
    <Input type='select' className='form-control' {...props}>
      <option key='_none' value=''>
        - choisir -
      </option>
      {paymentNetworks.map((paymentNetwork) => (
        <option key={paymentNetwork.id} value={paymentNetwork.id}>
          {paymentNetwork.name}
        </option>
      ))}
    </Input>
  );
}

PaymentNetworksSelect.propTypes = { networkType: PropTypes.string };

export default PaymentNetworksSelect;
