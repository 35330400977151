import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  getSpecialLines,
  getSpecialLinesSuccess,
  deleteSpecialLine,
  deleteSpecialLineSuccess,
} from './slice';

function* getSpecialLinesSaga() {
  const response = yield call(httpClient.instance.get, '/lignesspec');
  if (response.status !== undefined && response.status === 200) {
    yield put(getSpecialLinesSuccess(response.data));
  }
}

function* deleteSpecialLineSaga(action) {
  const specialLineId = action.payload;
  const response = yield call(httpClient.instance.delete, `/lignesspec/${specialLineId}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteSpecialLineSuccess(specialLineId));
  }
}

export default function* specialLinesSaga() {
  yield takeLatest(getSpecialLines, getSpecialLinesSaga);
  yield takeLatest(deleteSpecialLine, deleteSpecialLineSaga);
}
