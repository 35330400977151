import TransferServices from './TransferService/TransferServices';
import ErrorMessageConfigs from './ErrorMessageConfig/ErrorMessageConfigs';
import OrangeMoneySettings from "./OrangeMoneySettings";
import Breadcrumbs from '../../components/Breadcrumb';
import { Container } from 'reactstrap';

function ApiParameters() {
  document.title = 'Paramètres API | Paramètres';
  return (
    <div className='page-content'>
      <Container fluid>
        <Breadcrumbs title='Parametres' breadcrumbItem='Paramètres API – Orange Money' />
        <OrangeMoneySettings />
        <ErrorMessageConfigs />
        <TransferServices />
      </Container>
    </div>
  );
}

export default ApiParameters;
