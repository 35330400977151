import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumb';
import TableContainer from '../components/TableContainer';
import ValidationPopup from '../components/ValidationPopup';
import { isoToFullName, formateDate } from '../utility';

import { getContributors, deleteContributor } from '../store/contributors/slice';

function Contributors() {
  const history = useHistory();
  const dispatch = useDispatch();
  const contributors = useSelector((state) => state.contributors.items);

  const [validationOpen, setvalidationOpen] = useState(false);
  const [validationDeleteId, setvalidationDeleteId] = useState();
  const [validationText, setvalidationText] = useState('');

  const toggleValidation = () => setvalidationOpen(!validationOpen);
  const adderHandler = () => history.push('/contributeur/ajouter');
  const onDelete = () => dispatch(deleteContributor(validationDeleteId));

  useEffect(() => {
    dispatch(getContributors());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'username',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Prenom',
        accessor: 'prenom',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Nom',
        accessor: 'nom',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Email personnel',
        accessor: 'mailPerso',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Date entrée Prod',
        accessor: 'dateEntreeProd',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Ville',
        accessor: 'ville',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Pays',
        accessor: 'pays',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? isoToFullName(cellProps.value) : '';
        },
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <Link
                to={{
                  pathname: '/contributeur/modifier',
                  state: { contributorObject: cellProps.row.original },
                }}
                className='text-success'
              >
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Modifier
                </UncontrolledTooltip>
              </Link>
              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationText(
                    `Etes vous sur de vouloir supprimer ${cellProps.row.original.username} - ${cellProps.row.original.nom} ${cellProps.row.original.prenom}`,
                  );
                  setvalidationDeleteId(cellProps.row.original.id);
                  toggleValidation();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  // meta title
  document.title = 'Table des Contributeurs | Parametres';

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Table des Contributeurs' />
        <TableContainer
          columns={columns}
          data={contributors}
          isGlobalFilter={true}
          isAddUserList={true}
          handleUserClick={adderHandler}
          addUserName='ajouter un contributeur'
          customPageSize={15}
          className='custom-header-css'
        />
      </div>
      <ValidationPopup
        isOpen={validationOpen}
        toogle={toggleValidation}
        fct={onDelete}
        text={validationText}
      />
    </div>
  );
}

export default Contributors;
