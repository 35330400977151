import { paymentAccountType } from '../../constants/payment';

function AccountTypeSelect(props) {
  return (
    <select className='form-control' {...props}>
      <option key={paymentAccountType.BANK_ACCOUNT} value={paymentAccountType.BANK_ACCOUNT}>
        {paymentAccountType.BANK_ACCOUNT}
      </option>
      <option key={paymentAccountType.MOBILE_MONEY} value={paymentAccountType.MOBILE_MONEY}>
        {paymentAccountType.MOBILE_MONEY}
      </option>
      <option key={paymentAccountType.CASH_TRANSFER} value={paymentAccountType.CASH_TRANSFER}>
        {paymentAccountType.CASH_TRANSFER}
      </option>
    </select>
  );
}

export default AccountTypeSelect;
