import { createSlice } from '@reduxjs/toolkit';

const lineGroupsSlice = createSlice({
  name: 'lineGroups',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getLineGroups(state) {
      state.isLoading = true;
    },
    getLineGroupsSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    addLineGroup(state) {
      state.isLoading = true;
    },
    addLineGroupSuccess(state, action) {
      state.isLoading = false;
      state.items.push(action.payload);
    },
    deleteLineGroup(state) {
      state.isLoading = true;
    },
    deleteLineGroupSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter(
        (lineGroup) =>
          !(
            lineGroup.typeLigne === action.payload.lineType &&
            lineGroup.dossier === action.payload.dossier &&
            lineGroup.typeDoc === action.payload.docType
          ),
      );
    },
    setDefaultLineGroup(state) {
      state.isLoading = true;
    },
    setDefaultLineGroupSuccess(state, action) {
      state.isLoading = false;

      const { dossier, lineType, docType } = action.payload;
      const oldDefaultIndex = state.items.findIndex(
        (item) => item.dossier === dossier && item.isDefault === true,
      );
      const newDefaultIndex = state.items.findIndex(
        (item) =>
          item.dossier === dossier && item.typeLigne === lineType && item.typeDoc === docType,
      );

      if (oldDefaultIndex !== -1) {
        state.items[oldDefaultIndex].isDefault = false;
      }
      if (newDefaultIndex !== -1) {
        state.items[newDefaultIndex].isDefault = true;
      }
    },
  },
});

export const {
  getLineGroups,
  getLineGroupsSuccess,
  addLineGroup,
  addLineGroupSuccess,
  deleteLineGroup,
  deleteLineGroupSuccess,
  setDefaultLineGroup,
  setDefaultLineGroupSuccess,
} = lineGroupsSlice.actions;
export default lineGroupsSlice;
