import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formateDate } from '../utility';
import { getProductionLines } from '../store/productionLines/slice';

import Breadcrumbs from '../components/Breadcrumb';
import TableContainer from '../components/TableContainer';

function Productions(props) {
  document.title = 'Table des Lignes Production Jour | Admin Lemissa';

  const dispatch = useDispatch();
  const productionLines = useSelector((state) => state.productionLines.items);

  useEffect(() => {
    dispatch(getProductionLines());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Créée le',
        accessor: 'createdAt',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Dossier',
        accessor: 'dossier',
      },
      {
        Header: 'Date de production',
        accessor: 'date',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Type de Ligne',
        accessor: 'typeDoc',
      },
      {
        Header: 'Quantité',
        accessor: 'quantite',
      },
    ],
    [],
  );

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs
          title='Admin Lemissa'
          breadcrumbItem='Liste des lignes de Production standard'
        />
        <TableContainer
          columns={columns}
          data={productionLines}
          isGlobalFilter={true}
          customPageSize={50}
          className='custom-header-css'
        />
      </div>
    </div>
  );
}

export default Productions;
