import { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import Breadcrumbs from '../components/Breadcrumb';
import { isoToFullName } from '../utility';
import { fetchGet } from '../utility/apiFetch';

function FicheContributeur(props) {
  document.title = 'Fiche Contributeur | Contributeurs';
  const nickname = 'test';
  const [user, setUser] = useState();
  const [comptes, setComptes] = useState([]);

  useEffect(() => {
    fetchGet('/users/user/' + nickname)
      .then((res) => setUser(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchGet('/comptes/user/' + nickname)
      .then((res) => setComptes(res))
      .catch((err) => console.log(err));
  }, [props.username]);

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Contributeurs' breadcrumbItem='Fiche Contributeur' />
        <Row>
          <Col xl={6}>
            <Card>
              <CardBody>
                <CardTitle className='mb-4'>Information profil </CardTitle>
                {user ? (
                  <div className='table-responsive font-size-14'>
                    <Table className='table-nowrap mb-0'>
                      <tbody>
                        <tr>
                          <th scope='row'>Nom d'utilisateur </th>
                          <td>{user.username}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Prénom</th>
                          <td>{user.prenom}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Nom </th>
                          <td>{user.nom}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Email personnel </th>
                          <td>{user.mailPerso}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Email Lemissa </th>
                          <td>{user.mailLemissa}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Mobile </th>
                          <td>{user.mobile}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Ville </th>
                          <td>{user.ville}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Pays </th>
                          <td>{isoToFullName(user.pays)}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Adresse </th>
                          <td>{user.adresse}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <></>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl={6}>
            <Card>
              <CardBody>
                <CardTitle className='mb-4'>Comptes Monetaires</CardTitle>
                {comptes.map((value) => (
                  <div className='container mb-4 shadow rounded bg-light'>
                    <div className='table-responsive font-size-16'>
                      <Table className='table-nowrap mb-0'>
                        <tbody>
                          <tr>
                            <th scope='row'>Type Compte</th>
                            <td>{value.typeCompte}</td>
                          </tr>
                          <tr>
                            <th scope='row'>Numero Compte</th>
                            <td>{value.numeroCompte}</td>
                          </tr>
                          <tr>
                            <th scope='row'>Nom Etablissement</th>
                            <td>{value.etablissement}</td>
                          </tr>
                          {value.referenceEtablissement ? (
                            <tr>
                              <th scope='row'>Reference Etablissement</th>
                              <td>{value.referenceEtablissement}</td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <th scope='row'>Compte par Defaut ?</th>
                            <td>{value.isDefault ? 'Oui' : 'Non'}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FicheContributeur;
