import { useState } from 'react';
import BaseModal from './BaseModal';
import { useDispatch, useSelector } from 'react-redux';
import { cancel, confirm, initiate, refresh } from "../../../store/payments/slice";
import * as yup from 'yup';
import PasswordInput from "../../../components/Input/PasswordInput";
import { Row, Spinner } from "reactstrap";

function PaymentsModal() {
  const action = useSelector((state) => state.payments.modal.action);

  switch (action) {
    case 'create':
      return <CreateModal />;
    case 'confirm':
      return <ConfirmModal />;
    case 'refresh':
      return <RefreshModal />;
    case 'revert':
      return <RevertModal />;
    case 'cancel':
      return <CancelModal />;
    case 'result':
      return <ResultModal />;
    default:
      return <></>;
  }
}

function SimpleModal({ children }) {
  return (
    <BaseModal
      icon='alert'
      iconClassName='text-warning'
      title='Attention'
      cancelBtn={{ show: true, style: 'success', label: 'Ok' }}
    >
      {children}
    </BaseModal>
  );
}

function ConfirmModalBase({ icon, title, label, selected, onSubmit, hidePassword }) {
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState();

  if (selected.length === 0) {
    return <SimpleModal>Veuillez d'abord sélectionner des paiements !</SimpleModal>;
  }

  const operators = [...new Set(selected.map((p) => p.network))];
  if (operators.length > 1) {
    return (
      <SimpleModal>
        Opérateurs sélectionnés : <strong>{operators.join(', ')}</strong>
        <br />
        Tous les paiements sélectionnés doivent être liés au même opérateur !
      </SimpleModal>
    );
  }

  const paymentNotAllowed = selected.some((p) => !p.allowPayment);
  if (paymentNotAllowed) {
    return (
      <SimpleModal>
        Opérateur : <strong>{operators[0]}</strong>
        <br />
        Le traitement des paiements n'est pas autorisé pour l'opérateur sélectionné !
      </SimpleModal>
    );
  }

  const count = selected.length;
  let amount = selected.reduce((total, item) => total + item.expected_total, 0);
  amount = Math.round((amount) * 100) / 100;

  yup.setLocale({
    mixed: {
      required: 'Ceci est un champ obligatoire',
    },
  });

  const passSchema = yup.string().required();

  async function onChangeValidation(value) {
    try {
      setPassword(await passSchema.validate(value));
      setErrors([]);
    } catch (err) {
      setErrors(err.errors);
      setPassword(null);
    }
  }

  function onConfirmSubmit() {
    if (password || hidePassword) {
      onSubmit(password);
    } else {
      // Trigger validation to set error message.
      onChangeValidation();
    }
  }

  return (
    <BaseModal
      icon={icon}
      title={title}
      backdrop={'static'}
      submitBtn={{
        show: true,
        style: 'success',
        label: 'Confirmer l’action',
        onSubmit: onConfirmSubmit,
      }}
    >
      <p>
        Vous allez confirmer <strong>{label}</strong> par API du règlement suivant :<br />
        <strong>{count} paiement(s)</strong> pour un montant total de <strong>{amount}</strong> €
        <br />
        Exécution par l’opérateur <strong>{operators[0]}</strong>.
      </p>
      { hidePassword ||
        <PasswordInput
          label="Entrez la clé de sécurité pour valider la transaction :"
          id="paymentmodalpassInput"
          onChange={(event) => onChangeValidation(event.target.value)}
          errors={errors}
        />
      }
    </BaseModal>
  );
}

function CreateModal() {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.payments.selected);
  const isLoading = useSelector((state) => state.payments.isLoading);
  if (isLoading) {
    return <LoadingSpinner />
  }
  const onSubmit = (password) =>
    dispatch(initiate({ payments: selected.map((p) => p.id), password }));
  return (
    <ConfirmModalBase
      icon='alert'
      title='Création à confirmer'
      label='la création'
      selected={selected}
      onSubmit={onSubmit}
    />
  );
}

function ConfirmModal() {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.payments.selected);
  const isLoading = useSelector((state) => state.payments.isLoading);
  if (isLoading) {
    return <LoadingSpinner />
  }
  const onSubmit = (password) =>
    dispatch(confirm({ payments: selected.map((p) => p.id), password }));
  return (
    <ConfirmModalBase
      icon='alert'
      title='Paiement à confirmer'
      label='l’exécution'
      selected={selected}
      onSubmit={onSubmit}
    />
  );
}

function RefreshModal() {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.payments.selected);
  const isLoading = useSelector((state) => state.payments.isLoading);
  if (isLoading) {
    return <LoadingSpinner />
  }
  const onSubmit = (password) =>
    dispatch(refresh({ payments: selected.map((p) => p.id), password }));
  return (
    <ConfirmModalBase
      icon='alert'
      title='Paiement à rafraîchir'
      label="l'obtention"
      selected={selected}
      onSubmit={onSubmit}
    />
  );
}

function RevertModal() {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.payments.selected);
  const isLoading = useSelector((state) => state.payments.isLoading);
  if (isLoading) {
    return <LoadingSpinner />
  }
  const onSubmit = (password) =>
    dispatch(cancel({ payments: selected.map((p) => p.id), password }));
  return (
    <ConfirmModalBase
      icon='trash-can'
      title='Annulation à confirmer'
      label='l’annulation'
      selected={selected}
      onSubmit={onSubmit}
    />
  );
}

function CancelModal() {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.payments.selected);
  const apiCallRequired = selected.some(row => row.transfer_number);
  const isLoading = useSelector((state) => state.payments.isLoading);
  if (isLoading) {
    return <LoadingSpinner />
  }
  const onSubmit = password =>
    dispatch(cancel({ payments: selected.map((p) => p.id), password }));
  return (
    <ConfirmModalBase
      icon='trash-can'
      title='Annulation à confirmer'
      label='l’annulation'
      selected={selected}
      onSubmit={onSubmit}
      hidePassword={!apiCallRequired}
    />
  );
}

function ResultModal() {
  const context = useSelector((state) => state.payments.modal.context);

  const isSuccess = context.success.num > 0
  const isInProgress = context.inProgress.num > 0
  const isFailed = context.failed.num > 0

  let icon = 'alert'
  let iconClass = ''
  let title = 'Aie ! Erreur d’API !'

  if (context.type === 'confirm') {
    title = 'Resultats de la confirmation'
  }
  if (context.type === 'refresh') {
    title = 'Résultats de la Mise à jour'
  }

  if (isSuccess && !isFailed && !isInProgress) {
    icon = 'check'
    iconClass = 'text-success'
  }
  if (isInProgress && !isSuccess && !isFailed) {
    icon = 'information'
    iconClass = 'text-warning'
  }
  if (isFailed && (isSuccess || isInProgress)) {
    iconClass = 'text-warning'
  }

  return (
    <BaseModal
      icon={icon}
      iconClassName={iconClass}
      title={title}
      cancelBtn={{ show: true, style: 'success', label: 'Ok' }}
    >
      <p>
        <strong>{context.operator}</strong> a confirmé les transactions comme suit :<br />
        {isSuccess && (
          <div>
            <strong>{context.success.num} paiement(s)</strong> pour un montant total de{' '}
            <strong>{Math.round(context.success.amount * 100) / 100} €</strong> confirmé(s)
          </div>
        )}
        {isInProgress && (
          <div>
            <strong>{context.inProgress.num} paiement(s)</strong> pour un montant total de{' '}
            <strong>{Math.round(context.inProgress.amount * 100) / 100} €</strong> en cours
          </div>
        )}
        {isFailed && (
          <div>
            <strong>{context.failed.num} paiement(s)</strong> pour un montant total de{' '}
            <strong>{Math.round(context.failed.amount * 100) / 100} €</strong> échoué(s)
          </div>
        )}
        Pensez à suivre les transactions en cours.
      </p>
    </BaseModal>
  );
}

function LoadingSpinner() {
  return <Row className="justify-content-center"><Spinner className='ms-1' color='primary' /></Row>
}

export default PaymentsModal;
