import httpClient from './httpClient';

/**
 * This utility is deprecated and will be fully removed.
 */

export const fetchGet = async (route) => {
  const response = await httpClient.instance.get(route);
  return response.data;
};

export const fetchPost = async (route, data) => {
  return httpClient.instance.post(route, data);
};

export const fetchUpload = async (route, data) => {
  return httpClient.instance.post(route, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const fetchPut = async (route, data) => {
  return httpClient.instance.put(route, data);
};

export const fetchDelete = async (route) => {
  return httpClient.instance.delete(route);
};
