import { useState } from 'react';
import { Col, Row, Form, Card, CardBody, FormFeedback, FormGroup, Input } from 'reactstrap';
import { fetchPost } from '../../utility/apiFetch';
import AccountTypeSelect from '../../components/Select/AccountTypeSelect';
import { paymentAccountType } from '../../constants/payment';
import { allCountriesAlpha3 } from '../../utility';
import * as yup from 'yup';

function AddPaymentNetwork(props) {
  const [type, setType] = useState(paymentAccountType.BANK_ACCOUNT);
  const [name, setName] = useState();
  const [country, setCountry] = useState();
  const [networkId, setNetworkId] = useState();
  const [typeErrors, setTypeErrors] = useState([]);
  const [nameErrors, setNameErrors] = useState([]);

  yup.setLocale({
    mixed: {
      required: 'Ceci est un champ obligatoire',
    },
  });

  async function validateType(value) {
    try {
      const typeSchema = yup.string().required();
      await typeSchema.validate(value);
      setTypeErrors([]);
      return true;
    } catch (err) {
      setTypeErrors(err.errors);
    }
    return false;
  }
  async function validateName(value) {
    try {
      const nameSchema = yup.string().required();
      await nameSchema.validate(value);
      setNameErrors([]);
      return true;
    } catch (err) {
      setNameErrors(err.errors);
    }
    return false;
  }

  async function onSend() {
    if ((await validateType(type)) && (await validateName(name))) {
      const newPaymentNetwork = { type, name, country, networkId };
      fetchPost('/payment-networks', newPaymentNetwork)
        .then((res) => props.onSubmit())
        .catch((err) => console.log(err));
    }
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <FormGroup className='form-floating mb-3'>
                <AccountTypeSelect
                  id='payment-network-type'
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                    validateType(e.target.value);
                  }}
                  invalid={typeErrors.length > 0 ? true : undefined}
                />
                {typeErrors.map((error) => (
                  <FormFeedback type='invalid' key={error}>
                    {error}
                  </FormFeedback>
                ))}
                <label htmlFor='payment-network-type'>Type de réseau</label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className='form-floating mb-3'>
                <Input
                  type='text'
                  className='form-control'
                  id='payment-network-name'
                  placeholder='Nom du Réseau'
                  defaultValue=''
                  onChange={(e) => {
                    setName(e.target.value);
                    validateName(e.target.value);
                  }}
                  invalid={nameErrors.length > 0 ? true : undefined}
                />
                {nameErrors.map((error) => (
                  <FormFeedback type='invalid' key={error}>
                    {error}
                  </FormFeedback>
                ))}
                <label htmlFor='payment-network-name'>Nom du Réseau</label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className='form-floating mb-3'>
                <select
                  id='payment-network-country'
                  className='form-select'
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option key='_none' value=''>
                    - choisir -
                  </option>
                  {allCountriesAlpha3.map(({ value, name }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </select>
                <label htmlFor='payment-network-country'>Pays</label>
              </FormGroup>
            </Col>
          </Row>
          {type === paymentAccountType.BANK_ACCOUNT && (
            <Row>
              <Col>
                <FormGroup className='form-floating mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    id='payment-network-id'
                    placeholder='Reference Etablissement'
                    defaultValue=''
                    onChange={(event) => setNetworkId(event.target.value)}
                  />
                  <label htmlFor='payment-network-id'>Reference Etablissement</label>
                </FormGroup>
              </Col>
            </Row>
          )}
          <div className='d-flex justify-content-end'>
            <button onClick={props.onCancel} className='btn btn-secondary w-md m-2'>
              Annuler
            </button>
            <button onClick={onSend} className='btn btn-primary w-md m-2'>
              Valider
            </button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

export default AddPaymentNetwork;
