import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import { formateDate, isoToFullName } from '../utility';
import Breadcrumbs from '../components/Breadcrumb';
import TableContainer from '../components/TableContainer';

import ValidationPopup from '../components/ValidationPopup';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTariff, getTariffs } from '../store/tariffs/slice';

function Tarif() {
  const history = useHistory();
  const dispatch = useDispatch();
  const tariffs = useSelector((state) => state.tariffs.items);

  const [validationOpen, setvalidationOpen] = useState(false);
  const [validationDeleteId, setvalidationDeleteId] = useState();
  const [validationText, setvalidationText] = useState('');

  const toggleValidation = () => setvalidationOpen(!validationOpen);
  const addHandler = () => history.push('/tarif/ajouter');
  const onDelete = () => dispatch(deleteTariff(validationDeleteId));

  useEffect(() => {
    dispatch(getTariffs());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Dossier',
        accessor: 'dossier',
      },
      {
        Header: 'Type de ligne',
        accessor: 'typeLigne',
      },
      {
        Header: 'Pays',
        accessor: 'pays',
        Cell: (cellProps) => {
          return cellProps.value ? isoToFullName(cellProps.value) : 'Tous';
        },
      },
      {
        Header: 'Date début de validité',
        accessor: 'validiteDateDebut',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Date fin de validité',
        accessor: 'validiteDateFin',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Prix',
        accessor: 'prix',
        Cell: (cellProps) => {
          return cellProps.value.toFixed(3) + ' €';
        },
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <Link
                to={{
                  pathname: '/tarif/modifier',
                  state: { tarifObject: cellProps.row.original },
                }}
                className='text-success'
              >
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Editer
                </UncontrolledTooltip>
              </Link>
              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationText(
                    `Etes vous sur de vouloir supprimer ${cellProps.row.original.typeLigne} pour ${cellProps.row.original.dossier}`,
                  );
                  setvalidationDeleteId(cellProps.row.original.id);
                  toggleValidation();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  // meta title
  document.title = 'Table des Tarifs | Parametres';

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Table des Tarifs' />
        <TableContainer
          columns={columns}
          data={tariffs}
          isGlobalFilter={true}
          isAddUserList={true}
          handleUserClick={addHandler}
          addUserName='ajouter un tarif'
          customPageSize={15}
          className='custom-header-css'
        />
      </div>
      <ValidationPopup
        isOpen={validationOpen}
        toogle={toggleValidation}
        fct={onDelete}
        text={validationText}
      />
    </div>
  );
}

export default Tarif;
