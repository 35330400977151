import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  cancel,
  cancelSuccess,
  confirm,
  refresh,
  confirmSuccess,
  deletePending,
  deletePendingSuccess,
  getPending,
  getPendingSuccess,
  getToBeConfirmed,
  getToBeConfirmedSuccess,
  getInProgress,
  getInProgressSuccess,
  initiate,
  initiateSuccess,
  search,
  searchSuccess, refreshSuccess
} from "./slice";
import { IN_PROGRESS, PENDING, TO_BE_CONFIRMED } from "../../constants/paymentStatus";

function* searchSaga({ payload }) {
  let params = {};
  if (payload) {
    params = payload;
  }
  const response = yield call(httpClient.instance.get, '/payments/search', { params });
  if (response.status !== undefined && response.status === 200) {
    yield put(searchSuccess(response.data));
  }
}

function* getPendingSaga({ payload }) {
  const response = yield call(httpClient.instance.get, '/payments/search', {
    params: {
      status: PENDING,
      operator: payload,
      order: ['createdAt'],
    },
  });
  if (response.status !== undefined && response.status === 200) {
    yield put(getPendingSuccess(response.data));
  }
}

function* deletePendingSaga(action) {
  const invoiceId = action.payload;
  const response = yield call(httpClient.instance.delete, `/payments/${invoiceId}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deletePendingSuccess(invoiceId));
  }
}

function* getToBeConfirmedSaga(action) {
  const response = yield call(httpClient.instance.get, '/payments/search', {
    params: {
      status: TO_BE_CONFIRMED,
      order: ['errorCode'],
    },
  });
  if (response.status !== undefined && response.status === 200) {
    yield put(getToBeConfirmedSuccess(response.data));
  }
}

function* getInProgressSaga(action) {
  const response = yield call(httpClient.instance.get, '/payments/search', {
    params: {
      status: IN_PROGRESS,
      order: ['errorCode'],
    },
  });
  if (response.status !== undefined && response.status === 200) {
    yield put(getInProgressSuccess(response.data));
  }
}

function* initiateSaga({ payload }) {
  const response = yield call(httpClient.instance.post, '/payments/initiate', payload);
  if (response.status !== undefined && response.status === 200) {
    yield put(initiateSuccess(response.data));
  }
}

function* confirmSaga({ payload }) {
  const response = yield call(httpClient.instance.post, '/payments/confirm', payload);
  if (response.status !== undefined && response.status === 200) {
    yield put(confirmSuccess(response.data));
  }
}

function* refreshSaga({ payload }) {
  const response = yield call(httpClient.instance.post, '/payments/refresh', payload);
  if (response.status !== undefined && response.status === 200) {
    yield put(refreshSuccess(response.data));
  }
}

function* cancelSaga({ payload }) {
  const response = yield call(httpClient.instance.post, '/payments/cancel', payload);
  if (response.status !== undefined && response.status === 200) {
    yield put(cancelSuccess(response.data));
  }
}

export default function* paymentsSaga() {
  yield takeLatest(search, searchSaga);
  yield takeLatest(getPending, getPendingSaga);
  yield takeLatest(deletePending, deletePendingSaga);
  yield takeLatest(getToBeConfirmed, getToBeConfirmedSaga);
  yield takeLatest(getInProgress, getInProgressSaga);
  yield takeLatest(initiate, initiateSaga);
  yield takeLatest(confirm, confirmSaga);
  yield takeLatest(refresh, refreshSaga);
  yield takeLatest(cancel, cancelSaga);
}
