import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';

import Layout from './layout/reducer';
import Login from './login/reducer';
import invoicesSlice from './invoices/slice';
import contributorsSlice from './contributors/slice';
import tariffsSlice from './tariffs/slice';
import operatorsSlice from './operators/slice';
import specialLinesSlice from './specialLines/slice';
import productionLinesSlice from './productionLines/slice';
import dossiersSlice from './dossiers/slice';
import lineTypesSlice from './lineTypes/slice';
import lineGroupsSlice from './lineGroups/slice';
import paymentsSlice from './payments/slice';
import paymentNetworksSlice from './paymentNetworks/slice';
import transferServicesSlice from './transferServices/slice';
import errorMessageConfigsSlice from './errorMessageConfigs/slice';

import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const stateSyncMiddleware = createStateSyncMiddleware();

const store = configureStore({
  reducer: {
    Layout,
    Login,
    invoices: invoicesSlice.reducer,
    contributors: contributorsSlice.reducer,
    tariffs: tariffsSlice.reducer,
    operators: operatorsSlice.reducer,
    specialLines: specialLinesSlice.reducer,
    productionLines: productionLinesSlice.reducer,
    dossiers: dossiersSlice.reducer,
    lineTypes: lineTypesSlice.reducer,
    lineGroups: lineGroupsSlice.reducer,
    payments: paymentsSlice.reducer,
    paymentNetworks: paymentNetworksSlice.reducer,
    transferServices: transferServicesSlice.reducer,
    errorMessageConfigs: errorMessageConfigsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Uncomment if development env is too slow.
      // serializableCheck: false,
    })
      .concat(sagaMiddleware)
      .concat(stateSyncMiddleware),
  // serializableCheck: false,
});

sagaMiddleware.run(rootSaga);
initStateWithPrevTab(store);

// store.subscribe(() => console.log(store.getState()))

export default store;
