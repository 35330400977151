import { all, fork } from 'redux-saga/effects';

import LayoutSaga from './layout/saga';
import authSaga from './login/saga';
import invoicesSaga from './invoices/saga';
import contributorsSaga from './contributors/saga';
import tariffsSaga from './tariffs/saga';
import operatorsSaga from './operators/saga';
import specialLinesSaga from './specialLines/saga';
import productionLinesSaga from './productionLines/saga';
import dossiersSaga from './dossiers/saga';
import lineTypesSaga from './lineTypes/saga';
import lineGroupsSaga from './lineGroups/saga';
import paymentsSaga from './payments/saga';
import paymentNetworksSaga from './paymentNetworks/saga';
import transferServicesSaga from './transferServices/saga';
import ErrorMessageConfigsSaga from './errorMessageConfigs/saga';

export default function* rootSaga() {
  try {
    yield all([
      fork(LayoutSaga),
      fork(authSaga),
      fork(invoicesSaga),
      fork(contributorsSaga),
      fork(tariffsSaga),
      fork(operatorsSaga),
      fork(specialLinesSaga),
      fork(productionLinesSaga),
      fork(dossiersSaga),
      fork(lineTypesSaga),
      fork(lineGroupsSaga),
      fork(paymentsSaga),
      fork(paymentNetworksSaga),
      fork(transferServicesSaga),
      fork(ErrorMessageConfigsSaga),
    ]);
  } catch (err) {
    console.error('ERROR: rootSaga', err);
  }
}
