import { useState } from 'react';
import { Card, CardBody, Col, Row, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { fetchPost } from '../../../utility/apiFetch';
import * as yup from 'yup';

function AddErrorMessageConfig(props) {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [codeErrors, setCodeErrors] = useState([]);
  const [messageErrors, setMessageErrors] = useState([]);

  yup.setLocale({
    mixed: {
      required: 'Ceci est un champ obligatoire',
    },
  });

  async function validateCode(value) {
    try {
      await yup.string().required().validate(value);
      setCodeErrors([]);
      return true;
    } catch (err) {
      setCodeErrors(err.errors);
    }
    return false;
  }
  async function validateMessage(value) {
    try {
      await yup.string().required().validate(value);
      setMessageErrors([]);
      return true;
    } catch (err) {
      setMessageErrors(err.errors);
    }
    return false;
  }

  async function onSend() {
    if ((await validateCode(code)) && (await validateMessage(message))) {
      const errorMessageConfig = { code, message };
      fetchPost('/error-message-configs', errorMessageConfig)
        .then((res) => props.onSubmit())
        .catch((err) => console.log(err));
    }
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <FormGroup className='form-floating mb-3'>
                <Input
                  type='number'
                  className='form-control'
                  id='error-message-config-code'
                  placeholder='Code erreur'
                  onChange={(e) => {
                    setCode(e.target.value);
                    validateCode(e.target.value);
                  }}
                  invalid={codeErrors.length > 0 ? true : undefined}
                />
                {codeErrors.map((error) => (
                  <FormFeedback type='invalid' key={error}>
                    {error}
                  </FormFeedback>
                ))}
                <label htmlFor='error-message-config-code'>Code erreur</label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className='form-floating mb-3'>
                <Input
                  type='text'
                  className='form-control'
                  id='error-message-config-message'
                  placeholder='Message'
                  onChange={(e) => {
                    setMessage(e.target.value);
                    validateMessage(e.target.value);
                  }}
                  invalid={messageErrors.length > 0 ? true : undefined}
                />
                {messageErrors.map((error) => (
                  <FormFeedback type='invalid' key={error}>
                    {error}
                  </FormFeedback>
                ))}
                <label htmlFor='error-message-config-message'>Nom du Réseau</label>
              </FormGroup>
            </Col>
          </Row>
          <div className='d-flex justify-content-end'>
            <button onClick={props.onCancel} className='btn btn-secondary w-md m-2'>
              Annuler
            </button>
            <button onClick={onSend} className='btn btn-primary w-md m-2'>
              Valider
            </button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

export default AddErrorMessageConfig;
