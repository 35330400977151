import React, { useMemo } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { formateDate } from '../../utility';

import { PropTypes } from 'prop-types';

function FactureModal({ isOpen, title, toggle, infoFacture }) {
  const tableColumns = useMemo(
    () => [
      {
        title: "Nom d'utilisateur ",
        value: infoFacture && infoFacture.username ? infoFacture.username : '',
      },
      {
        title: 'Date de Demande ',
        value:
          infoFacture && infoFacture.dateDemande
            ? formateDate(infoFacture.dateDemande, 'DD/MM/YYYY')
            : '',
      },
      {
        title: "Date d'execution ",
        value:
          infoFacture && infoFacture.dateExecution
            ? formateDate(infoFacture.dateExecution, 'DD/MM/YYYY')
            : '',
      },
      {
        title: 'Mode de paiement ',
        value: infoFacture && infoFacture.modePaiement ? infoFacture.modePaiement : '',
      },
      {
        title: "Nom de l'operateur de paiement",
        value:
          infoFacture && infoFacture.nomOperateurPaiement ? infoFacture.nomOperateurPaiement : '',
      },
      {
        title: 'Frais de Transfert ',
        value: infoFacture && infoFacture.fraisTransfert ? infoFacture.fraisTransfert : '',
      },
      {
        title: 'Montant Retire ',
        value: infoFacture && infoFacture.montantRetire ? infoFacture.montantRetire : '',
      },
      {
        title: 'Montant Transfere ',
        value: infoFacture && infoFacture.montantTransfere ? infoFacture.montantTransfere : '',
      },
      {
        title: 'Montant Remis ',
        value: infoFacture && infoFacture.montantRemis ? infoFacture.montantRemis : '',
      },
      { title: 'Monnaie ', value: infoFacture && infoFacture.monnaie ? infoFacture.monnaie : '' },
      {
        title: "Facture d'operation ",
        value: infoFacture && infoFacture.factureOperation ? infoFacture.factureOperation : '',
      },
      {
        title: 'Facture Correspondante ',
        value:
          infoFacture && infoFacture.factureCorrespondante ? infoFacture.factureCorrespondante : '',
      },
      {
        title: 'Periode de Debut ',
        value:
          infoFacture && infoFacture.periodeFactureDebut
            ? formateDate(infoFacture.periodeFactureDebut, 'DD/MM/YYYY')
            : '',
      },
      {
        title: 'Periode de Fin ',
        value:
          infoFacture && infoFacture.periodeFactureFin
            ? formateDate(infoFacture.periodeFactureFin, 'DD/MM/YYYY')
            : '',
      },
      {
        title: 'Reference de Paiement ',
        value: infoFacture && infoFacture.referencePaiement ? infoFacture.referencePaiement : '',
      },
      { title: 'Status ', value: infoFacture && infoFacture.status ? infoFacture.status : '' },
      {
        title: 'Commentaire ',
        value: infoFacture && infoFacture.commentaires ? infoFacture.commentaires : '',
      },
    ],
    [infoFacture],
  );

  return (
    <Modal
      isOpen={isOpen}
      role='dialog'
      autoFocus={true}
      centered={true}
      className='exampleModal'
      toggle={toggle}
      size='lg'
    >
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <div className='table-responsive'>
            <Table className='table-nowrap mb-0 font-size-16'>
              <tbody>
                {tableColumns.map(({ title, value }) =>
                  value ? (
                    <tr>
                      <th scope='row'>{title}</th>
                      <td>{value}</td>
                    </tr>
                  ) : (
                    <></>
                  ),
                )}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type='button' color='secondary' onClick={toggle}>
            Fermer
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

FactureModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  infoFacture: PropTypes.any,
};
export default FactureModal;
