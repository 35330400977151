import React, { useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumb';
import ProfileContent from '../components/Profile/ProfileContent';
import 'flatpickr/dist/themes/material_blue.css';
import { fetchGet } from '../utility/apiFetch';
import { previousDay, formateDate } from '../utility';
import { useAuth0 } from '@auth0/auth0-react';
import { getContributorLastBillingDate } from "../utility/contributorFunc";
import DateFlatpickrInput from "../components/Input/DateFlatpickrInput";

function CompteLemissa(props) {
  document.title = 'Compte Lemissa | Contributeurs';
  const dateFormat = 'YYYY-MM-DD';

  const [selectedUser, setselectedUser] = useState(null);
  const nickname = useAuth0().user?.nickname ?? null;

  const [dateDebut, setdateDebut] = useState('2021-01-01');
  const [dateFin, setdateFin] = useState(previousDay());
  const [lastUpdate, setlastUpdate] = useState('');
  const startDate = useMemo(() => '2021-01-01', []);
  const [lastBillingDate, setLastBillingDate] = useState('');

  useEffect(() => {
    fetchGet('/users/user/' + nickname)
      .then((res) => setselectedUser(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchGet('/lignesprod/lastupdate')
      .then((res) => {
        setdateFin(formateDate(res, dateFormat))
        setlastUpdate(formateDate(res, dateFormat))
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (selectedUser) {
      getContributorLastBillingDate(selectedUser).then(date => {
        setLastBillingDate(date);
        setdateDebut(date);
      })
    }
  }, [selectedUser]);


  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Contributeurs' breadcrumbItem='Compte Lemissa' />
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col xl={4}></Col>
                <DateFlatpickrInput
                  label='Date de Début'
                  placeholder='Choisir une Date de debut'
                  defaultValue={lastBillingDate < lastUpdate ? lastBillingDate : '' }
                  minDate={startDate}
                  maxDate={lastUpdate}
                  onChange={([date]) => {
                    setdateDebut(formateDate(date, dateFormat));
                  }}
                />
                <DateFlatpickrInput
                  label='Date de Fin'
                  placeholder='Choisir une date de fin'
                  defaultValue={lastUpdate}
                  minDate={startDate}
                  maxDate={lastUpdate}
                  onChange={([date]) => {
                    setdateFin(formateDate(date, dateFormat));
                  }}
                />
              </Row>
            </CardBody>
          </Card>
        </Row>
        {selectedUser ? (
          <ProfileContent user={selectedUser} dateDebut={dateDebut} dateFin={dateFin} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default CompteLemissa;
