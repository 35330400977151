import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter, Link } from 'react-router-dom';

import LogoutButton from '../logout-button';

// i18n
// import { withTranslation } from "react-i18next";

const SidebarContentAdmin = (props) => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    let pathName = props.location.pathname;
    pathName = pathName.split('/');
    pathName = '/' + pathName[1];
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
      console.log('found his parent ! !');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className='h-100' ref={ref}>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled font-size-24' id='side-menu'>
            <li className='menu-title font-size-12'>Contributeurs </li>
            <li>
              <Link to='/comptelemissa'>
                <i className='bx bx-money'></i>
                <span>Mon compte Lemissa</span>
              </Link>
            </li>
            {/* Temp Profil */}
            {/* <li>
            <Link to="/profil">
              <i className="bx bxs-user"></i>
              <span>Profil Test</span>  
            </Link>
            </li> */}

            {/* { isAdmin ? : <></>} */}
            <li className='menu-title font-size-12'>Admin Lemissa </li>
            <li>
              <Link to='/contributeur'>
                <i className='bx bx-user-plus'></i>
                <span>Contributeurs</span>
              </Link>
            </li>
            <li>
              <Link to='/comptecontributor'>
                <i className='bx bxs-user-detail'></i>
                <span>Compte Contributeur</span>
              </Link>
            </li>
            <li>
              <Link to='/consulterfacture'>
                <i className='bx bx-file'></i>
                <span>Détail factures</span>
              </Link>
            </li>
            <li>
              <Link to='/factures'>
                <i className='bx bx-list-ul'></i>
                <span>Factures</span>
              </Link>
            </li>
            <li>
              <Link to='/payments'>
                <i className='bx bx-euro'></i>
                <span>Paiements</span>
              </Link>
            </li>
            <li>
              <Link to='/payments-in-progress'>
                <i className='bx bx-euro'></i>
                <span>Paiements en cours</span>
              </Link>
            </li>
            <li>
              <Link to='/productions'>
                <i className='bx bx-list-ul'></i>
                <span>Productions standards</span>
              </Link>
            </li>
            <li>
              <Link to='/importstatdeprod'>
                <i className='bx bx-import'></i>
                <span>Imports</span>
              </Link>
            </li>

            <li className='menu-title font-size-12'>Paramètres </li>
            <li>
              <Link to='/dossiersetlignes'>
                <i className='bx bxs-file-find'></i>
                <span>Dossiers{' & '}Lignes dossiers</span>
              </Link>
            </li>
            <li>
              <Link to='/lignespeciale'>
                <i className='bx bxs-layer'></i>
                <span>Lignes Spéciales</span>
              </Link>
            </li>
            <li>
              <Link to='/operateurs'>
                <i className='bx bxs-bank'></i>
                <span>Opérateurs de paiements</span>
              </Link>
            </li>
            <li>
              <Link to='/tarif'>
                <i className='fas fa-money-check-alt'></i>
                <span>Tarifs</span>
              </Link>
            </li>
            <li>
              <Link to='/payment-networks'>
                <i className='bx bxs-share-alt'></i>
                <span>Réseau Paiement</span>
              </Link>
            </li>
            <li>
              <Link to='/parameters-api'>
                <i className='mdi mdi-alert-outline'></i>
                <span>Paramètres API</span>
              </Link>
            </li>

            <li className='menu-title font-size-12'>Journaux</li>
            <li>
              <Link to='/logs/transaction-errors'>
                <i className='bx bx-error-alt'></i>
                <span>Erreurs de transactions</span>
              </Link>
            </li>

            <li className='menu-title  font-size-12'>Déconnection </li>
            <li>
              <Link to='/logout'>
                <i className='bx bx-power-off'></i>
                <span>Se déconnecter</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContentAdmin.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(SidebarContentAdmin);
