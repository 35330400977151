import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import { isoToFullName, formateDate } from '../../utility';
import PropTypes from 'prop-types';

function ProfileFacture(props) {
  const [userProfile, setuserProfile] = useState(props.user);
  const [factureToShow, setfactureToShow] = useState();
  const [compte, setCompte] = useState();

  useEffect(() => {
    setuserProfile(props.user);
  }, [props.user]);

  useEffect(() => {
    setfactureToShow(props.facture);
  }, [props.facture]);

  const tableColumns = useMemo(
    () => [
      { title: 'N° Facture ', value: factureToShow ? factureToShow.factureCorrespondante : '' },
      {
        title: 'Date de Demande ',
        value:
          factureToShow && factureToShow.dateDemande ? formateDate(factureToShow.dateDemande) : '',
      },
      {
        title: 'Du ',
        value:
          factureToShow && factureToShow.periodeFactureDebut
            ? formateDate(factureToShow.periodeFactureDebut)
            : '',
      },
      { title: 'Au ', value: factureToShow ? formateDate(factureToShow.periodeFactureFin) : '' },
      {
        title: 'Montant Retiré ',
        value: factureToShow ? factureToShow.montantRetire.toFixed(2) + ' €' : '',
      },
      { title: 'Statut ', value: factureToShow ? factureToShow.status : '' },
      { title: 'Commentaire ', value: factureToShow ? factureToShow.commentaires : '' },
    ],
    [factureToShow],
  );

  const tableColumnsPaiement = useMemo(
    () => [
      {
        title: "Date d'exécution ",
        value:
          factureToShow && factureToShow.dateExecution
            ? formateDate(factureToShow.dateExecution)
            : '',
      },
      {
        title: 'Frais de Transfert ',
        value:
          factureToShow && factureToShow.fraisTransfert
            ? factureToShow.fraisTransfert.toFixed(2) + ' €'
            : '0.00 €',
      },
      {
        title: 'Montant Transféré ',
        value:
          factureToShow && factureToShow.montantTransfere
            ? factureToShow.montantTransfere.toFixed(2) + ' €'
            : '',
      },
      {
        title: 'Montant Remis ',
        value: factureToShow ? Intl.NumberFormat('fr-FR').format(factureToShow.montantRemis) : '',
      }, // format d'affichage
      { title: 'Monnaie ', value: factureToShow ? factureToShow.monnaie : '' },
      {
        title: 'Reférence de Paiement ',
        value: factureToShow ? factureToShow.referencePaiement : '',
      },
      { title: 'Status ', value: factureToShow ? factureToShow.status : '' },
      { title: 'Commentaire ', value: factureToShow ? factureToShow.commentaires : '' },
    ],
    [factureToShow],
  );

  return (
    <React.Fragment>
      <Row>
        <Col xl={6}>
          <Card>
            <CardBody>
              <CardTitle className='mb-4'>Information contributeur</CardTitle>
              <p className='text-muted mb-4'>{userProfile.personalDetail}</p>
              <div className='table-responsive'>
                <Table className='mb-0'>
                  <tbody>
                    <tr>
                      <th scope='row'>Nom d'utilisateur </th>
                      <td>{userProfile.username}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Nom complet </th>
                      <td>{userProfile.prenom + ' ' + userProfile.nom}</td>
                    </tr>
                    <tr>
                      <th scope='row'>E-mail personnel </th>
                      <td>{userProfile.mailPerso}</td>
                    </tr>
                    <tr>
                      <th scope='row'>E-mail lemissa </th>
                      <td>{userProfile.mailLemissa}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Mobile </th>
                      <td>{userProfile.mobile}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Adresse </th>
                      <td>
                        {userProfile.adresse +
                          ', ' +
                          userProfile.ville +
                          ', ' +
                          isoToFullName(userProfile.pays)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle className='mb-4'>Mode de paiement</CardTitle>

              <div className='table-responsive'>
                <Table className='mb-0'>
                  <tbody>
                    <tr>
                      <th scope='row'>Mode de paiement</th>
                      <td>{factureToShow ? factureToShow.modePaiement : ''}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Opérateur de paiement</th>
                      <td>{factureToShow ? factureToShow.nomOperateurPaiement : ''}</td>
                    </tr>
                    {compte ? (
                      <>
                        <tr>
                          <th scope='row'>Numéro de Compte</th>
                          <td>{compte.numeroCompte}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Etablissement</th>
                          <td>{compte.etablissement}</td>
                        </tr>
                        <tr>
                          <th scope='row'>Reference Etablissement</th>
                          <td>{compte.referenceEtablissement}</td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6}>
          <Card>
            <CardBody>
              <CardTitle className='mb-4'>{factureToShow ? 'Entête Facture  ' : <></>}</CardTitle>

              <div className='table-responsive'>
                <Table className='mb-0'>
                  <tbody>
                    {tableColumns.map(({ title, value }) => (
                      <tr>
                        <th scope='row'>{title}</th>
                        <td>{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle className='mb-4'>{factureToShow ? 'Info paiement  ' : <></>}</CardTitle>

              <div className='table-responsive'>
                <Table className='mb-0'>
                  <tbody>
                    {tableColumnsPaiement.map(({ title, value }) =>
                      value ? (
                        <tr>
                          <th scope='row'>{title}</th>
                          <td>{value}</td>
                        </tr>
                      ) : (
                        <></>
                      ),
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

ProfileFacture.propTypes = {
  user: PropTypes.any,
  facture: PropTypes.any,
};

export default ProfileFacture;
