import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumb';
import moment from 'moment';
import { Card, CardBody, Col, Row, CardTitle, Container, Label, Input, Form } from 'reactstrap';

import { formateDate, formatEurFr, allCountriesAlpha3 } from '../utility';
import { fetchGet, fetchPut } from '../utility/apiFetch';
function ModifierTarif(props) {
  document.title = 'Modifier Tarif | Parametres';
  const [tarif, setTarif] = useState(new Object());
  const location = useLocation();
  const history = useHistory();
  const { tarifObject } = location.state;

  const [dossier, setDossier] = useState('');
  const [typeLigne, settypeLigne] = useState('');
  const [pays, setpays] = useState('');
  const [prix, setprix] = useState('');
  const [validiteDateDebut, setvaliditeDateDebut] = useState('');
  const [validiteDateFin, setvaliditeDateFin] = useState('');
  const [commentaire, setcommentaire] = useState('');

  const [dossiersListe, setDossiersListe] = useState([]);
  const [ligneTypes, setligneTypes] = useState([]);

  useEffect(() => {
    fetchGet('/dossiers')
      .then((res) => setDossiersListe(res))
      .catch((err) => console.log(err));
    if (tarifObject.dossier) {
      setDossier(tarifObject.dossier);
    }
  }, []);

  useEffect(() => {
    fetchGet('/typelignes')
      .then((res) => setligneTypes(res))
      .catch((err) => console.log(err));
    if (tarifObject.typeLigne) {
      settypeLigne(tarifObject.typeLigne);
    }
  }, []);

  useEffect(() => {
    const obj = new Object({
      dossier: tarifObject.dossier ? tarifObject.dossier : 'Dossier',
      typeLigne: tarifObject.typeLigne ? tarifObject.typeLigne : 'Type de ligne',
      pays: tarifObject.pays ? tarifObject.pays : '',
      validiteDateDebut: tarifObject.validiteDateDebut
        ? formateDate(tarifObject.validiteDateDebut, 'YYYY-MM-DD')
        : '',
      validiteDateFin: tarifObject.validiteDateFin
        ? formateDate(tarifObject.validiteDateFin, 'YYYY-MM-DD')
        : '',
      prix: tarifObject.prix ? tarifObject.prix : '0',
      commentaire: tarifObject.commentaire ? tarifObject.commentaire : '',
    });
    setTarif(obj);
  }, [tarifObject]);

  const onModif = () => {
    const newTarif = {
      dossier: dossier !== '' ? dossier : tarif.dossier,
      typeLigne: typeLigne !== '' ? typeLigne : tarif.typeLigne,
      pays: pays !== '' ? pays : tarif.pays,
      prix: prix !== '' ? prix : tarif.prix,
      validiteDateDebut: validiteDateDebut !== '' ? validiteDateDebut : tarif.validiteDateDebut,
      validiteDateFin: validiteDateFin !== '' ? validiteDateFin : tarif.validiteDateFin,
      commentaire: commentaire || null,
    };
    fetchPut('/tarifs/' + tarifObject.id, newTarif)
      .then((res) => {
        history.push('/tarif');
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Paramètres' breadcrumbItem='Modifier Tarif' />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className='h4 mb-4'>Info Contributeur</CardTitle>

                <Row className='mb-3'>
                  <label htmlFor='dossier' className='col-md-2 col-form-label'>
                    Dossier
                  </label>
                  <div className='col-md-4'>
                    <select
                      value={dossier}
                      id='dossier'
                      className='form-select'
                      onChange={(event) => setDossier(event.target.value)}
                    >
                      {dossiersListe.map(({ dossier }) => (
                        <option value={dossier}>{dossier}</option>
                      ))}
                    </select>
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label htmlFor='typedoc' className='col-md-2 col-form-label'>
                    Type de ligne
                  </label>
                  <div className='col-md-4'>
                    <select
                      value={typeLigne}
                      id='typedoc'
                      className='form-select'
                      onChange={(event) => settypeLigne(event.target.value)}
                    >
                      {ligneTypes.map((ligne) => {
                        if (ligne.dossier === dossier)
                          return <option value={ligne.typeLigne}>{ligne.typeLigne}</option>;
                      })}
                    </select>
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Pays</label>
                  <div className='col-md-4'>
                    <select
                      defaultValue={tarifObject.pays ? tarifObject.pays : ''}
                      className='form-select'
                      onChange={(e) => setpays(e.target.value)}
                    >
                      <option value=''>Tous les Pays</option>
                      {allCountriesAlpha3.map(({ value, name }) => (
                        <option value={value}>{name}</option>
                      ))}
                    </select>
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Prix UO - EUR</label>
                  <div className='col-md-4'>
                    <input
                      className='form-control'
                      type='text'
                      defaultValue={formatEurFr(tarifObject.prix, 3)}
                      onChange={(e) => setprix(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label htmlFor='datetime-end-input' className='col-md-2 col-form-label'>
                    Date debut de validité
                  </label>
                  <div className='col-md-4'>
                    <input
                      className='form-control'
                      type='date'
                      defaultValue={formateDate(
                        new Date(tarifObject.validiteDateDebut),
                        'YYYY-MM-DD',
                      )}
                      id='datetime-start-input'
                      onChange={(e) => setvaliditeDateDebut(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label htmlFor='datetime-end-input' className='col-md-2 col-form-label'>
                    Date fin de validité
                  </label>
                  <div className='col-md-4'>
                    <input
                      className='form-control'
                      type='date'
                      defaultValue={formateDate(
                        new Date(tarifObject.validiteDateFin),
                        'YYYY-MM-DD',
                      )}
                      id='datetime-end-input'
                      onChange={(e) => setvaliditeDateFin(e.target.value)}
                    />
                  </div>
                </Row>

                <Row className='mb-3'>
                  <label htmlFor='comment-input' className='col-md-2 col-form-label'>
                    Commentaire
                  </label>
                  <div className='col-md-4'>
                    <textarea
                      className='form-control'
                      rows='4'
                      placeholder='Ajouter un commentaire'
                      defaultValue={tarifObject.commentaire}
                      onChange={(e) => setcommentaire(e.target.value)}
                    />
                  </div>
                </Row>
                <Row>
                  <div className='d-flex justify-content-center'>
                    <Link
                      to='/tarif'
                      className='m-2'
                      // ref='annuler'
                    >
                      <button className='btn btn-secondary w-md'>Annuler</button>
                    </Link>
                    <button onClick={onModif} className='btn btn-primary w-md m-2'>
                      Modifier
                    </button>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ModifierTarif;
