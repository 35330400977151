import { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumb';
import ContributorsSelect from '../../components/Select/ContributorsSelect';
import 'flatpickr/dist/themes/material_blue.css';
import { fetchGet } from '../../utility/apiFetch';

import ProfileFacture from './ProfileFacture';
import DetailsLignes from './DetailsLignes';
import { useSelector } from 'react-redux';

function DetailFacture(props) {
  document.title = 'Consulter les Factures | Admin Lemissa';

  const selectedContributor = useSelector((state) => state.contributors.selected);

  const [selectedFacture, setselectedFacture] = useState(null);
  const [userFactures, setuserFactures] = useState([]);

  useEffect(() => {
    setselectedFacture(userFactures[0]);
  }, [userFactures]);

  useEffect(() => {
    if (selectedContributor) {
      fetchGet(`/payments/user/${selectedContributor.username}`)
        .then((res) => setuserFactures(res.reverse()))
        .catch((err) => console.log(err));
    }
  }, [selectedContributor]);

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Admin Lemissa' breadcrumbItem='Consulter les factures' />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col xl={6}>
                    <ContributorsSelect title={'Contributeurs'}></ContributorsSelect>
                  </Col>
                  <Col xl={6}>
                    <CardTitle className='mb-3 text-muted'>Factures</CardTitle>

                    <select
                      id='pafactures'
                      className='form-select'
                      onChange={(e) => {
                        const tab = [...userFactures];
                        setselectedFacture(tab[e.target.value]);
                      }}
                    >
                      {userFactures.map((facture, id) => (
                        <option key={id} value={id}>
                          {facture.factureCorrespondante}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {selectedContributor ? (
          <ProfileFacture user={selectedContributor} facture={selectedFacture} />
        ) : (
          <div
            className='d-flex p-2 justify-content-center align-items-center'
            style={{ height: '400px' }}
          >
            <h1>Choisir un Contributeur</h1>
          </div>
        )}

        {selectedContributor ? (
          <DetailsLignes facture={selectedFacture} user={selectedContributor} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default DetailFacture;
