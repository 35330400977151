import { useMemo, useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumb';
import { Row, Col, Card, CardBody, CardTitle, Badge, UncontrolledTooltip } from 'reactstrap';
import TableContainer from '../../components/TableContainer';
import DossierModal from './DossierModal';

import TypeModal from './TypeModal';
import CorrModal from './CorrModal';
import { Link } from 'react-router-dom';
import ValidationPopup from '../../components/ValidationPopup';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDossier, getDossiers } from '../../store/dossiers/slice';
import { deleteLineType, getLineTypes } from '../../store/lineTypes/slice';
import { deleteLineGroup, getLineGroups, setDefaultLineGroup } from '../../store/lineGroups/slice';

function DocLine(props) {
  document.title = 'Dossiers et lignes dossiers | Parmètres';

  const dispatch = useDispatch();
  const dossiers = useSelector((state) => state.dossiers.items);
  const lineTypes = useSelector((state) => state.lineTypes.items);
  const lineGroups = useSelector((state) => state.lineGroups.items);

  const [showDossierModal, setShowDossierModal] = useState(false);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [showCorrModal, setShowCorrrModal] = useState(false);

  const [validationDossierOpen, setvalidationDossierOpen] = useState(false);
  const [validationDossierDelete, setvalidationDossierDelete] = useState();
  const [validationDossierText, setvalidationDossierText] = useState('');
  const toogleValidationDossier = () => setvalidationDossierOpen(!validationDossierOpen);

  const [validationTypeOpen, setvalidationTypeOpen] = useState(false);
  const [validationTypeDelete, setvalidationTypeDelete] = useState();
  const [validationTypeText, setvalidationTypeText] = useState('');
  const toogleValidationType = () => setvalidationTypeOpen(!validationTypeOpen);

  const [validationCorrOpen, setvalidationCorrOpen] = useState(false);
  const [validationCorrDelete, setvalidationCorrDelete] = useState();
  const [validationCorrText, setvalidationCorrText] = useState('');
  const toogleValidationCorr = () => setvalidationCorrOpen(!validationCorrOpen);

  const toggleShowDossierModal = () => setShowDossierModal(!showDossierModal);
  const toggleShowTypeModal = () => setShowTypeModal(!showTypeModal);
  const toggleShowCorrrModal = () => setShowCorrrModal(!showCorrModal);

  const [dossierData, setdossierData] = useState('');
  const [typeData, settypeData] = useState('');
  const [corrData, setcorrData] = useState('');

  useEffect(() => {
    dispatch(getDossiers());
    dispatch(getLineTypes());
    dispatch(getLineGroups());
  }, []);

  const onAddDossierClick = () => {
    setdossierData('');
    toggleShowDossierModal();
  };

  const onDeleteDossierClick = (cellProps) =>
    dispatch(deleteDossier(cellProps.row.original.dossier));

  const onAddLigneTypeClick = () => {
    settypeData('');
    toggleShowTypeModal();
  };

  const onDeleteLineTypeClick = (cellProps) =>
    dispatch(
      deleteLineType({
        dossier: cellProps.row.original.dossier,
        lineType: cellProps.row.original.typeLigne,
      }),
    );

  const onAddCorrClick = () => {
    setcorrData('');
    toggleShowCorrrModal();
  };

  const onSetDefaultLineGroupClick = (cellProps) =>
    dispatch(
      setDefaultLineGroup({
        dossier: cellProps.row.original.dossier,
        lineType: cellProps.row.original.typeLigne,
        docType: cellProps.row.original.typeDoc,
      }),
    );

  const onDeleteLineGroupClick = (cellProps) =>
    dispatch(
      deleteLineGroup({
        dossier: cellProps.row.original.dossier,
        lineType: cellProps.row.original.typeLigne,
        docType: cellProps.row.original.typeDoc,
      }),
    );

  const docsColumns = useMemo(
    () => [
      {
        Header: 'Nom du dossier',
        accessor: 'dossier',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              {/* L'option modification est suspendue (compte tenu des risques) -  En cas de modif Suppression et recréation */}

              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationDossierText(
                    'Etes vous sur de vouloir supprimer ' + cellProps.row.original.dossier,
                  );
                  setvalidationDossierDelete(cellProps);
                  toogleValidationDossier();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Effacer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );
  const typeColumns = useMemo(
    () => [
      {
        Header: 'Nom du dossier',
        accessor: 'dossier',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Type de ligne',
        accessor: 'typeLigne',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              {/* L'option modification est suspendue (compte tenu des risques) -  En cas de modif Suppression et recréation */}

              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationTypeText(
                    'Etes vous sur de vouloir supprimer ' +
                      cellProps.row.original.typeLigne +
                      ' pour ' +
                      cellProps.row.original.dossier,
                  );
                  setvalidationTypeDelete(cellProps);
                  toogleValidationType();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Effacer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );
  const correspColumns = useMemo(
    () => [
      {
        Header: 'Nom du dossier',
        accessor: 'dossier',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Type de ligne',
        accessor: 'typeLigne',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Type de Document',
        accessor: 'typeDoc',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Ligne par defaut',
        accessor: 'isDefault',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={'font-size-14 badge-soft-' + (cellProps.value ? 'success' : 'danger')}
              pill
            >
              {cellProps.value ? 'Oui' : 'Non'}
            </Badge>
          );
        },
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              {/* L'option modification est suspendue (compte tenu des risques) -  En cas de modif Suppression et recréation */}

              <Link
                to='#'
                className='text-info'
                onClick={() => onSetDefaultLineGroupClick(cellProps)}
              >
                <i className='mdi mdi-adjust font-size-18' id='setdefault' />
                <UncontrolledTooltip placement='top' target='setdefault'>
                  Definir par défaut
                </UncontrolledTooltip>
              </Link>

              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationCorrText(
                    'Etes vous sur de vouloir supprimer définitivement ' +
                      cellProps.row.original.typeDoc +
                      ' pour ' +
                      cellProps.row.original.dossier +
                      '-' +
                      cellProps.row.original.typeLigne,
                  );
                  setvalidationCorrDelete(cellProps);
                  toogleValidationCorr();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Dossiers et lignes dossiers' />
        <Row>
          <Col lg={5}>
            <Card>
              <CardBody>
                <CardTitle className='mb-4'>Liste des dossiers </CardTitle>
                <TableContainer
                  columns={docsColumns}
                  data={dossiers}
                  isGlobalFilter={true}
                  isAddUserList={true}
                  handleUserClick={onAddDossierClick}
                  addUserName='ajouter un dossier'
                  customPageSize={10}
                  className='custom-header-css'
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={7}>
            <Card>
              <CardBody>
                <CardTitle className='mb-4'>Liste des lignes de dossiers </CardTitle>
                <TableContainer
                  columns={typeColumns}
                  data={lineTypes}
                  isGlobalFilter={true}
                  isAddUserList={true}
                  handleUserClick={onAddLigneTypeClick}
                  addUserName='ajouter un type de ligne'
                  customPageSize={10}
                  className='custom-header-css'
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className='mb-4'>
                  Correspondance lignes dossiers - types de documents{' '}
                </CardTitle>
                <TableContainer
                  columns={correspColumns}
                  data={lineGroups}
                  isGlobalFilter={true}
                  isAddUserList={true}
                  handleUserClick={onAddCorrClick}
                  addUserName='ajouter une correspondance'
                  customPageSize={20}
                  className='custom-header-css'
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <DossierModal
          isOpen={showDossierModal}
          toggle={toggleShowDossierModal}
          data={dossierData}
        />
        <TypeModal
          dossiersListe={dossiers}
          isOpen={showTypeModal}
          toggle={toggleShowTypeModal}
          data={typeData}
        />
        <CorrModal
          dossiersListe={dossiers}
          ligneDossiersListe={lineTypes}
          isOpen={showCorrModal}
          toggle={toggleShowCorrrModal}
          data={corrData}
        />

        <ValidationPopup
          isOpen={validationDossierOpen}
          toogle={toogleValidationDossier}
          fct={() => onDeleteDossierClick(validationDossierDelete)}
          text={validationDossierText}
        />
        <ValidationPopup
          isOpen={validationTypeOpen}
          toogle={toogleValidationType}
          fct={() => onDeleteLineTypeClick(validationTypeDelete)}
          text={validationTypeText}
        />
        <ValidationPopup
          isOpen={validationCorrOpen}
          toogle={toogleValidationCorr}
          fct={() => onDeleteLineGroupClick(validationCorrDelete)}
          text={validationCorrText}
        />
      </div>
    </div>
  );
}

export default DocLine;
