import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  getTransferServices,
  getTransferServicesSuccess,
  deleteTransferService,
  deleteTransferServiceSuccess,
} from './slice';

function* getTransferServicesSaga() {
  const response = yield call(httpClient.instance.get, '/transfer-services');
  if (response.status !== undefined && response.status === 200) {
    yield put(getTransferServicesSuccess(response.data));
  }
}

function* deleteTransferServiceSaga(action) {
  const id = action.payload;
  const response = yield call(httpClient.instance.delete, `/transfer-services/${id}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteTransferServiceSuccess(id));
  }
}

export default function* TransferServicesSaga() {
  yield takeLatest(getTransferServices, getTransferServicesSaga);
  yield takeLatest(deleteTransferService, deleteTransferServiceSaga);
}
