import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { PropTypes } from 'prop-types';

import { fetchPut } from '../../utility/apiFetch';
import { addLineGroup } from '../../store/lineGroups/slice';

function CorrModal(props) {
  const dispatch = useDispatch();

  const { isOpen, toggle } = props;

  const [dossiersListe, setDossiersListe] = useState([]);
  const [ligneDossiersListe, setLigneDossiersListe] = useState([]);

  const [dossier, setDossier] = useState('');
  const [lineType, setTypeLigne] = useState('');
  const [docType, setTypeDoc] = useState('');

  useEffect(() => {
    setDossiersListe(props.dossiersListe);
  }, [props.dossiersListe]);

  useEffect(() => {
    setLigneDossiersListe(props.ligneDossiersListe);
  }, [props.ligneDossiersListe]);

  useEffect(() => {
    setDossier(props.data.dossier ? props.data.dossier : '');
  }, [props.data.dossier]);

  useEffect(() => {
    setTypeLigne(props.data.typeLigne ? props.data.typeLigne : '');
  }, [props.data.typeLigne]);

  useEffect(() => {
    setTypeDoc(props.data.typeDoc ? props.data.typeDoc : '');
  }, [props.data.typeDoc]);

  const addHandler = () => {
    if (dossier !== '' && lineType !== '' && docType !== '') {
      dispatch(addLineGroup({ dossier, lineType, docType }));
    }
  };

  const validerModifClick = () => {
    if (dossier !== '' && lineType !== '' && docType !== '') {
      // @todo: Update to use Redux storage.
      fetchPut(
        `/regroupements/${props.data.dossier}/${props.data.typeLigne}/${props.data.typeDoc}`,
        { dossier, typeLigne: lineType, typeDoc: docType },
      )
        .then((res) => toggle())
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      role='dialog'
      autoFocus={true}
      centered={true}
      className='exampleModal'
      toggle={toggle}
    >
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>Ajouter une correspondance </ModalHeader>
        <ModalBody>
          <Form>
            <p>Saisir le triplet " Dossier - Type ligne - Documents/Unités " </p>
            <Row>
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <select
                    value={dossier}
                    className='form-select'
                    onChange={(event) => setDossier(event.target.value)}
                  >
                    <option value=''>Sélectionner le dossier</option>
                    {dossiersListe.map((value) => (
                      <option value={value.dossier}>{value.dossier}</option>
                    ))}
                  </select>
                  <label htmlFor='floatingSelectGrid'>Choisir le Dossier</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <select
                    value={lineType}
                    className='form-select'
                    onChange={(event) => setTypeLigne(event.target.value)}
                  >
                    <option value=''>Sélectionner la ligne dossier</option>
                    {ligneDossiersListe.map((value) => {
                      if (value.dossier === dossier) {
                        return <option value={value.typeLigne}>{value.typeLigne}</option>;
                      }
                    })}
                  </select>
                  <label htmlFor='floatingSelectGrid'>Choisir le Type de ligne</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <input
                    defaultValue=''
                    type='text'
                    className='form-control'
                    id='floatingLigneTypeInput'
                    placeholder='Saisir le Type de documents - unités'
                    onChange={(event) => setTypeDoc(event.target.value)}
                  />
                  <label htmlFor='floatingLigneTypeInput'>Saisir le Type de Document/Unité</label>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type='button' color='secondary' onClick={toggle}>
            Fermer
          </Button>
          <Button
            className='btn btn-primary'
            color='primary'
            onClick={() => {
              if (props.data && props.data !== '') {
                validerModifClick();
              } else {
                addHandler();
              }
              toggle();
            }}
          >
            Valider
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

CorrModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  dossiersListe: PropTypes.any,
  ligneDossiersListe: PropTypes.any,
  data: PropTypes.any,
};

export default CorrModal;
