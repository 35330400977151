import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';
import { func, PropTypes } from 'prop-types';
import { fetchGet } from '../../utility/apiFetch';
import { Link } from 'react-router-dom';
import { formateDate } from '../../utility';
import { useAuth0 } from '@auth0/auth0-react';

const groupBy = require('group-by-with-sum');

function EnCoursAFacture({ user, setTotalFunc, dateFin, dateDebut }) {
  const [ligneStandard, setligneStandard] = useState([]);
  const [lignesSpeciales, setlignesSpeciales] = useState([]);
  const nickname = useAuth0().user?.nickname ?? null;
  const adminList = ['dsamba', 'vpetre', 'arkplai', 'skilld', 'mbrethenoux', 'nboukerouni', 'camaghnouj'];

  // Anc. Version - avant adaptation tarifs
  // useEffect(() =>{
  //     fetchGet("/lignesprod/user/"+user.username+"/"+formateDate(dateDebut,"YYYY-MM-DD")+"/"+formateDate(dateFin,"YYYY-MM-DD"))
  //     .then((res) =>  {
  //             console.log(res)
  //             const fct = async () =>{
  //                 const results = Array();
  //                 for(let i = 0 ; i<res.length ;i++){
  //                     // const tarif = await fetchGet("/tarifs/typepays/"+res[i].typeLigne+"/"+(user.pays ? user.pays : "None")) // anc. formulation
  //                     const tarif = await fetchGet("/tarifs/typepays/"+res[i].typeLigne+"/"+"None") // Test
  //                     results.push(Object.assign(res[i],{tarifUnitaire:tarif.prix}))
  //                 }
  //                 console.log(results)
  //                 return results
  //             }
  //             fct().then(res => setligneStandard(res))
  //     })
  //     .catch((err) => console.log(err))
  // },[user,dateDebut,dateFin])

  // New Version - Avec adaptation tarifs
  useEffect(() => {
    fetchGet(
      '/lignesprod/userraw/' +
        user.username +
        '/' +
        formateDate(dateDebut, 'YYYY-MM-DD') +
        '/' +
        formateDate(dateFin, 'YYYY-MM-DD'),
    )
      .then((res) => {
        const fct = async () => {
          const intermediates = Array();
          // const results = Array();
          for (let i = 0; i < res.length; i++) {
            // const tarif = await fetchGet("/tarifs/typepays/"+res[i].typeLigne+"/"+(user.pays ? user.pays : "None")) // anc. formulation
            const tarif = await fetchGet(
              '/tarifs/quartet/' +
                res[i].dossier +
                '/' +
                res[i].typeLigne +
                '/' +
                user.pays +
                '/' +
                res[i].date,
            ); // Test
            intermediates.push(Object.assign(res[i], { tarifUnitaire: tarif.prix }));
          }

          const results = groupBy(intermediates, 'dossier,typeLigne,tarifUnitaire', 'nbUnite');
          return results;
        };
        fct().then((res) => setligneStandard(res));
      })
      .catch((err) => console.log(err));
  }, [user, dateDebut, dateFin]);

  useEffect(() => {
    fetchGet(
      '/lignesspec/user/nonfacture/' + user.username + '/' + formateDate(dateFin, 'YYYY-MM-DD'),
    )
      .then((res) => {
        const fct = async () => {
          const results = Array();
          for (let i = 0; i < res.length; i++) {
            // const tarif = await fetchGet("/tarifs/typepays/"+res[i].typeLigne+"/"+(user.pays ? user.pays : "None")) // anc. formulation
            const tarif = await fetchGet('/tarifs/typepays/' + res[i].typeLigne + '/' + 'None');
            results.push(Object.assign(res[i], { tarifUnitaire: tarif.prix }));
          }

          return results;
        };
        fct().then((res) => setlignesSpeciales(res));
      })
      .catch((err) => console.log(err));
  }, [user, dateFin]);

  const total = useMemo(() => {
    const somme =
      ligneStandard.reduce((s, data) => (s = s + data.nbUnite * data.tarifUnitaire), 0) +
      lignesSpeciales.reduce((s, data) => (s = s + data.quantite * data.tarifUnitaire), 0);
    return somme;
  }, [ligneStandard, lignesSpeciales]);
  useEffect(() => setTotalFunc(total), [total]);

  return adminList.includes(nickname) ? (
    // Affichage si Admin
    <React.Fragment>
      <Card>
        <CardBody>
          <div className='float-end me-1'>
            <Link
              to={{
                pathname: '/factures/ajouter',
                state: {
                  user,
                  ligneStandard,
                  lignesSpeciales,
                  dateDebut: formateDate(dateDebut, 'YYYY-MM-DD'),
                  dateFin: formateDate(dateFin, 'YYYY-MM-DD'),
                },
              }}
              className='btn btn-primary'
            >
              <i className='mdi mdi-plus-circle-outline me-1' />
              Créer la Facture
            </Link>
          </div>
          <CardTitle className='mb-4'>En cours à facturer</CardTitle>

          <div className='float-end ms-2'>
            <h5 className='font-size-16'>
              <i className='bx bx-wallet text-primary font-size-16 align-middle me-1'></i>
              {'  '}
              {total.toFixed(2)} €
            </h5>
          </div>
          <h5 className='font-size-14 mb-4'>Detail des lignes :</h5>
          <div className='table-responsive'>
            <Table className='table-nowrap mb-0'>
              <tbody>
                <tr>
                  <th></th>
                  <th>Dossier</th>
                  <th>Type de Ligne</th>
                  <th>Du</th>
                  <th>Au</th>
                  <th>Nbre d'UO</th>
                  <th>Tarif UO</th>
                  <th>Montant</th>
                </tr>
                {ligneStandard.map((line) => (
                  <tr>
                    <td>
                      <i className='far fa-star'></i>{' '}
                    </td>
                    <td>{line.dossier}</td>
                    <td>{line.typeLigne}</td>
                    <td>{formateDate(dateDebut, 'DD-MM-YY')}</td>
                    <td>{formateDate(dateFin, 'DD-MM-YY')}</td>
                    <td>{line.nbUnite}</td>
                    <td>{line.tarifUnitaire.toFixed(3)} €</td>
                    <td>{(line.nbUnite * line.tarifUnitaire).toFixed(2)} €</td>
                  </tr>
                ))}
                {lignesSpeciales.map((line) => (
                  <tr>
                    <td>
                      <i className='fas fa-star'></i>{' '}
                    </td>
                    <td>{line.dossier}</td>
                    <td>{line.typeLigne}</td>
                    <td>{formateDate(line.periodeDateDebut, 'DD-MM-YY')}</td>
                    <td>{formateDate(line.periodeDateFin, 'DD-MM-YY')}</td>
                    <td>{line.quantite}</td>
                    <td>{line.tarifUnitaire.toFixed(3)} €</td>
                    <td>{(line.quantite * line.tarifUnitaire).toFixed(2)} €</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  ) : (
    // Affichage si Contributeur - pas Admin
    <React.Fragment>
      <Card>
        <CardBody>
          <div className='float-end me-1'>
            {/* <Link
                        to={{
                            pathname:"/factures/ajouter",
                            state:{
                                user:user ,
                                ligneStandard: ligneStandard,
                                lignesSpeciales: lignesSpeciales,
                                dateDebut:formateDate(dateDebut,"YYYY-MM-DD"),
                                dateFin:formateDate(dateFin,"YYYY-MM-DD"),
                            }
                        }}
                        className="btn btn-primary"
                    >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Créer la Facture
                    </Link> */}
          </div>
          <CardTitle className='mb-4'>En cours à facturer</CardTitle>

          <div className='float-end ms-2'>
            <h5 className='font-size-14'>
              <i className='bx bx-wallet text-primary font-size-16 align-middle me-1'></i>{' '}
              {total.toFixed(2)} €
            </h5>
          </div>
          <h5 className='font-size-14 mb-4'>Detail des lignes :</h5>
          <div className='table-responsive'>
            <Table className='table-nowrap mb-0'>
              <tbody>
                <tr>
                  <th></th>
                  <th>Dossier</th>
                  <th>Type de Ligne</th>
                  <th>Du</th>
                  <th>Au</th>
                  <th>Nombre d'UO</th>
                  <th>Tarif unitaire</th>
                  <th>Montant</th>
                </tr>
                {ligneStandard.map((line) => (
                  <tr>
                    <td>
                      <i className='far fa-star'></i>{' '}
                    </td>
                    <td>{line.dossier}</td>
                    <td>{line.typeLigne}</td>
                    <td>{formateDate(dateDebut, 'DD-MM-YY')}</td>
                    <td>{formateDate(dateFin, 'DD-MM-YY')}</td>
                    <td>{line.nbUnite}</td>
                    <td>{line.tarifUnitaire.toFixed(3)} €</td>
                    <td>{(line.nbUnite * line.tarifUnitaire).toFixed(2)} €</td>
                  </tr>
                ))}
                {lignesSpeciales.map((line) => (
                  <tr>
                    <td>
                      <i className='fas fa-star'></i>{' '}
                    </td>
                    <td>{line.dossier}</td>
                    <td>{line.typeLigne}</td>
                    <td>{formateDate(line.periodeDateDebut, 'DD-MM-YY')}</td>
                    <td>{formateDate(line.periodeDateFin, 'DD-MM-YY')}</td>
                    <td>{line.quantite}</td>
                    <td>{line.tarifUnitaire} €</td>
                    <td>{(line.quantite * line.tarifUnitaire).toFixed(2)} €</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
EnCoursAFacture.propTypes = {
  user: PropTypes.any,
  setTotalFunc: func,
  dateFin: func,
};
export default EnCoursAFacture;
