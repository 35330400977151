import React, { useMemo, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Row, CardTitle, Form } from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumb';
import { fetchPost, fetchPut } from '../utility/apiFetch';

function ModifierOperateurPaiement(props) {
  const location = useLocation();
  const history = useHistory();
  const { operateurObject } = location.state;

  const [operateur, setOperateur] = useState('');

  const [typeOperateur, settypeOperateur] = useState('');

  useEffect(() => {
    const obj = new Object({
      nomOperateur: operateurObject.nomOperateur ? operateurObject.nomOperateur : '',
      typeOperateur: operateurObject.typeOperateur ? operateurObject.typeOperateur : '',
    });
    setOperateur(obj);
  }, [operateurObject]);

  const onModif = () => {
    const newOperateur = {
      typeOperateur: typeOperateur !== '' ? typeOperateur : operateurObject.typeOperateur,
    };
    fetchPut('/operateurs/' + operateurObject.nomOperateur, newOperateur)
      .then((res) => {
        history.push('/operateurs');
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Ajouter un Tarif' />
        <Card>
          <CardBody>
            <CardTitle className='h5 card-title-desc'>Formulaire d'ajout</CardTitle>

            <Form onSubmit={(e) => e.preventDefault()}>
              <Row className='mb-3'>
                <label className='col-md-2 col-form-label'>Nom Operateur</label>
                <div className='col-md-10'>
                  <input
                    className='form-control'
                    type='text'
                    defaultValue={operateur.nomOperateur}
                    readOnly
                  />
                </div>
              </Row>
              <Row className='mb-3'>
                <label className='col-md-2 col-form-label'>Type d'opérateur</label>
                <div className='col-md-10'>
                  <input
                    className='form-control'
                    type='text'
                    defaultValue={operateur.typeOperateur}
                    onChange={(e) => settypeOperateur(e.target.value)}
                  />
                </div>
              </Row>
              <Row>
                <div className='d-flex justify-content-end'>
                  <Link
                    to='/operateurs'
                    className='m-2'
                    // ref='annuler'
                  >
                    <button className='btn btn-secondary w-md'>Annuler</button>
                  </Link>
                  <button onClick={onModif} className='btn btn-primary w-md m-2'>
                    Modifier
                  </button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ModifierOperateurPaiement;
