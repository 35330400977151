import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumb';
import TableContainer from '../../components/TableContainer';
import ValidationPopup from '../../components/ValidationPopup';
import { getPaymentNetworks, deletePaymentNetwork } from '../../store/paymentNetworks/slice';
import { isoToFullName } from '../../utility';
import AddPaymentNetwork from './AddPaymentNetwork';
import UpdatePaymentNetwork from './UpdatePaymentNetwork';

function PaymentNetworks() {
  document.title = 'Réseau Paiement | Parametres';

  const dispatch = useDispatch();
  const paymentNetworks = useSelector((state) => state.paymentNetworks.items);

  const [validationOpen, setvalidationOpen] = useState(false);
  const [validationDeleteId, setvalidationDeleteId] = useState();
  const [validationText, setvalidationText] = useState('');

  const [refresh, setRefresh] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [paymentNetwork, setPaymentNetwork] = useState();

  const toggleValidation = () => setvalidationOpen(!validationOpen);
  const onDelete = () => dispatch(deletePaymentNetwork(validationDeleteId));

  useEffect(() => {
    dispatch(getPaymentNetworks());
  }, [refresh]);

  const columns = useMemo(
    () => [
      {
        Header: 'Type de réseau',
        accessor: 'type',
        disableFilters: true,
      },
      {
        Header: 'Nom du Réseau',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'Pays',
        accessor: 'country',
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? isoToFullName(cellProps.value) : '';
        },
      },
      {
        Header: 'Reference Etablissement',
        accessor: 'networkId',
        disableFilters: true,
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <div
                className='text-success'
                onClick={() => {
                  setPaymentNetwork(cellProps.row.original);
                  toggleEditModal();
                }}
              >
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Modifier
                </UncontrolledTooltip>
              </div>
              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationText(
                    `Etes vous sur de vouloir supprimer ${cellProps.row.original.name}`,
                  );
                  setvalidationDeleteId(cellProps.row.original.id);
                  toggleValidation();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  const toggleAddNewModal = () => setAddNewModal(!addNewModal);
  const toggleEditModal = () => setEditModal(!editModal);
  const toggleRefresh = () => setRefresh(!refresh);

  return (
    <div className='page-content'>
      <Container fluid>
        <Breadcrumbs title='Parametres' breadcrumbItem='Configuration Réseau Mobiles & Bancaires' />
        <Row>
          <Col xs='12'>
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={paymentNetworks}
                  isGlobalFilter={true}
                  isAddUserList={true}
                  handleUserClick={toggleAddNewModal}
                  addUserName='Ajouter Réseau'
                  customPageSize={10}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={addNewModal} toggle={toggleAddNewModal} size='xl'>
        <ModalHeader toggle={toggleAddNewModal} tag='h4'>
          Ajouter de Réseau
        </ModalHeader>
        <ModalBody>
          <AddPaymentNetwork
            onSubmit={() => {
              toggleAddNewModal();
              toggleRefresh();
            }}
            onCancel={() => toggleAddNewModal()}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={editModal} toggle={toggleEditModal} size='xl'>
        <ModalHeader toggle={toggleEditModal} tag='h4'>
          Modifier de Réseau
        </ModalHeader>
        <ModalBody>
          <UpdatePaymentNetwork
            paymentNetwork={paymentNetwork}
            onSubmit={() => {
              toggleEditModal();
              toggleRefresh();
            }}
            onCancel={() => toggleEditModal()}
          />
        </ModalBody>
      </Modal>

      <ValidationPopup
        isOpen={validationOpen}
        toogle={toggleValidation}
        fct={onDelete}
        text={validationText}
      />
    </div>
  );
}

export default PaymentNetworks;
