import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import Breadcrumbs from '../../../components/Breadcrumb';
import TableContainer from '../../../components/TableContainer';
import ValidationPopup from '../../../components/ValidationPopup';
import {
  deleteErrorMessageConfig,
  getErrorMessageConfigs,
} from '../../../store/errorMessageConfigs/slice';
import AddPaymentNetwork from '../../PaymentNetwork/AddPaymentNetwork';
import UpdatePaymentNetwork from '../../PaymentNetwork/UpdatePaymentNetwork';
import AddErrorMessageConfig from './AddErrorMessageConfig';
import UpdateErrorMessageConfig from './UpdateErrorMessageConfig';

function ErrorMessageConfigs() {
  const dispatch = useDispatch();
  const operators = useSelector((state) => state.errorMessageConfigs.items);

  const [validationOpen, setvalidationOpen] = useState(false);
  const [validationDeleteId, setvalidationDeleteId] = useState();
  const [validationText, setvalidationText] = useState('');

  const [refresh, setRefresh] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errorMessageConfig, setErrorMessageConfig] = useState();

  const toggleValidation = () => setvalidationOpen(!validationOpen);
  const onDelete = () => dispatch(deleteErrorMessageConfig(validationDeleteId));

  useEffect(() => {
    dispatch(getErrorMessageConfigs());
  }, [refresh]);

  const columns = useMemo(
    () => [
      {
        Header: 'Code erreur',
        accessor: 'code',
        disableFilters: true,
      },
      {
        Header: 'Message',
        accessor: 'message',
        disableFilters: true,
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <div
                className='text-success'
                onClick={() => {
                  setErrorMessageConfig(cellProps.row.original);
                  toggleEditModal();
                }}
              >
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Modifier
                </UncontrolledTooltip>
              </div>
              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationText(
                    `Etes vous sur de vouloir supprimer ${cellProps.row.original.service_id}`,
                  );
                  setvalidationDeleteId(cellProps.row.original.id);
                  toggleValidation();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  const toggleAddNewModal = () => setAddNewModal(!addNewModal);
  const toggleEditModal = () => setEditModal(!editModal);
  const toggleRefresh = () => setRefresh(!refresh);

  return (
    <>
      <Row>
        <Col xs='12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <h3 className='mb-0 font-size-14'>Configuration messages d’erreur API</h3>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs='12'>
          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={operators}
                isGlobalFilter={true}
                isAddUserList={true}
                handleUserClick={toggleAddNewModal}
                addUserName='Ajouter code message'
                customPageSize={10}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={addNewModal} toggle={toggleAddNewModal} size='xl'>
        <ModalHeader toggle={toggleAddNewModal} tag='h4'>
          Ajouter un message d’erreur API
        </ModalHeader>
        <ModalBody>
          <AddErrorMessageConfig
            onSubmit={() => {
              toggleAddNewModal();
              toggleRefresh();
            }}
            onCancel={() => toggleAddNewModal()}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={editModal} toggle={toggleEditModal} size='xl'>
        <ModalHeader toggle={toggleEditModal} tag='h4'>
          Modifier un message d’erreur API
        </ModalHeader>
        <ModalBody>
          <UpdateErrorMessageConfig
            errorMessageConfig={errorMessageConfig}
            onSubmit={() => {
              toggleEditModal();
              toggleRefresh();
            }}
            onCancel={() => toggleEditModal()}
          />
        </ModalBody>
      </Modal>

      <ValidationPopup
        isOpen={validationOpen}
        toogle={toggleValidation}
        fct={onDelete}
        text={validationText}
      />
    </>
  );
}

export default ErrorMessageConfigs;
