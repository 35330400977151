import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  getDossiers,
  getDossiersSuccess,
  deleteDossier,
  deleteDossierSuccess,
  addDossier,
  addDossierSuccess,
} from './slice';

function* getDossiersSaga() {
  const response = yield call(httpClient.instance.get, '/dossiers');
  if (response.status !== undefined && response.status === 200) {
    yield put(getDossiersSuccess(response.data));
  }
}

function* addDossierSaga(action) {
  const dossier = action.payload;
  const response = yield call(httpClient.instance.post, `/dossiers`, { dossier });
  if (response.status !== undefined && response.status === 200) {
    yield put(addDossierSuccess(response.data));
  }
}

function* deleteDossierSaga(action) {
  const dossierId = action.payload;
  const response = yield call(httpClient.instance.delete, `/dossiers/${dossierId}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteDossierSuccess(dossierId));
  }
}

export default function* dossiersSaga() {
  yield takeLatest(getDossiers, getDossiersSaga);
  yield takeLatest(addDossier, addDossierSaga);
  yield takeLatest(deleteDossier, deleteDossierSaga);
}
