import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  getPaymentNetworks,
  getPaymentNetworksSuccess,
  deletePaymentNetwork,
  deletePaymentNetworkSuccess,
} from './slice';

function* getPaymentNetworksSaga() {
  const response = yield call(httpClient.instance.get, '/payment-networks');
  if (response.status !== undefined && response.status === 200) {
    yield put(getPaymentNetworksSuccess(response.data));
  }
}

function* deletePaymentNetworkSaga(action) {
  const id = action.payload;
  const response = yield call(httpClient.instance.delete, `/payment-networks/${id}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deletePaymentNetworkSuccess(id));
  }
}

export default function* PaymentNetworksSaga() {
  yield takeLatest(getPaymentNetworks, getPaymentNetworksSaga);
  yield takeLatest(deletePaymentNetwork, deletePaymentNetworkSaga);
}
