import { createSlice } from '@reduxjs/toolkit';

const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getTariffs(state) {
      state.isLoading = true;
    },
    getTariffsSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    deleteTariff(state) {
      state.isLoading = true;
    },
    deleteTariffSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter((tariff) => tariff.id !== action.payload);
    },
  },
});

export const { getTariffs, getTariffsSuccess, deleteTariff, deleteTariffSuccess } =
  tariffsSlice.actions;
export default tariffsSlice;
