import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardSubtitle, CardTitle, Col, Form, Row } from 'reactstrap';
import axios from 'axios';

import Breadcrumbs from '../components/Breadcrumb';
import { fetchUpload } from '../utility/apiFetch';

function ImportStatProd(props) {
  document.title = 'Import stat de prod | Admin Lemissa';

  const [selectedFile, setselectedFile] = useState(null);

  function handleAcceptedFiles(files) {
    // files.map(file =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: formatBytes(file.size),
    //   })
    // )
    setselectedFile(files[0]);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const onSend = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    fetchUpload('/lignesprod/upload', formData)
      .then((response) => {
        console.log('succeess');
        setselectedFile(null);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Admin Lemissa' breadcrumbItem='Import stat de prod' />
        <Row>
          <Col className='col-12'>
            <Card>
              <CardBody>
                <CardTitle>Dropzone</CardTitle>
                <CardSubtitle className='mb-3'>
                  {' '}
                  Ajoutez le fichier CSV avec les nouvelles données.
                  <br />
                  Le fichier doit avoir des en-têtes (headers) et des données au format :
                  "nom,date,nb_actions,type_doc,dossier"
                </CardSubtitle>
                <Form>
                  <Dropzone
                    maxFiles={1}
                    accept={{
                      'text/csv': ['.csv'],
                    }}
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone'>
                        <div className='dz-message needsclick mt-2' {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className='mb-3'>
                            <i className='display-4 text-muted bx bxs-cloud-upload' />
                          </div>
                          <h4>Déposez le fichier ici ou cliquez pour Choisir.</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className='dropzone-previews mt-3' id='file-previews'>
                    {selectedFile ? (
                      <Card
                        className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                        key={0 + '-file'}
                      >
                        <div className='p-2'>
                          <Row className='align-items-center'>
                            <Col className='col-auto'>
                              <img
                                data-dz-thumbnail=''
                                height='80'
                                className='avatar-sm rounded bg-light'
                                alt={selectedFile.name}
                                src={selectedFile.preview}
                              />
                            </Col>
                            <Col>
                              <Link to='#' className='text-muted font-weight-bold'>
                                {selectedFile.name}
                              </Link>
                              <p className='mb-0'>
                                <strong>{selectedFile.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : (
                      <></>
                    )}
                  </div>
                </Form>

                <div className='text-center mt-4'>
                  <button type='button' className='btn btn-primary ' onClick={onSend}>
                    Send File
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ImportStatProd;
