import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { PropTypes } from 'prop-types';

import { fetchPut } from '../../utility/apiFetch';
import { addDossier } from '../../store/dossiers/slice';

function DossierModal(props) {
  const dispatch = useDispatch();

  const { isOpen, toggle } = props;
  const [dossier, setDossier] = useState('');

  useEffect(() => {
    setDossier(props.data.dossier);
  }, [props.data.dossier]);

  const addHandler = () => {
    if (dossier !== '') {
      dispatch(addDossier(dossier));
    }
  };

  const validerModifClick = () => {
    if (dossier !== '') {
      // @todo: Update to use Redux storage.
      fetchPut(`/dossiers/${props.data.dossier}`, { dossier })
        .then((res) => toggle())
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      role='dialog'
      autoFocus={true}
      centered={true}
      className='exampleModal'
      toggle={toggle}
    >
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>Ajouter un Dossier</ModalHeader>
        <ModalBody>
          <Form>
            <p>Ajouter un nom de dossier </p>
            <Row>
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    id='floatingDossierInput'
                    placeholder='Dossier'
                    defaultValue={props.data.dossier}
                    onChange={(event) => setDossier(event.target.value)}
                  />
                  <label htmlFor='floatingDossierInput'>Dossier</label>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type='button' color='secondary' onClick={toggle}>
            Fermer
          </Button>
          <Button
            className='btn btn-primary'
            color='primary'
            onClick={() => {
              if (props.data && props.data !== '') {
                validerModifClick();
              } else {
                addHandler();
              }

              toggle();
            }}
          >
            Valider
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

DossierModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.any,
};

export default DossierModal;
