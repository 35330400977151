import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row, CardTitle, Form } from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumb';
import { fetchPost } from '../utility/apiFetch';

function AddOperateurPaiement(props) {
  const history = useHistory();

  const [nomOperateur, setnomOperateur] = useState('');
  const [typeOperateur, settypeOperateur] = useState('');

  const onSend = () => {
    if (nomOperateur !== '' && typeOperateur !== '') {
      const operateur = {
        nomOperateur,
        typeOperateur,
      };
      fetchPost('/operateurs', operateur)
        .then((res) => {
          history.push('/operateurs');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Ajouter un Opérateur de paiement' />
        <Card>
          <CardBody>
            <CardTitle className='h5 card-title-desc'>Formulaire d'ajout</CardTitle>

            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingnomOperateurInput'
                      placeholder='Nom Operateur'
                      defaultValue=''
                      onChange={(event) => setnomOperateur(event.target.value)}
                    />
                    <label htmlFor='floatingnomOperateurInput'>Nom Operateur</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingtypeOperateurInput'
                      placeholder='Type Opêrateur'
                      defaultValue=''
                      onChange={(event) => settypeOperateur(event.target.value)}
                    />
                    <label htmlFor='floatingtypeOperateurInput'>Type de L'operateur</label>
                  </div>
                </Col>
              </Row>
              <div className='d-flex justify-content-end'>
                <Link
                  to='/operateurs'
                  className='m-2'
                  // ref='annuler'
                >
                  <button
                    onClick={(event) => this.refs.annuler.click()}
                    className='btn btn-secondary w-md'
                  >
                    Annuler
                  </button>
                </Link>
                <Link
                  to='#'
                  className='m-2'
                  // hidden={true}
                >
                  <button onClick={onSend} className='btn btn-primary w-md'>
                    Valider
                  </button>
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AddOperateurPaiement;
