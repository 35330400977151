import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';

import ContributorsSelect from '../components/Select/ContributorsSelect';
import Breadcrumbs from '../components/Breadcrumb';
import ProfileContent from '../components/Profile/ProfileContent';

import 'flatpickr/dist/themes/material_blue.css';
import { fetchGet } from '../utility/apiFetch';
import { previousDay, formateDate } from '../utility';
import { getContributorLastBillingDate } from "../utility/contributorFunc";
import DateFlatpickrInput from "../components/Input/DateFlatpickrInput";

function CompteContributor(props) {
  document.title = 'Compte Contributeur | Admin Lemissa';
  const dateFormat = 'YYYY-MM-DD';

  const selectedContributor = useSelector((state) => state.contributors.selected);

  const [dateDebut, setdateDebut] = useState('2021-01-01');
  const [dateFin, setdateFin] = useState(previousDay());
  const [lastUpdate, setlastUpdate] = useState('');
  const [lastBillingDate, setLastBillingDate] = useState('');

  const startDate = useMemo(() => '2021-01-01', []);

  useEffect(() => {
    fetchGet('/lignesprod/lastupdate')
      .then((res) => {
        setdateFin(formateDate(res, dateFormat))
        setlastUpdate(formateDate(res, dateFormat))
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (selectedContributor) {
      getContributorLastBillingDate(selectedContributor).then(date => {
        setLastBillingDate(date);
        setdateDebut(date);
      })
    }
  }, [selectedContributor]);

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Admin Lemissa' breadcrumbItem='Compte contributeur' />
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col xl={4}>
                  <ContributorsSelect title={'Choisir le Contributeur'}></ContributorsSelect>
                </Col>
                <DateFlatpickrInput
                  label='Date de Début'
                  placeholder='Choisir une Date de debut'
                  defaultValue={lastBillingDate < lastUpdate ? lastBillingDate : '' }
                  minDate={startDate}
                  maxDate={lastUpdate}
                  onChange={([date]) => {
                    setdateDebut(formateDate(date, dateFormat));
                  }}
                />
                <DateFlatpickrInput
                  label='Date de Fin'
                  placeholder='Choisir une date de fin'
                  defaultValue={lastUpdate}
                  minDate={startDate}
                  maxDate={lastUpdate}
                  onChange={([date]) => {
                    setdateFin(formateDate(date, dateFormat));
                  }}
                />
              </Row>
            </CardBody>
          </Card>
        </Row>
        {selectedContributor ? (
          <ProfileContent user={selectedContributor} dateDebut={dateDebut} dateFin={dateFin} />
        ) : (
          <div
            className='d-flex p-2 justify-content-center align-items-center'
            style={{ height: '400px' }}
          >
            <h1>Choisir un Contributeur</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default CompteContributor;
