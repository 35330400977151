import { useState } from 'react';
import { Card, CardBody, Row, Form, FormGroup, FormFeedback, Input } from 'reactstrap';
import { fetchPut } from '../../utility/apiFetch';
import AccountTypeSelect from '../../components/Select/AccountTypeSelect';
import { paymentAccountType } from '../../constants/payment';
import { allCountriesAlpha3 } from '../../utility';
import * as yup from 'yup';

function UpdatePaymentNetwork(props) {
  const [type, setType] = useState(props.paymentNetwork.type);
  const [name, setName] = useState(props.paymentNetwork.name);
  const [country, setCountry] = useState(props.paymentNetwork.country);
  const [networkId, setNetworkId] = useState(props.paymentNetwork.networkId);
  const [typeErrors, setTypeErrors] = useState([]);
  const [nameErrors, setNameErrors] = useState([]);

  yup.setLocale({
    mixed: {
      required: 'Ceci est un champ obligatoire',
    },
  });

  async function validateType(value) {
    try {
      const typeSchema = yup.string().required();
      await typeSchema.validate(value);
      setTypeErrors([]);
      return true;
    } catch (err) {
      setTypeErrors(err.errors);
    }
    return false;
  }
  async function validateName(value) {
    try {
      const nameSchema = yup.string().required();
      await nameSchema.validate(value);
      setNameErrors([]);
      return true;
    } catch (err) {
      setNameErrors(err.errors);
    }
    return false;
  }

  async function onUpdate() {
    if ((await validateType(type)) && (await validateName(name))) {
      const newPaymentNetwork = { type, name, networkId: null };
      if (type === paymentAccountType.BANK_ACCOUNT) {
        newPaymentNetwork.networkId = networkId;
      }
      fetchPut('/payment-networks/' + props.paymentNetwork.id, newPaymentNetwork)
        .then((res) => props.onSubmit())
        .catch((err) => console.log(err));
    }
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row className='mb-3'>
            <label className='col-md-2 col-form-label'>Type de réseau</label>
            <FormGroup className='col-md-10'>
              <AccountTypeSelect
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  validateType(e.target.value);
                }}
                invalid={typeErrors.length > 0 ? true : undefined}
              />
              {typeErrors.map((error) => (
                <FormFeedback type='invalid' key={error}>
                  {error}
                </FormFeedback>
              ))}
            </FormGroup>
          </Row>
          <Row className='mb-3'>
            <label className='col-md-2 col-form-label'>Nom du Réseau</label>
            <FormGroup className='col-md-10'>
              <Input
                className='form-control'
                type='text'
                defaultValue={name}
                onChange={(e) => {
                  setName(e.target.value);
                  validateName(e.target.value);
                }}
                invalid={nameErrors.length > 0 ? true : undefined}
              />
              {nameErrors.map((error) => (
                <FormFeedback type='invalid' key={error}>
                  {error}
                </FormFeedback>
              ))}
            </FormGroup>
          </Row>
          <Row className='mb-3'>
            <label className='col-md-2 col-form-label'>Pays</label>
            <div className='col-md-10'>
              <select
                id='payment-network-country'
                className='form-control'
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option key='_none' value=''>
                  - choisir -
                </option>
                {allCountriesAlpha3.map(({ value, name }) => (
                  <option key={value} value={value}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </Row>
          {type === paymentAccountType.BANK_ACCOUNT && (
            <Row className='mb-3'>
              <label className='col-md-2 col-form-label'>Reference Etablissement</label>
              <div className='col-md-10'>
                <input
                  className='form-control'
                  type='text'
                  defaultValue={networkId}
                  onChange={(e) => setNetworkId(e.target.value)}
                />
              </div>
            </Row>
          )}
          <Row>
            <div className='d-flex justify-content-end'>
              <button onClick={props.onCancel} className='btn btn-secondary w-md m-2'>
                Annuler
              </button>
              <button onClick={onUpdate} className='btn btn-primary w-md m-2'>
                Modifier
              </button>
            </div>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

export default UpdatePaymentNetwork;
