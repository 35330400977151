import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

function ReportCard({ title, value, iconClass }) {
  return (
    <Card className='mini-stats-wid'>
      <CardBody className='mb-0'>
        <div className='d-flex'>
          <div className='flex-grow-1'>
            <p className='text-muted fw-medium mb-4'>{title}</p>
            <h4 className='mb-1'>{value}</h4>
          </div>
          <div className='avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon'>
            <span className='avatar-title rounded-circle bg-primary'>
              <i className={'bx ' + iconClass + ' font-size-24'}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

ReportCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  iconClass: PropTypes.string,
};

export default ReportCard;
