import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Tooltip,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Redirect } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import PaymentsTable from './PaymentsTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePending, getInProgress,
  getPending,
  getToBeConfirmed,
  togglePaymentsModal,
  toggleRefresh
} from "../../store/payments/slice";
import Breadcrumbs from '../../components/Breadcrumb';
import { getOperators } from '../../store/operators/slice';
import PaymentsModal from './Modals/PaymentsModal';
import { allCountriesAlpha3 } from '../../utility';
import TotalAmount from './components/TotalAmount';
import TotalPayments from './components/TotalPayments';
import { PaymentEditForm } from '../ModifierFacture';
import DeleteModal from '../../components/Common/DeleteModal';

const ALLOWED_OPERATORS = ['Orange Money'];

function PaymentsCreate() {
  const dispatch = useDispatch();
  const toBeConfirmed = useSelector((state) => state.payments.toBeConfirmed);
  const isLoading = useSelector((state) => state.payments.isLoading);

  useEffect(() => {
    dispatch(getToBeConfirmed());
  }, []);

  if (!isLoading && toBeConfirmed.length > 0) {
    return <Redirect to='/payments/confirm' />;
  }
  return (
    <>
      <PaymentsCreateContent />
      <PaymentsModal />
    </>
  );
}

function PaymentsCreateContent() {
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.payments.pending);
  const operators = useSelector((state) => state.operators.items);
  const refresh = useSelector((state) => state.payments.refresh);
  const [operator, setOperator] = useState();
  const [modal, setModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getOperators());
  }, []);

  useEffect(() => {
    dispatch(getPending(operator));
  }, [operator, refresh]);

  const columns = useMemo(
    () => [
      {
        Header: 'Prénom NOM',
        accessor: 'last_first_name',
      },
      {
        Header: 'N° Compte',
        accessor: 'account_num',
      },
      {
        Header: 'Réseau',
        accessor: 'network',
      },
      {
        Header: 'Pays',
        accessor: 'country',
      },
      {
        Header: 'N° Facture',
        accessor: 'invoice_num',
      },
      {
        Header: 'Transféré - EUR',
        accessor: 'transfer_amount',
      },
      {
        Header: 'Frais Op. - EUR',
        accessor: 'expected_fees',
      },
      {
        Header: 'Montant - EUR',
        accessor: 'expected_total',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        Cell: (cellProps) => <div className='text-center'>{cellProps.value}</div>,
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag='a' className='card-drop'>
                <i className='mdi mdi-dots-horizontal font-size-18'></i>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <DropdownItem onClick={() => handlePaymentEdit(cellProps.row.original)}>
                  <i className='mdi mdi-pencil font-size-16 text-success me-1' id='edittooltip'></i>
                  Modifier
                  <UncontrolledTooltip placement='top' target='edittooltip'>
                    Modifier
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem onClick={() => onClickDelete(cellProps.row.original)}>
                  <i
                    className='mdi mdi-trash-can font-size-16 text-danger me-1'
                    id='deletetooltip'
                  ></i>
                  Supprimer
                  <UncontrolledTooltip placement='top' target='deletetooltip'>
                    Supprimer
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [],
  );

  const data = [];
  for (const p of payments) {
    const country =
      p.User && p.User.pays
        ? allCountriesAlpha3.find((country) => country.value === p.User.pays)
        : null;

    data.push({
      id: p.id,
      username: p.username,
      last_first_name: getUserName(p.User),
      account_num: p.CompteMonetaire ? p.CompteMonetaire.numero_compte : null,
      country: country ? country.name : null,
      network: p.nomOperateur,
      invoice_num: p.factureCorrespondante,
      transfer_amount: p.montantTransfere,
      expected_fees: p.fraisTransfert,
      expected_total: p.montantRetire,
      comment: p.commentaire ? <Comment id={p.id} text={p.commentaire} /> : null,
      allowPayment: ALLOWED_OPERATORS.includes(p.nomOperateur),
    });
  }

  function getUserName(user) {
    const parts = [];
    if (user && user.prenom) {
      parts.push(user.prenom);
    }
    if (user && user.nom) {
      parts.push(user.nom);
    }
    return parts.join(' ');
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setPaymentData(null);
    } else {
      setModal(true);
    }
  };

  const handlePaymentEdit = (arg) => {
    setPaymentData(arg);
    toggle();
  };

  const onClickDelete = (data) => {
    setPaymentData(data);
    setDeleteModal(true);
  };

  const handleDeletePayment = () => {
    if (paymentData && paymentData.id) {
      dispatch(deletePending(paymentData.id));
      setDeleteModal(false);
    }
  };

  const buttons = (
    <button
      type='button'
      className='btn btn-rounded btn-primary mb-2 me-2'
      onClick={() => dispatch(togglePaymentsModal('create'))}
    >
      <i className='mdi mdi-plus-circle-outline me-1'></i>
      Créer les paiements
    </button>
  );

  return (
    <div className='page-content'>
      <Container fluid>
        <Breadcrumbs
          title='Admin Lemissa'
          breadcrumbItem='Paiements – Etape 1 : Sélection des factures à régler'
        />
        <Row>
          <Col lg={4}>
            <Card className='mini-stats-wid'>
              <CardBody>
                <div className='d-flex'>
                  <div className='flex-grow-1'>
                    <p className='text-muted fw-medium'>Sélectionner l’opérateur</p>
                    <select
                      className='form-control'
                      onChange={(e) => setOperator(e.target.value)}
                      value={operator}
                    >
                      <option value=''>- Tout -</option>
                      {operators.map((operator) => (
                        <option key={operator.nomOperateur} value={operator.nomOperateur}>
                          {operator.nomOperateur}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <TotalAmount />
          </Col>
          <Col lg={4}>
            <TotalPayments />
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <Card>
              <CardBody>
                <PaymentsTable
                  columns={columns}
                  data={data}
                  buttons={buttons}
                  // className="custom-header-css"
                />

                <Modal isOpen={modal} toggle={toggle} size='xl'>
                  <ModalHeader toggle={toggle} tag='h4'>
                    Modifier Facture
                  </ModalHeader>
                  <ModalBody>
                    {paymentData && (
                      <PaymentEditForm
                        paymentId={paymentData.id}
                        username={paymentData.username}
                        onSubmit={() => {
                          toggle();
                          dispatch(toggleRefresh());
                        }}
                        onCancel={() => toggle()}
                      />
                    )}
                  </ModalBody>
                </Modal>

                <DeleteModal
                  text='Êtes-vous sûr de vouloir effacer définitivement le paiement ?'
                  show={deleteModal}
                  onDeleteClick={handleDeletePayment}
                  onCloseClick={() => setDeleteModal(false)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function Comment({ id, text }) {
  const [tooltip, setTooltip] = useState(false);
  const tooltipId = 'payment-comment-tooltip-' + id;
  return (
    <>
      <Tooltip
        placement='top'
        isOpen={tooltip}
        target={tooltipId}
        toggle={() => setTooltip(!tooltip)}
      >
        {text.split('\n').map(str => <div>{str}</div>)}
      </Tooltip>
      <i id={tooltipId} className='bx bxs-comment-detail font-size-20'></i>
    </>
  );
}

export default PaymentsCreate;
