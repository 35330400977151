import { createSlice } from '@reduxjs/toolkit';

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    isLoading: false,
    refresh: false,
    items: [],
    pending: [],
    toBeConfirmed: [],
    inProgress: [],
    selected: [],
    modal: {
      isOpen: false,
      action: null,
      context: null,
    },
  },
  reducers: {
    search(state) {
      state.isLoading = true;
    },
    searchSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    getPending(state) {
      state.isLoading = true;
    },
    getPendingSuccess(state, action) {
      state.isLoading = false;
      state.pending = action.payload;
    },
    deletePending(state) {
      state.isLoading = true;
    },
    deletePendingSuccess(state, action) {
      state.isLoading = false;
      state.pending = state.pending.filter((payment) => payment.id !== action.payload);
    },
    getToBeConfirmed(state) {
      state.isLoading = true;
    },
    getToBeConfirmedSuccess(state, action) {
      state.isLoading = false;
      state.toBeConfirmed = action.payload;
    },
    getInProgress(state) {
      state.isLoading = true;
    },
    getInProgressSuccess(state, action) {
      state.isLoading = false;
      state.inProgress = action.payload;
    },
    addSelected(state, action) {
      state.selected = action.payload;
    },
    initiate(state) {
      state.isLoading = true;
    },
    initiateSuccess(state, action) {
      state.isLoading = false;
      state.refresh = !state.refresh;
      state.toBeConfirmed = action.payload;
      state.modal.isOpen = false;
    },
    confirm(state) {
      state.isLoading = true;
    },
    confirmSuccess(state, { payload }) {
      state.isLoading = false;
      state.refresh = !state.refresh;
      state.toBeConfirmed = payload.payments;
      state.modal.isOpen = true;
      state.modal.action = 'result';
      state.modal.context = payload.summary;
    },
    refresh(state) {
      state.isLoading = true;
    },
    refreshSuccess(state, { payload }) {
      state.isLoading = false;
      state.refresh = !state.refresh;
      state.inProgress = payload.payments;
      state.modal.isOpen = true;
      state.modal.action = "result";
      state.modal.context = payload.summary;
    },
    cancel(state) {
      state.isLoading = true;
    },
    cancelSuccess(state, action) {
      state.isLoading = false;
      state.toBeConfirmed = action.payload.toBeConfirmed;
      state.inProgress = action.payload.inProgress;
      state.modal.isOpen = false;
      state.selected = [];
    },
    toggleRefresh(state, action) {
      state.refresh = !state.refresh;
    },
    // Modal
    togglePaymentsModal(state, action) {
      state.modal.isOpen = !state.modal.isOpen;
      state.modal.action = action.payload;
    },
  },
});

export const {
  search,
  searchSuccess,
  getPending,
  getPendingSuccess,
  deletePending,
  deletePendingSuccess,
  getToBeConfirmed,
  getToBeConfirmedSuccess,
  getInProgress,
  getInProgressSuccess,
  addSelected,
  initiate,
  initiateSuccess,
  confirm,
  confirmSuccess,
  refresh,
  refreshSuccess,
  cancel,
  cancelSuccess,
  toggleRefresh,
  togglePaymentsModal,
} = paymentsSlice.actions;
export default paymentsSlice;
