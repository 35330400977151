import { CardTitle, Col } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { French } from 'flatpickr/dist/l10n/fr.js';

function DateFlatpickrInput(props) {
  return (
    <Col xl={4}>
      <CardTitle className='mb-3 text-muted'>{props.label}</CardTitle>
      <Flatpickr
        className='form-control d-block'
        placeholder={props.placeholder}
        value={props.defaultValue}
        options={{
          altInput: true,
          altFormat: 'j F Y',
          dateFormat: 'Y-m-d',
          minDate: props.minDate,
          maxDate: props.maxDate,
          locale: French,
        }}
        onChange={props.onChange}
      />
    </Col>
  )
}

export default DateFlatpickrInput;
