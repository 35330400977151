import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { PropTypes } from 'prop-types';
import { fetchPost, fetchPut } from '../../utility/apiFetch';
import { paymentAccountType } from '../../constants/payment';
import PaymentNetworksSelect from '../../components/Select/PaymentNetworksSelect';
import httpClient from '../../utility/httpClient';

function CompteMonetaireModal(props) {
  const { isOpen, toggle } = props;

  const [username, setusername] = useState('');
  const [typeCompte, settypeCompte] = useState('');
  const [paymentNetworkId, setPaymentNetworkId] = useState('');
  const [numeroCompte, setnumeroCompte] = useState('');
  const [networkCode, setNetworkCode] = useState('');

  useEffect(() => setusername(props.username), [props.username]);
  useEffect(
    () => settypeCompte(props.data.typeCompte ? props.data.typeCompte : ''),
    [props.data.typeCompte],
  );
  useEffect(
    () => setPaymentNetworkId(props.data.paymentNetworkId ? props.data.paymentNetworkId : ''),
    [props.data.paymentNetworkId],
  );
  useEffect(
    () => setnumeroCompte(props.data.numeroCompte ? props.data.numeroCompte : ''),
    [props.data.numeroCompte],
  );
  useEffect(() => {
    if (paymentNetworkId) {
      httpClient.instance
        .get(`/payment-networks/${paymentNetworkId}`)
        .then((res) => setNetworkCode(res.data.networkId));
    } else {
      setNetworkCode(null);
    }
  }, [paymentNetworkId]);

  const validerAddClick = () => {
    if (username !== '' && typeCompte !== '' && paymentNetworkId && numeroCompte !== '') {
      fetchPost('/comptes/', {
        username,
        typeCompte,
        paymentNetworkId,
        numeroCompte,
      })
        .then((res) => {
          toggle();
        })
        .catch((err) => console.log(err));
    }
  };

  const validerModifClick = () => {
    if (username !== '' && typeCompte !== '' && paymentNetworkId && numeroCompte !== '') {
      fetchPut('/comptes/' + props.data.id, {
        username,
        typeCompte,
        paymentNetworkId,
        numeroCompte,
      })
        .then((res) => {
          toggle();
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      role='dialog'
      autoFocus={true}
      centered={true}
      className='exampleModal'
      toggle={toggle}
    >
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>Ajouter un Compte</ModalHeader>
        <ModalBody>
          <Form>
            <p>Ajouter un compte monetaire </p>
            <Row>
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <input
                    type='text'
                    readOnly
                    className='form-control'
                    id='floatingUsernameInput'
                    placeholder='Username'
                    value={props.username}
                  />
                  <label htmlFor='floatingUsernameInput'>Username</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <select
                    className='form-select'
                    value={typeCompte}
                    onChange={(event) => settypeCompte(event.target.value)}
                  >
                    <option key='_none' value=''>
                      - сhoisir le Type de compte -
                    </option>
                    {[paymentAccountType.BANK_ACCOUNT, paymentAccountType.MOBILE_MONEY].map(
                      (value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ),
                    )}
                  </select>
                  <label htmlFor='floatingSelectGrid'>Choisir le Dossier</label>
                </div>
              </Col>
            </Row>
            {typeCompte && (
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <PaymentNetworksSelect
                    networkType={typeCompte}
                    value={paymentNetworkId}
                    onChange={(e) => setPaymentNetworkId(e.target.value)}
                  />
                  <label htmlFor='floatingRefEtablissementInput'>Réseau Paiement</label>
                </div>
              </Col>
            )}
            <Row>
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    id='floatingNumeroCompteInput'
                    placeholder='Numero Compte'
                    defaultValue={props.data.numeroCompte}
                    onChange={(event) => setnumeroCompte(event.target.value)}
                  />
                  <label htmlFor='floatingNumeroCompteInput'>Numero Compte</label>
                </div>
              </Col>
            </Row>
            {typeCompte === paymentAccountType.BANK_ACCOUNT && networkCode && (
              <Row>
                <Col md={10}>
                  <div className='form-floating mb-3'>
                    <input
                      disabled={true}
                      readOnly={true}
                      type='text'
                      className='form-control'
                      id='payment-network-id'
                      placeholder='Reference Etablissement'
                      value={networkCode}
                    />
                    <label htmlFor='payment-network-id'>Reference Etablissement</label>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <input
                    disabled={true}
                    type='text'
                    className='form-control'
                    id='floatingEtablissementInput'
                    placeholder='Etablissement'
                    defaultValue={props.data.etablissement}
                  />
                  <label htmlFor='floatingEtablissementInput'>Etablissement [DEPRECATED]</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <div className='form-floating mb-3'>
                  <input
                    disabled={true}
                    type='text'
                    className='form-control'
                    id='floatingRefEtablissementInput'
                    placeholder='Reference Etablissement'
                    defaultValue={props.data.referenceEtablissement}
                  />
                  <label htmlFor='floatingRefEtablissementInput'>
                    Reference Etablissement [DEPRECATED]
                  </label>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type='button' color='secondary' onClick={toggle}>
            Fermer
          </Button>
          <Button
            className='btn btn-primary'
            color='primary'
            onClick={() => {
              if (props.data && props.data !== '') {
                validerModifClick();
              } else {
                validerAddClick();
              }
            }}
          >
            Valider
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

CompteMonetaireModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.any,
  username: PropTypes.string,
};

export default CompteMonetaireModal;
