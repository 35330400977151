import React, { useMemo } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { PropTypes } from 'prop-types';

function ComptesMonetairesModal({ isOpen, title, toggle, comptesMonetaires }) {
  const tableColumns = useMemo(
    () => [
      'Type Compte ',
      'Numero Compte ',
      'Nom établissement',
      'Réference établissement ',
      'Compte par Defaut',
    ],
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      role='dialog'
      autoFocus={true}
      centered={true}
      className='exampleModal'
      toggle={toggle}
      size='xl'
    >
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <div className='table-responsive'>
            <Table className='table-nowrap mb-0 font-size-16'>
              <tbody>
                <tr>
                  {tableColumns.map((value) => (
                    <th>{value}</th>
                  ))}
                </tr>

                {comptesMonetaires.map((value) => (
                  <tr>
                    <td>{value.typeCompte}</td>
                    <td>{value.numeroCompte}</td>
                    <td>{value.etablissement}</td>
                    <td>{value.referenceEtablissement ? value.referenceEtablissement : ''}</td>
                    <td>{value.isDefaut ? 'Oui' : 'Non'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type='button' color='secondary' onClick={toggle}>
            Fermer
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

ComptesMonetairesModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  comptesMonetaires: PropTypes.any,
};
export default ComptesMonetairesModal;
