import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import { Badge, Card, CardBody, UncontrolledTooltip } from 'reactstrap';

import TableContainer from '../../components/TableContainer';

import { fetchDelete, fetchGet, fetchPut } from '../../utility/apiFetch';
import CompteMonetaireModal from './CompteMonetaireModal';
import ValidationPopup from '../../components/ValidationPopup';

const CompteMonetaireList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setdata] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [comptes, setComptes] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [validationOpen, setvalidationOpen] = useState(false);
  const [validationDeleteId, setvalidationDeleteId] = useState();
  const [validationText, setvalidationText] = useState('');
  const toogleValidation = () => setvalidationOpen(!validationOpen);

  const toggleShowModal = () => {
    setShowModal(!showModal);
    setRefresh((value) => !value);
    setRefresh('refresh is ' + refresh);
  };

  const onAddClick = () => {
    setdata('');
    toggleShowModal();
  };

  const onEditClick = (cellProps) => {
    setdata(cellProps.row.original);
    toggleShowModal();
  };

  const onSetDefaultClick = (cellProps) => {
    fetchPut('/comptes/setdefaut/' + cellProps.row.original.id)
      .then((res) => setRefresh((value) => !value))
      .catch((err) => console.log(err));
  };

  const onDeleteClick = (cellProps) => {
    fetchDelete('/comptes/' + cellProps.row.original.id)
      .then((res) => setRefresh((value) => !value))
      .catch((err) => console.log(err));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Type de compte',
        accessor: 'typeCompte',
        filterable: true,
        disableFilters: true,
        isEdit: true,
      },
      {
        Header: 'Réseau Paiement',
        accessor: 'PaymentNetwork.name',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Reference etablissement',
        accessor: 'PaymentNetwork.network_id',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Numero de compte',
        accessor: 'numeroCompte',
        filterable: true,
        disableFilters: true,
      },
      {
        Header: 'Reference par defaut',
        accessor: 'isDefault',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={'font-size-14 badge-soft-' + (cellProps.value ? 'success' : 'danger')}
              pill
            >
              {cellProps.value ? 'Oui' : 'Non'}
            </Badge>
          );
        },
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <Link className='text-success' onClick={() => onEditClick(cellProps)}>
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Editer
                </UncontrolledTooltip>
              </Link>
              <Link className='text-info' onClick={() => onSetDefaultClick(cellProps)}>
                <i className='mdi mdi-adjust font-size-18' id='setdefault' />
                <UncontrolledTooltip placement='top' target='setdefault'>
                  Définir par defaut
                </UncontrolledTooltip>
              </Link>
              <Link
                className='text-danger'
                onClick={() => {
                  setvalidationText(
                    'Etes vous sur de vouloir supprimer le compte ' +
                      cellProps.row.original.numeroCompte +
                      ' pour ' +
                      cellProps.row.original.username,
                  );
                  setvalidationDeleteId(cellProps);
                  toogleValidation();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    fetchGet('/comptes/user/' + props.username)
      .then((res) => setComptes(res))
      .catch((err) => console.log(err));
  }, [props.username, refresh]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className='mb-4 h4 card-title'>Liste des comptes monetaires</div>
          <TableContainer
            columns={columns}
            data={comptes}
            isGlobalFilter={true}
            isAddOptions={false}
            isAddUserList={true}
            handleUserClick={onAddClick}
            addUserName='Ajouter un compte monetaire'
            customPageSize={5}
          />
        </CardBody>
      </Card>
      <CompteMonetaireModal
        isOpen={showModal}
        toggle={toggleShowModal}
        data={data}
        username={props.username}
      />
      <ValidationPopup
        isOpen={validationOpen}
        toogle={toogleValidation}
        fct={() => onDeleteClick(validationDeleteId)}
        text={validationText}
      />
    </React.Fragment>
  );
};

CompteMonetaireList.propTypes = {
  username: PropTypes.string,
};

export default withRouter(CompteMonetaireList);
