import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { CardTitle } from 'reactstrap';
import PropTypes from 'prop-types';
import { getContributors, setSelectedContributor } from '../../store/contributors/slice';

function ContributorsSelect(props) {
  const dispatch = useDispatch();
  const contributors = useSelector((state) => state.contributors.items);
  const selectedContributor = useSelector((state) => state.contributors.selected);

  useEffect(() => {
    dispatch(getContributors());
    return () => dispatch(setSelectedContributor(null));
  }, []);

  const selectOptions = useMemo(
    () => [
      {
        label: 'Contributeurs',
        options: contributors.map((row) => ({
          label: `${row.username} - ${row.prenom} ${row.nom}`,
          value: row,
        })),
      },
    ],
    [contributors],
  );
  const contributorSelectValue = useMemo(
    () =>
      selectOptions[0].options.filter(
        (option) => selectedContributor !== null && option.value.id === selectedContributor.id,
      ),
    [selectedContributor],
  );
  const contributorSelectOnChange = (event) => dispatch(setSelectedContributor(event.value));

  return (
    <>
      <CardTitle className='mb-3 text-muted'>{props.title}</CardTitle>
      <Select
        value={contributorSelectValue}
        onChange={contributorSelectOnChange}
        options={selectOptions}
        classNamePrefix='select2-selection'
      />
    </>
  );
}

ContributorsSelect.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ContributorsSelect;
