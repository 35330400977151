import { useState } from 'react';
import { Card, CardBody, Row, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { fetchPut } from '../../../utility/apiFetch';
import * as yup from 'yup';

function UpdateErrorMessageConfig(props) {
  const [code, setCode] = useState(props.errorMessageConfig.code);
  const [message, setMessage] = useState(props.errorMessageConfig.message);
  const [codeErrors, setCodeErrors] = useState([]);
  const [messageErrors, setMessageErrors] = useState([]);

  yup.setLocale({
    mixed: {
      required: 'Ceci est un champ obligatoire',
    },
  });

  async function validateCode(value) {
    try {
      await yup.string().required().validate(value);
      setCodeErrors([]);
      return true;
    } catch (err) {
      setCodeErrors(err.errors);
    }
    return false;
  }
  async function validateMessage(value) {
    try {
      await yup.string().required().validate(value);
      setMessageErrors([]);
      return true;
    } catch (err) {
      setMessageErrors(err.errors);
    }
    return false;
  }

  async function onUpdate() {
    if ((await validateCode(code)) && (await validateMessage(message))) {
      const newErrorMessageConfig = { code, message };
      fetchPut('/error-message-configs/' + props.errorMessageConfig.id, newErrorMessageConfig)
        .then((res) => props.onSubmit())
        .catch((err) => console.log(err));
    }
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row className='mb-3'>
            <label className='col-md-2 col-form-label'>Code erreur</label>
            <FormGroup className='col-md-10'>
              <Input
                type='number'
                className='form-control'
                id='error-message-config-code'
                placeholder='Code erreur'
                defaultValue={code}
                onChange={(e) => {
                  setCode(e.target.value);
                  validateCode(e.target.value);
                }}
                invalid={codeErrors.length > 0 ? true : undefined}
              />
              {codeErrors.map((error) => (
                <FormFeedback type='invalid' key={error}>
                  {error}
                </FormFeedback>
              ))}
            </FormGroup>
          </Row>
          <Row className='mb-3'>
            <label className='col-md-2 col-form-label'>Message</label>
            <FormGroup className='col-md-10'>
              <Input
                type='text'
                className='form-control'
                id='error-message-config-message'
                placeholder='Message'
                defaultValue={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  validateMessage(e.target.value);
                }}
                invalid={messageErrors.length > 0 ? true : undefined}
              />
              {messageErrors.map((error) => (
                <FormFeedback type='invalid' key={error}>
                  {error}
                </FormFeedback>
              ))}
            </FormGroup>
          </Row>
          <Row>
            <div className='d-flex justify-content-end'>
              <button onClick={props.onCancel} className='btn btn-secondary w-md m-2'>
                Annuler
              </button>
              <button onClick={onUpdate} className='btn btn-primary w-md m-2'>
                Modifier
              </button>
            </div>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

export default UpdateErrorMessageConfig;
