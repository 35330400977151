import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { PropTypes } from 'prop-types';
const ApexRevenue = (props) => {
  const [axisX, setAxisX] = useState([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]);
  const [axisFacture, setAxisFacture] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [axisEnCours, setAxisEnCours] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    setAxisX(props.x);
  }, [props.x]);

  useEffect(() => {
    setAxisFacture(props.yFacture);
  }, [props.yFacture]);

  useEffect(() => {
    setAxisEnCours(props.yEnCours);
  }, [props.yEnCours]);

  const options = {
    chart: {
      height: 300,
      type: 'bar',
      toolbar: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: '25%', // initialement 14%
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      colors: ['transparent'],
    },
    series: [
      {
        name: 'Revenue',
        data: [42, 85, 101, 56, 37, 105, 38, 58, 92, 82, 72, 32],
      },
    ],
    xaxis: {
      categories: axisX,
    },
    yaxis: {
      title: {
        text: '(EUR)',
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
      offsetY: 0,
    },
    fill: {
      opacity: 1,
    },
    colors: ['#556ee6', '#FF0000'],
  };

  const series = [
    {
      name: 'En cours à facturer',
      data: axisEnCours,
    },
    {
      name: 'Revenu facturé',
      data: axisFacture,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type='bar'
      height='320'
      className='apex-charts'
    />
  );
};

ApexRevenue.propTypes = {
  x: PropTypes.any,
  yFacture: PropTypes.any,
  yEnCours: PropTypes.any,
};

export default ApexRevenue;
