import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumb';
import TableContainer from '../components/TableContainer';
import ValidationPopup from '../components/ValidationPopup';

import { deleteOperator, getOperators } from '../store/operators/slice';

function OperateurPaiement() {
  const history = useHistory();
  const dispatch = useDispatch();
  const operators = useSelector((state) => state.operators.items);

  const [validationOpen, setvalidationOpen] = useState(false);
  const [validationDeleteId, setvalidationDeleteId] = useState();
  const [validationText, setvalidationText] = useState('');

  const toggleValidation = () => setvalidationOpen(!validationOpen);
  const addHandler = () => history.push('/operateurs/ajouter');
  const onDelete = () => dispatch(deleteOperator(validationDeleteId));

  useEffect(() => {
    dispatch(getOperators());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Nom Operateur',
        accessor: 'nomOperateur',
        disableFilters: true,
      },
      {
        Header: "Type d'operateur",
        accessor: 'typeOperateur',
        disableFilters: true,
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <Link
                to={{
                  pathname: '/operateurs/modifier',
                  state: { operateurObject: cellProps.row.original },
                }}
                className='text-success'
              >
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Modifier
                </UncontrolledTooltip>
              </Link>
              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationText(
                    `Etes vous sur de vouloir supprimer ${cellProps.row.original.nomOperateur}`,
                  );
                  setvalidationDeleteId(cellProps.row.original.nomOperateur);
                  toggleValidation();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  // meta title
  document.title = 'Table des Tarifs | Parametres';

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Table des Operateurs' />
        <TableContainer
          columns={columns}
          data={operators}
          isGlobalFilter={true}
          isAddUserList={true}
          handleUserClick={addHandler}
          addUserName='ajouter un operateur de paiement'
          customPageSize={10}
          className='custom-header-css'
        />
      </div>
      <ValidationPopup
        isOpen={validationOpen}
        toogle={toggleValidation}
        fct={onDelete}
        text={validationText}
      />
    </div>
  );
}

export default OperateurPaiement;
