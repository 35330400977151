import React, { useMemo, useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row, CardTitle, Form } from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumb';
import { allCountriesAlpha3 } from '../utility';
import { fetchGet, fetchPost } from '../utility/apiFetch';

function AddTarif(props) {
  const history = useHistory();

  const [dossier, setDossier] = useState('');
  const [typeLigne, settypeLigne] = useState('');
  const [pays, setpays] = useState('');
  const [prix, setprix] = useState('');
  const [validiteDateDebut, setvaliditeDateDebut] = useState('');
  const [validiteDateFin, setvaliditeDateFin] = useState('');
  const [commentaire, setcommentaire] = useState('');

  const [dossiersListe, setDossiersListe] = useState([]);
  const [ligneTypes, setligneTypes] = useState([]);

  useEffect(() => settypeLigne(''), [dossier]);

  useEffect(() => {
    fetchGet('/dossiers')
      .then((res) => setDossiersListe(res))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    fetchGet('/typelignes')
      .then((res) => setligneTypes(res))
      .catch((err) => console.log(err));
  }, []);

  const onSend = () => {
    if (
      dossier !== '' &&
      typeLigne !== '' &&
      prix !== '' &&
      validiteDateDebut !== '' &&
      validiteDateFin !== ''
    ) {
      const tarif = {
        dossier,
        typeLigne,
        pays,
        prix,
        validiteDateDebut,
        validiteDateFin,
        commentaire,
      };
      fetchPost('/tarifs', tarif)
        .then((res) => {
          history.push('/tarif');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Ajouter un Tarif' />
        <Card>
          <CardBody>
            <CardTitle className='h5 card-title-desc'>Formulaire d'ajout</CardTitle>

            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <select
                      defaultValue=''
                      className='form-select'
                      onChange={(event) => setDossier(event.target.value)}
                    >
                      <option value=''>Open this select menu</option>
                      {dossiersListe.map(({ dossier }) => (
                        <option value={dossier}>{dossier}</option>
                      ))}
                    </select>
                    <label htmlFor='floatingSelectGrid'>Choisir le Dossier</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <select
                      defaultValue=''
                      className='form-select'
                      onChange={(event) => settypeLigne(event.target.value)}
                    >
                      <option value=''>Open this select menu</option>
                      {ligneTypes.map((ligne) => {
                        if (ligne.dossier === dossier)
                          return <option value={ligne.typeLigne}>{ligne.typeLigne}</option>;
                      })}
                    </select>
                    <label htmlFor='floatingSelectGrid'>Choisir le type de ligne</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <select
                      defaultValue=''
                      className='form-select'
                      onChange={(event) => setpays(event.target.value)}
                    >
                      <option value=''>Tous les Pays</option>
                      {allCountriesAlpha3.map(({ value, name }) => (
                        <option value={value}>{name}</option>
                      ))}
                    </select>
                    <label htmlFor='floatingSelectGrid'>Choisir le Pays</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingprixInput'
                      placeholder='Ville'
                      defaultValue=''
                      onChange={(event) => setprix(event.target.value)}
                    />
                    <label htmlFor='floatingprixInput'>prix</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='date'
                      className='form-control'
                      id='floatingdebutInput'
                      placeholder='Pays'
                      defaultValue=''
                      onChange={(event) => setvaliditeDateDebut(event.target.value)}
                    />
                    <label htmlFor='floatingdebutInput'>Date debut de validité</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='date'
                      className='form-control'
                      id='floatingfinInput'
                      placeholder='Ville'
                      defaultValue=''
                      onChange={(event) => setvaliditeDateFin(event.target.value)}
                    />
                    <label htmlFor='floatingfinInput'>Date Fin de validité</label>
                  </div>
                </Col>
              </Row>

              <div className='form-floating mb-3'>
                <input
                  type='text'
                  className='form-control'
                  id='floatingcommentaireInput'
                  placeholder='Commentaire'
                  defaultValue=''
                  onChange={(event) => setcommentaire(event.target.value)}
                />
                <label htmlFor='floatingcommentaireInput'>Commentaire</label>
              </div>

              <div className='d-flex justify-content-end'>
                <Link
                  to='/tarif'
                  className='m-2'
                  // ref='annuler'
                >
                  <button
                    onClick={(event) => this.refs.annuler.click()}
                    className='btn btn-secondary w-md'
                  >
                    Annuler
                  </button>
                </Link>
                <Link
                  to='#'
                  className='m-2'
                  // hidden={true}
                >
                  <button onClick={onSend} className='btn btn-primary w-md'>
                    Valider
                  </button>
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AddTarif;
