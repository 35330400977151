import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { apiError, loginSuccess, logoutUserSuccess } from './actions';

function* loginUser({ payload: { user, history } }) {
  try {
    const response = {
      user,
      email: 'oguermazi@lemissa.com',
    };
    localStorage.setItem('authUser', JSON.stringify(response));
    yield put(loginSuccess(response));
    history.push('/');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser');
    yield put(logoutUserSuccess(history));
    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
