import { createSlice } from '@reduxjs/toolkit';

const contributorsSlice = createSlice({
  name: 'contributors',
  initialState: { isLoading: false, items: [], selected: null },
  reducers: {
    getContributors(state) {
      state.isLoading = true;
    },
    getContributorsSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    deleteContributor(state) {
      state.isLoading = true;
    },
    deleteContributorSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter((contributor) => contributor.id !== action.payload);
    },
    setSelectedContributor(state, action) {
      state.selected = action.payload;
    },
  },
});

export const {
  getContributors,
  getContributorsSuccess,
  deleteContributor,
  deleteContributorSuccess,
  setSelectedContributor,
} = contributorsSlice.actions;
export default contributorsSlice;
