import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInProgress } from "../../store/payments/slice";
import { Redirect } from 'react-router-dom';
import PaymentsModal from './Modals/PaymentsModal';
import { IN_PROGRESS } from "../../constants/paymentStatus";
import PaymentsInitializedContent from "./components/PaymentsInitializedContent";

function PaymentsInProgress() {
  const dispatch = useDispatch();
  const inProgress = useSelector((state) => state.payments.inProgress);
  const refresh = useSelector((state) => state.payments.refresh);

  useEffect(() => {
    dispatch(getInProgress());
  }, [refresh]);

  return (
    <>
      <PaymentsInitializedContent
        title="Paiements en cours"
        payments={inProgress}
        status={IN_PROGRESS}
      />
      <PaymentsModal />
    </>
  );
}

export default PaymentsInProgress;
