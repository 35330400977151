import React from 'react';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { PropTypes } from 'prop-types';

function ValidationPopup({ toogle, isOpen, text, fct }) {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        role='dialog'
        autoFocus={true}
        centered
        data-toggle='modal'
        toggle={toogle}
      >
        <div>
          <ModalHeader className='border-bottom-0' toggle={toogle}></ModalHeader>
        </div>
        <div className='modal-body'>
          <div className='text-center mb-4'>
            <div className='avatar-md mx-auto mb-4'>
              <div className='avatar-title bg-light rounded-circle text-primary h1'>
                <i className='mdi mdi-alert-outline'></i>
              </div>
            </div>

            <div className='row justify-content-center'>
              <div className='col-xl-10'>
                <h4 className='text-primary'>Veuillez Confirmer !</h4>
                <p className='text-muted font-size-16 mb-4'>{text}</p>

                <div className='container'>
                  <Button
                    className='mx-3'
                    onClick={toogle}
                    color='secondary'
                    type='button'
                    id='button-annuler'
                  >
                    Annuler
                  </Button>
                  <Button
                    onClick={() => {
                      fct();
                      toogle();
                    }}
                    color='primary'
                    type='button'
                    id='button-confirm'
                  >
                    Valider
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

ValidationPopup.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  fct: PropTypes.func,
};
export default ValidationPopup;
