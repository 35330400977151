import { Modal, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { togglePaymentsModal } from '../../../store/payments/slice';
import { useEffect } from 'react';

function BaseModal({ icon, iconClassName, title, children, cancelBtn, submitBtn, backdrop }) {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.payments.modal.isOpen);
  const isLoading = useSelector((state) => state.payments.isLoading);

  const toggle = () => dispatch(togglePaymentsModal());

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (submitBtn.show) {
          submitBtn.onSubmit();
        } else if (cancelBtn.show) {
          toggle();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [submitBtn.onSubmit]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={backdrop} id='payments-modal' centered>
      <div className='modal-header'>
        <i className={`mdi mdi-${icon} font-size-20 align-middle me-2 ${iconClassName}`}></i>
        <h5 className='modal-title' id='payments-modal-label'>
          {title}
        </h5>
        <button type='button' className='btn-close' onClick={toggle} aria-label='Close'></button>
      </div>
      <div className='modal-body'>{children}</div>
      {(cancelBtn.show || submitBtn.show) && (
        <div className='modal-footer'>
          {cancelBtn.show && (
            <button type='button' className={`btn btn-${cancelBtn.style}`} onClick={toggle}>
              {cancelBtn.label}
            </button>
          )}
          {submitBtn.show && (
            <>
              <button
                disabled={isLoading}
                type='button'
                className={`btn btn-${submitBtn.style}`}
                onClick={submitBtn.onSubmit}
              >
                {submitBtn.label}
              </button>
              {isLoading && <Spinner className='ms-1' color='success' />}
            </>
          )}
        </div>
      )}
    </Modal>
  );
}

BaseModal.defaultProps = {
  backdrop: true,
  submitBtn: {
    show: false,
    label: 'Envoyer',
    style: 'success',
    onSubmit: () => {},
  },
  cancelBtn: {
    show: true,
    label: 'Retour',
    style: 'light',
  },
};

BaseModal.propTypes = {
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  title: PropTypes.string,
  submitBtn: PropTypes.shape({
    show: PropTypes.bool,
    label: PropTypes.string,
    style: PropTypes.string,
    onSubmit: PropTypes.fun,
  }),
  cancelBtn: PropTypes.shape({
    show: PropTypes.bool,
    label: PropTypes.string,
    style: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default BaseModal;
