import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row, CardTitle, Form } from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumb';
import { allCountriesAlpha3, formateDate } from '../utility';
import { fetchPost } from '../utility/apiFetch';

function AddContributor(props) {
  const history = useHistory();

  const [username, setusername] = useState('');
  const [prenom, setprenom] = useState('');
  const [nom, setnom] = useState('');
  const [mailPerso, setmailPerso] = useState('');
  const [mailLemissa, setmailLemissa] = useState('');
  const [mobile, setmobile] = useState('');
  const [ville, setville] = useState('');
  const [pays, setpays] = useState('');
  const [adresse, setadresse] = useState('');
  const [dateEntreeProd, setdateEntreeProd] = useState(new Date());
  const [commentaire, setcommentaire] = useState('');

  const onSend = () => {
    if (
      username !== '' &&
      prenom !== '' &&
      nom !== '' &&
      mailPerso !== '' &&
      mailLemissa !== '' &&
      mobile !== '' &&
      ville !== '' &&
      pays !== '' &&
      adresse !== ''
    ) {
      const contributeur = {
        username,
        prenom,
        nom,
        mailPerso,
        mailLemissa,
        mobile,
        ville,
        pays,
        adresse,
        dateEntreeProd,
        commentaire,
      };
      fetchPost('/users', contributeur)
        .then((res) => {
          history.push('/contributeur');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Ajouter un contributeur' />
        <Card>
          <CardBody>
            <CardTitle className='h5 card-title-desc'>Formulaire d'ajout</CardTitle>

            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatinguserIDInput'
                      placeholder='user Id'
                      defaultValue=''
                      onChange={(event) => setusername(event.target.value)}
                    />
                    <label htmlFor='floatinguserIDInput'>username</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingPrenomInput'
                      placeholder='Prenom'
                      defaultValue=''
                      onChange={(event) => setprenom(event.target.value)}
                    />
                    <label htmlFor='floatingPrenomInput'>Prenom</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingNomInput'
                      placeholder='Nom'
                      defaultValue=''
                      onChange={(event) => setnom(event.target.value)}
                    />
                    <label htmlFor='floatingNomInput'>Nom</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='email'
                      className='form-control'
                      id='floatingemailpersoInput'
                      placeholder='mail personnel '
                      defaultValue=''
                      onChange={(event) => setmailPerso(event.target.value)}
                    />
                    <label htmlFor='floatingemailpersoInput'>Email personnel</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='email'
                      className='form-control'
                      id='floatingemaillemissaInput'
                      placeholder='mail lemissa'
                      defaultValue=''
                      onChange={(event) => setmailLemissa(event.target.value)}
                    />
                    <label htmlFor='floatingemaillemissaInput'>Email lemissa</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingvilleInput'
                      placeholder='Ville'
                      defaultValue=''
                      onChange={(event) => setville(event.target.value)}
                    />
                    <label htmlFor='floatingvilleInput'>Ville</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <select
                      className='form-select'
                      onChange={(event) => setpays(event.target.value)}
                    >
                      <option value=''></option>
                      {allCountriesAlpha3.map(({ value, name }) => (
                        <option value={value}>{name}</option>
                      ))}
                    </select>
                    <label htmlFor='floatingSelectGrid'>Choisir le Pays</label>
                  </div>
                </Col>
              </Row>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  className='form-control'
                  id='floatingmobileInput'
                  placeholder='Mobile'
                  defaultValue=''
                  onChange={(event) => setmobile(event.target.value)}
                />
                <label htmlFor='floatingmobileInput'>Mobile</label>
              </div>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  className='form-control'
                  id='floatingadresseInput'
                  placeholder='Adresse'
                  defaultValue=''
                  onChange={(event) => setadresse(event.target.value)}
                />
                <label htmlFor='floatingadresseInput'>Adresse</label>
              </div>

              <div className='form-floating mb-3'>
                <input
                  type='date'
                  className='form-control'
                  defaultValue={formateDate(new Date(dateEntreeProd), 'YYYY-MM-DD')}
                  id='floatingdateDemandeInput'
                  placeholder='Date de Demande'
                  onChange={(event) => setdateEntreeProd(event.target.value)}
                />
                <label htmlFor='floatingdateDemandeInput'>Date d'entrée en production</label>
              </div>

              <div className='form-floating mb-3'>
                <input
                  type='text'
                  className='form-control'
                  id='floatingcommentaireInput'
                  placeholder='Commentaire'
                  defaultValue=''
                  onChange={(event) => setcommentaire(event.target.value)}
                />
                <label htmlFor='floatingcommentaireInput'>Commentaire</label>
              </div>

              <div className='d-flex justify-content-end'>
                <Link
                  to='/contributeur'
                  className='m-2'
                  // ref='annuler'
                >
                  <button
                    onClick={(event) => this.refs.annuler.click()}
                    className='btn btn-secondary w-md'
                  >
                    Annuler
                  </button>
                </Link>
                <Link
                  to='#'
                  className='m-2'
                  // hidden={true}
                >
                  <button onClick={onSend} className='btn btn-primary w-md'>
                    Valider
                  </button>
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AddContributor;
