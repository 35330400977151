import { createSlice } from '@reduxjs/toolkit';

const productionLinesSlice = createSlice({
  name: 'productionLines',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getProductionLines(state) {
      state.isLoading = true;
    },
    getProductionLinesSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
  },
});

export const { getProductionLines, getProductionLinesSuccess } = productionLinesSlice.actions;
export default productionLinesSlice;
