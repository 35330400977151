import { createSlice } from '@reduxjs/toolkit';

const lineTypesSlice = createSlice({
  name: 'lineTypes',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getLineTypes(state) {
      state.isLoading = true;
    },
    getLineTypesSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    addLineType(state) {
      state.isLoading = true;
    },
    addLineTypeSuccess(state, action) {
      state.isLoading = false;
      state.items.push(action.payload);
    },
    deleteLineType(state) {
      state.isLoading = true;
    },
    deleteLineTypeSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter(
        (lineType) =>
          !(
            lineType.typeLigne === action.payload.lineType &&
            lineType.dossier === action.payload.dossier
          ),
      );
    },
  },
});

export const {
  getLineTypes,
  getLineTypesSuccess,
  addLineType,
  addLineTypeSuccess,
  deleteLineType,
  deleteLineTypeSuccess,
} = lineTypesSlice.actions;
export default lineTypesSlice;
