import { createSlice } from '@reduxjs/toolkit';

const operatorsSlice = createSlice({
  name: 'operators',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getOperators(state) {
      state.isLoading = true;
    },
    getOperatorsSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    deleteOperator(state) {
      state.isLoading = true;
    },
    deleteOperatorSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter((operator) => operator.nomOperateur !== action.payload);
    },
  },
});

export const { getOperators, getOperatorsSuccess, deleteOperator, deleteOperatorSuccess } =
  operatorsSlice.actions;
export default operatorsSlice;
