import { useEffect, useMemo, useState } from 'react';
import Breadcrumbs from '../components/Breadcrumb';
import TableContainer from '../components/TableContainer';
import httpClient from '../utility/httpClient';
import { formateDate } from '../utility';
import { Card, CardBody, Col, Row } from 'reactstrap';

function TransactionErrorLogs() {
  document.title = 'Erreurs de transactions | Journaux';
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    httpClient.instance.get('/transaction-errors').then((res) => setLogs(res.data));
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'createdAt',
        disableFilters: true,
        Cell: (cellProps) => {
          return formateDate(cellProps.value, 'DD/MM/YYYY - HH:mm:ss');
        },
      },
      {
        Header: 'Username',
        accessor: 'Paiement.username',
        disableFilters: true,
      },
      {
        Header: 'Numéro de Facture',
        accessor: 'Paiement.factureCorrespondante',
        disableFilters: true,
      },
      {
        Header: 'Montant - EUR',
        accessor: 'Paiement.montantRetire',
        disableFilters: true,
      },
      {
        Header: 'N° transfert',
        accessor: 'Paiement.referencePaiement',
        disableFilters: true,
      },
      {
        Header: 'Code',
        accessor: 'code',
        disableFilters: true,
      },
      {
        Header: 'Message',
        accessor: 'message',
        disableFilters: true,
      },
    ],
    [],
  );

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Erreurs de transactions' />
        <Row>
          <Col xs='12'>
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={logs}
                  isGlobalFilter={true}
                  customPageSize={10}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TransactionErrorLogs;
