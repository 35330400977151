import { createSlice } from '@reduxjs/toolkit';

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getInvoices(state) {
      state.isLoading = true;
    },
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    deleteInvoice(state) {
      state.isLoading = true;
    },
    deleteInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter((invoice) => invoice.id !== action.payload);
    },
  },
});

export const { getInvoices, getInvoicesSuccess, deleteInvoice, deleteInvoiceSuccess } =
  invoicesSlice.actions;
export default invoicesSlice;
