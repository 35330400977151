import { useState } from "react";
import { FormFeedback, Input } from "reactstrap";
import PropTypes from "prop-types";

function PasswordInput(props) {
  const [show, setShow] = useState(false);
  return (
    <div className="mb-3">
      {props.label &&
        <label htmlFor={props.id}>{props.label}</label>
      }
      <div className="input-group auth-pass-inputgroup">
        <Input
          type={show ? "text" : "password"}
          className="form-control"
          invalid={props.errors.length > 0 ? true : undefined}
          {...props}
        />
        <button onClick={() => setShow(!show)} className="btn btn-light " type="button" id="password-addon">
          <i className={`mdi ${show ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }`}></i></button>
        {props.errors.map((error) => (
          <FormFeedback type='invalid' key={error}>
            {error}
          </FormFeedback>
        ))}
      </div>
    </div>
  )
}

PasswordInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.array,
};

export default PasswordInput;
