import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Row, CardTitle } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumb';
import CompteMonetaireList from './CompteMonetaireList';

import { allCountriesAlpha3, formateDate } from '../../utility';
import { fetchPut } from '../../utility/apiFetch';
import { profileIdType } from '../../constants/profileIdType';

function ModifContributor() {
  const location = useLocation();
  const history = useHistory();
  const { contributorObject } = location.state;

  const [prenom, setprenom] = useState(contributorObject.prenom);
  const [nom, setnom] = useState(contributorObject.nom);
  const [mailPerso, setmailPerso] = useState(contributorObject.mailPerso);
  const [mailLemissa, setmailLemissa] = useState(contributorObject.mailLemissa);
  const [mobile, setmobile] = useState(contributorObject.mobile);
  const [ville, setville] = useState(contributorObject.ville);
  const [pays, setpays] = useState(contributorObject.pays);
  const [adresse, setadresse] = useState(contributorObject.adresse);
  const [dateEntreeProd, setdateEntreeProd] = useState(contributorObject.dateEntreeProd);
  const [commentaire, setcommentaire] = useState(contributorObject.commentaire);
  const [birthdate, setBirthdate] = useState(contributorObject.birthdate);
  const [idCountry, setIdCountry] = useState(contributorObject.idCountry);
  const [idType, setIdType] = useState(contributorObject.idType);
  const [idNumber, setIdNumber] = useState(contributorObject.idNumber);

  const onModif = () => {
    const data = {
      username: contributorObject.username,
      nom,
      prenom,
      mailPerso,
      mailLemissa,
      mobile,
      ville,
      pays,
      adresse,
      dateEntreeProd,
      commentaire,
      birthdate,
      idCountry,
      idType,
      idNumber,
    };
    fetchPut('/users/' + contributorObject.id, data)
      .then((res) => {
        history.push('/contributeur');
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Parametres' breadcrumbItem='Detail contributeur' />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className='h4 mb-4'>Info Contributeur</CardTitle>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>ID du contributeur</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='text'
                      defaultValue={contributorObject.username}
                      readOnly
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Prenom</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='text'
                      defaultValue={prenom}
                      onChange={(e) => setprenom(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Nom</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='text'
                      defaultValue={nom}
                      onChange={(e) => setnom(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Birthdate</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='date'
                      defaultValue={
                        birthdate ? formateDate(new Date(birthdate), 'YYYY-MM-DD') : null
                      }
                      onChange={(e) => setBirthdate(e.target.value ? e.target.value : null)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Email personnel</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='email'
                      defaultValue={mailPerso}
                      onChange={(e) => setmailPerso(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Email lemissa</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='email'
                      defaultValue={mailLemissa}
                      onChange={(e) => setmailLemissa(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Mobile</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='tel'
                      defaultValue={mobile}
                      onChange={(e) => setmobile(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Ville</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='text'
                      defaultValue={ville}
                      onChange={(e) => setville(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Pays</label>
                  <div className='col-md-10'>
                    <select
                      defaultValue={pays}
                      className='form-select'
                      onChange={(e) => setpays(e.target.value)}
                    >
                      <option key='_none' value=''>
                        - choisir -
                      </option>
                      {allCountriesAlpha3.map(({ value, name }) => (
                        <option key={value} value={value}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>adresse</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='text'
                      defaultValue={adresse}
                      onChange={(e) => setadresse(e.target.value)}
                    />
                  </div>
                </Row>

                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>Date d'entrée en production</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='date'
                      defaultValue={
                        dateEntreeProd ? formateDate(new Date(dateEntreeProd), 'YYYY-MM-DD') : null
                      }
                      onChange={(e) => setdateEntreeProd(e.target.value ? e.target.value : null)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>ID Country</label>
                  <div className='col-md-10'>
                    <select
                      defaultValue={idCountry}
                      className='form-select'
                      onChange={(e) => setIdCountry(e.target.value)}
                    >
                      <option key='_none' value=''>
                        - choisir -
                      </option>
                      {allCountriesAlpha3.map(({ value, name }) => (
                        <option key={value} value={value}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>ID Type</label>
                  <div className='col-md-10'>
                    <select
                      defaultValue={idType}
                      className='form-select'
                      onChange={(e) => setIdType(e.target.value)}
                    >
                      <option key='_none' value=''>
                        - choisir -
                      </option>
                      <option value={profileIdType.PASSPORT}>Passport</option>
                      <option value={profileIdType.IDENTITY}>CNI / IDENTITY</option>
                    </select>
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label className='col-md-2 col-form-label'>ID Number</label>
                  <div className='col-md-10'>
                    <input
                      className='form-control'
                      type='text'
                      defaultValue={idNumber}
                      onChange={(e) => setIdNumber(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className='mb-3'>
                  <label htmlFor='example-search-input' className='col-md-2 col-form-label'>
                    Commentaire
                  </label>
                  <div className='col-md-10'>
                    <textarea
                      className='form-control'
                      rows='4'
                      placeholder='Ajouter un commentaire'
                      defaultValue={commentaire}
                      onChange={(e) => setcommentaire(e.target.value)}
                    />
                  </div>
                </Row>
                <div className='d-flex justify-content-end'>
                  <Link
                    to='/contributeur'
                    className='mx-2'
                    // ref='annuler'
                  >
                    <button className='btn btn-secondary w-md'>Annuler</button>
                  </Link>
                  <button onClick={onModif} className='btn btn-primary w-md'>
                    Modifier
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <CompteMonetaireList username={contributorObject.username} />
        </Row>
      </div>
    </div>
  );
}

export default ModifContributor;
