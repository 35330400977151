import { Redirect } from 'react-router-dom';
import Contributors from '../pages/Contributors';
import Tarif from '../pages/Tarif';
import AddContributor from '../pages/AddContributor';
import ModifierTarif from '../pages/ModifierTarif';
import ModifContributor from '../pages/modif_contributeur/ModifContributor';
import AddTarif from '../pages/AddTarif';
import LignesSpeciales from '../pages/LignesSpeciales';
import AddLigneSpeciale from '../pages/AddLigneSpeciale';
import ModifierLigneSpeciale from '../pages/ModifierLigneSpeciale';
import CompteContributor from '../pages/CompteContributor';
import CompteLemissa from '../pages/CompteLemissa';
import DetailFacture from '../pages/DetailFacture/DetailFacture';
import Factures from '../pages/Factures';
import ModifierFacture from '../pages/ModifierFacture';
import AddFacture from '../pages/AddFacture';
import ImportStatProd from '../pages/ImportStatProd';
import FicheContributeur from '../pages/FicheContributeur';
import DocLine from '../pages/Doc&Type&Ligne/DocLine';
import OperateurPaiement from '../pages/OperateurPaiement';
import AddOperateurPaiement from '../pages/AddOperateurPaiement';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import ModifierOperateurPaiement from '../pages/ModifierOperateurPaiement';
import ProfileTest from '../pages/ProfileTest';
import Productions from '../pages/Productions';
import ProductionsUser from '../pages/ProductionsUser';
import Payments from '../pages/Payments/Payments';
import PaymentsCreate from '../pages/Payments/PaymentsCreate';
import PaymentsConfirm from '../pages/Payments/PaymentsConfirm';
import PaymentNetworks from '../pages/PaymentNetwork/PaymentNetworks';
import ApiParameters from '../pages/ApiParameters/ApiParameters';
import TransactionErrorLogs from '../pages/TransactionErrorLogs';
import PaymentsInProgress from "../pages/Payments/PaymentsInProgress";

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/profil', component: ProfileTest }, // test
];

const authProtectedRoutes = [
  { path: '/comptecontributor', component: CompteContributor },
  { path: '/comptelemissa', component: CompteLemissa },

  { path: '/fichecontributeur', component: FicheContributeur },

  { path: '/contributeur', component: Contributors },
  { path: '/contributeur/modifier', component: ModifContributor },
  { path: '/contributeur/ajouter', component: AddContributor },

  { path: '/tarif', component: Tarif },
  { path: '/tarif/modifier', component: ModifierTarif },
  { path: '/tarif/ajouter', component: AddTarif },

  { path: '/dossiersetlignes', component: DocLine },
  { path: '/lignespeciale', component: LignesSpeciales },
  { path: '/lignespeciale/ajouter', component: AddLigneSpeciale },
  { path: '/lignespeciale/modifier', component: ModifierLigneSpeciale },

  { path: '/productions', component: Productions },
  { path: '/productions/user', component: ProductionsUser },

  { path: '/factures', component: Factures },
  { path: '/consulterfacture', component: DetailFacture },
  { path: '/factures/ajouter', component: AddFacture },
  { path: '/factures/modifier', component: ModifierFacture },

  { path: '/importstatdeprod', component: ImportStatProd },

  { path: '/operateurs', component: OperateurPaiement },
  { path: '/operateurs/ajouter', component: AddOperateurPaiement },
  { path: '/operateurs/modifier', component: ModifierOperateurPaiement },

  { path: '/payment-networks', component: PaymentNetworks },

  { path: '/parameters-api', component: ApiParameters },

  { path: '/payments', component: Payments },
  { path: '/payments/create', component: PaymentsCreate },
  { path: '/payments/confirm', component: PaymentsConfirm },
  { path: '/payments-in-progress', component: PaymentsInProgress },

  { path: '/logs/transaction-errors', component: TransactionErrorLogs },

  { path: '/', exact: true, component: () => <Redirect to='/comptelemissa' /> },
];

export { publicRoutes, authProtectedRoutes };
