import { useSelector } from 'react-redux';
import { Card, CardText, CardTitle, Col, Row } from 'reactstrap';
import ReportCard from './ReportCard';

function TotalPayments() {
  const totalPayments = useSelector((state) => state.payments.selected).length;
  return <ReportCard title='Nombre de paiements' value={totalPayments} iconClass='bx-list-ol' />;
}

export default TotalPayments;
