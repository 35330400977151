import { fetchGet } from "./apiFetch";
import { formateDate } from "./index";

const getContributorLastBillingDate = (contributor) => {
  return fetchGet('/payments/user/lastpaiement/' + contributor.username)
    .then((res) => {
      if (res.periodeFactureFin) {
        const date = new Date(res.periodeFactureFin);
        date.setDate(date.getDate() + 1);
        return  formateDate(date, 'YYYY-MM-DD');
      } else {
        return formateDate(contributor.dateEntreeProd, 'YYYY-MM-DD');
      }
    })
    .catch((error) => console.log(error));
};

export {
  getContributorLastBillingDate,
};
