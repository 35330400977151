import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// i18n
// import { withTranslation } from "react-i18next";
import SidebarContent from './SidebarContent';

import logo from '../../assets/images/embleme-blanc-lemissa.png';
import logoBlanc from '../../assets/images/lemissa-logo-long-sans-blanc.svg';

const Sidebar = (props) => {
  return (
    <React.Fragment>
      <div className='vertical-menu'>
        <div className='navbar-brand-box'>
          <Link to='/' className='logo logo-light'>
            <span className='logo-sm'>
              <img src={logo} alt='' height='22' />
            </span>
            <span className='logo-lg'>
              <img src={logoBlanc} alt='' height='50' />
            </span>
          </Link>
        </div>
        <div data-simplebar className='h-100'>
          {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className='sidebar-background'></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
