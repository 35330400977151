import { createSlice } from '@reduxjs/toolkit';

const transferServicesSlice = createSlice({
  name: 'transferServices',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getTransferServices(state) {
      state.isLoading = true;
    },
    getTransferServicesSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    deleteTransferService(state) {
      state.isLoading = true;
    },
    deleteTransferServiceSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter((paymentNetwork) => paymentNetwork.id !== action.payload);
    },
  },
});

export const {
  getTransferServices,
  getTransferServicesSuccess,
  deleteTransferService,
  deleteTransferServiceSuccess,
} = transferServicesSlice.actions;
export default transferServicesSlice;
