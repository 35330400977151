import { createSlice } from '@reduxjs/toolkit';

const errorMessageConfigsSlice = createSlice({
  name: 'errorMessageConfigs',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getErrorMessageConfigs(state) {
      state.isLoading = true;
    },
    getErrorMessageConfigsSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    deleteErrorMessageConfig(state) {
      state.isLoading = true;
    },
    deleteErrorMessageConfigSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter((paymentNetwork) => paymentNetwork.id !== action.payload);
    },
  },
});

export const {
  getErrorMessageConfigs,
  getErrorMessageConfigsSuccess,
  deleteErrorMessageConfig,
  deleteErrorMessageConfigSuccess,
} = errorMessageConfigsSlice.actions;
export default errorMessageConfigsSlice;
