import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import { formateDate } from '../utility';
import { getInvoices, deleteInvoice } from '../store/invoices/slice';

import Breadcrumbs from '../components/Breadcrumb';
import TableContainer from '../components/TableContainer';
import ValidationPopup from '../components/ValidationPopup';

function Factures(props) {
  document.title = 'Table des Factures | Admin Lemissa';

  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.invoices.items);
  const [validationOpen, setvalidationOpen] = useState(false);
  const [validationDeleteId, setvalidationDeleteId] = useState();
  const [validationText, setvalidationText] = useState('');
  const toogleValidation = () => setvalidationOpen(!validationOpen);

  useEffect(() => {
    dispatch(getInvoices());
  }, []);

  const onDelete = () => dispatch(deleteInvoice(validationDeleteId));

  const columns = useMemo(
    () => [
      {
        Header: 'Créée le',
        accessor: 'createdAt',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Statut',
        accessor: 'status',
      },
      {
        Header: 'Opérateur',
        accessor: 'nomOperateur',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Numéro de Facture',
        accessor: 'factureCorrespondante',
      },
      {
        Header: 'Date de demande',
        accessor: 'dateDemande',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Du',
        accessor: 'periodeFactureDebut',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Au',
        accessor: 'periodeFactureFin',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Montant retiré - EUR',
        accessor: 'montantRetire',
        Cell: (cellProps) => {
          return cellProps.value.toFixed(2) + ' €';
        },
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <Link
                to={{
                  pathname: '/factures/modifier',
                  state: {
                    paiementId: cellProps.row.original.id,
                    userId: cellProps.row.original.username,
                  },
                }}
                className='text-success'
              >
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Modifier
                </UncontrolledTooltip>
              </Link>
              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationText(
                    'Etes vous sur de vouloir supprimer la facture n° ' +
                      cellProps.row.original.factureCorrespondante +
                      ' du ' +
                      formateDate(cellProps.row.original.dateDemande) +
                      ' pour ' +
                      cellProps.row.original.username +
                      ' ATTENTION ! Pensez à changer le statut des lignes spéciales liées',
                  );
                  setvalidationDeleteId(cellProps.row.original.id);
                  toogleValidation();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Admin Lemissa' breadcrumbItem='Liste des  Factures' />
        <TableContainer
          columns={columns}
          data={invoices}
          isGlobalFilter={true}
          customPageSize={20}
          className='custom-header-css'
        />
      </div>
      <ValidationPopup
        isOpen={validationOpen}
        toogle={toogleValidation}
        fct={onDelete}
        text={validationText}
      />
    </div>
  );
}

export default Factures;
