import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import { getTariffs, getTariffsSuccess, deleteTariff, deleteTariffSuccess } from './slice';

function* getTariffsSaga() {
  const response = yield call(httpClient.instance.get, '/tarifs');
  if (response.status !== undefined && response.status === 200) {
    yield put(getTariffsSuccess(response.data));
  }
}

function* deleteTariffSaga(action) {
  const tariffId = action.payload;
  const response = yield call(httpClient.instance.delete, `/tarifs/${tariffId}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteTariffSuccess(tariffId));
  }
}

export default function* tariffsSaga() {
  yield takeLatest(getTariffs, getTariffsSaga);
  yield takeLatest(deleteTariff, deleteTariffSaga);
}
