import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row, CardTitle, Form } from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumb';
import { fetchGet, fetchPost } from '../utility/apiFetch';

function AddLigneSpeciale(props) {
  const history = useHistory();

  const [users, setusers] = useState([]);
  const [dossiersListe, setdossiersListe] = useState([]);
  const [lignesListe, setlignesListe] = useState([]);
  const [unitesListe, setunitesListe] = useState([]);

  const [username, setusername] = useState('');
  const [dossier, setdossier] = useState('');
  const [typeLigne, settypeLigne] = useState('');
  const [quantite, setquantite] = useState('');
  const [unite, setunite] = useState('');
  const [periodeDateDebut, setperiodeDateDebut] = useState('');
  const [periodeDateFin, setperiodeDateFin] = useState('');
  const [commentaire, setcommentaire] = useState('');

  useEffect(() => settypeLigne(''), [dossier]);

  useEffect(() => {
    fetchGet('/users')
      .then((res) => setusers(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchGet('/dossiers')
      .then((res) => setdossiersListe(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchGet('/typelignes')
      .then((res) => setlignesListe(res))
      .catch((err) => console.log(err));
  }, []);

  // récupération des types de documents pour les unités - Penser à ajouter un regroupement de base pour toute Ligne pour les tarifs
  useEffect(() => {
    fetchGet('/regroupements')
      .then((res) => setunitesListe(res))
      .catch((err) => console.log(err));
  }, []);

  const onSend = () => {
    if (
      username != '' &&
      dossier != '' &&
      typeLigne != '' &&
      quantite != '' &&
      unite != '' &&
      periodeDateDebut != '' &&
      periodeDateFin != ''
    ) {
      const lignespeciale = {
        username,
        dossier,
        typeLigne,
        quantite,
        unite,
        periodeDateDebut,
        periodeDateFin,
        commentaire,
      };

      fetchPost('/lignesspec', lignespeciale)
        .then((res) => {
          history.push('/lignespeciale');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Admin Lemissa' breadcrumbItem='Ajouter une ligne speciale' />
        <Card>
          <CardBody>
            <CardTitle className='h5 card-title-desc'>Formulaire d'ajout</CardTitle>

            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <select
                      defaultValue=''
                      className='form-select'
                      onChange={(event) => setusername(event.target.value)}
                    >
                      <option value=''>Choisir le contributeur</option>
                      {users.map(({ username, nom, prenom }) => (
                        <option value={username}>{username + ' - ' + prenom + '  ' + nom}</option>
                      ))}
                    </select>
                    <label htmlFor='floatinguserIDGrid'>Contributeur</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <select
                      defaultValue=''
                      className='form-select'
                      onChange={(event) => setdossier(event.target.value)}
                    >
                      <option value=''>Choisir le Dossier</option>
                      {dossiersListe.map(({ dossier }) => (
                        <option value={dossier}>{dossier}</option>
                      ))}
                    </select>
                    <label htmlFor='floatingSelectGrid'>Choisir le Dossier</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <select
                      defaultValue=''
                      className='form-select'
                      onChange={(event) => settypeLigne(event.target.value)}
                    >
                      <option value=''>Choisir le type de ligne</option>
                      {lignesListe.map((ligne) => {
                        if (ligne.dossier === dossier)
                          return <option value={ligne.typeLigne}>{ligne.typeLigne}</option>;
                      })}
                    </select>
                    <label htmlFor='floatingSelectGrid'>Choisir le type de ligne</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingquantiteInput'
                      placeholder='Quantité'
                      defaultValue=''
                      onChange={(event) => setquantite(event.target.value)}
                    />
                    <label htmlFor='floatingquantiteInput'>Quantité</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    {/* <input type="text" className="form-control" id="floatinguniteInput" placeholder="Unité" defaultValue="" onChange={(event) => setunite(event.target.value)}/> */}
                    <select
                      defaultValue='EUR'
                      className='form-select'
                      onChange={(event) => setunite(event.target.value)}
                    >
                      <option value=''>Choisir l'unité</option>
                      {unitesListe.map((unite) => {
                        if (unite.dossier === dossier && unite.typeLigne === typeLigne)
                          return <option value={unite.typeDoc}>{unite.typeDoc}</option>;
                      })}
                    </select>
                    <label htmlFor='floatinguniteInput'>Unité</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='date'
                      className='form-control'
                      id='floatingdebutInput'
                      placeholder='Pays'
                      defaultValue=''
                      onChange={(event) => setperiodeDateDebut(event.target.value)}
                    />
                    <label htmlFor='floatingdebutInput'>Periode de Debut</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <input
                      type='date'
                      className='form-control'
                      id='floatingfinInput'
                      placeholder='Ville'
                      defaultValue=''
                      onChange={(event) => setperiodeDateFin(event.target.value)}
                    />
                    <label htmlFor='floatingfinInput'>Periode de Fin</label>
                  </div>
                </Col>
              </Row>

              <div className='form-floating mb-3'>
                <input
                  type='text'
                  className='form-control'
                  id='floatingcommentaireInput'
                  placeholder='Commentaire'
                  defaultValue=''
                  onChange={(event) => setcommentaire(event.target.value)}
                />
                <label htmlFor='floatingcommentaireInput'>Commentaire</label>
              </div>

              <div className='d-flex justify-content-end'>
                <Link
                  to='/lignespeciale'
                  className='m-2'
                  // ref='annuler'
                >
                  <button
                    onClick={(event) => this.refs.annuler.click()}
                    className='btn btn-secondary w-md'
                  >
                    Annuler
                  </button>
                </Link>
                <Link
                  to='#'
                  className='m-2'
                  // hidden={true}
                >
                  <button onClick={onSend} className='btn btn-primary w-md'>
                    Valider
                  </button>
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AddLigneSpeciale;
