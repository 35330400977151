import moment from 'moment';
import countries from 'i18n-iso-countries';
import frLocale from 'i18n-iso-countries/langs/fr.json';

const formateDate = (date, format) => {
  const dateFormat = format || 'DD/MM/YYYY';
  const date1 = moment(new Date(date)).format(dateFormat);
  return date1;
};

const previousDay = () => {
  return (
    new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() - 1)
  );
};

const actualDay = () => {
  return new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
};

// 1er jour du mois en cours, base d'affichage par défaut
const firstDayCurrentMonth = () => {
  return new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + '01';
};

// 1er jour du mois précédent
const startLastMonth = () => {
  return new Date().getFullYear() + '-' + new Date().getMonth() + '-' + '01'; // (new Date().getDate() - 30) ;
};

const formatEurFr = (number, digits) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(number);
};

// fonction de conversion text en boolean si le text vaut 'true' renvoie un bolean "True" sinon, non !
const readBoolean = (bool) => {
  let res;
  if (bool === true) {
    res = 'Oui';
  } else {
    res = 'Non';
  }
  return res;
};

countries.registerLocale(frLocale);
const allCountries = countries.getNames('fr', { select: 'official' });
const allCountriesAlpha2 = Object.entries(allCountries).map(([key, value]) => {
  return {
    value: key,
    name: value,
  };
});
const allCountriesAlpha3 = Object.entries(allCountries).map(([key, value]) => {
  return {
    value: countries.alpha2ToAlpha3(key),
    name: value,
  };
});

const isoToFullName = (name) => countries.getName(name, 'fr');

export {
  formateDate,
  previousDay,
  startLastMonth,
  formatEurFr,
  actualDay,
  readBoolean,
  firstDayCurrentMonth,
  allCountriesAlpha2,
  allCountriesAlpha3,
  isoToFullName,
};
