// Constitution du SidebarContent selon les droits Admin ou User

import React from 'react';

import SidebarContentAdmin from './SidebarContentAdmin';
import SidebarContentUser from './SidebarContentUser';

import { useAuth0 } from '@auth0/auth0-react';

// const { auth } = require('express-oauth2-jwt-bearer');

// Appeler le test sur les droits- Roles pour éviter la gestion en dur dans le code.
const SidebarContent = () => {
  const nickname = useAuth0().user?.nickname ?? null;
  const adminList = ['dsamba', 'vpetre', 'arkplai', 'skilld', 'mbrethenoux', 'nboukerouni', 'camaghnouj'];

  return adminList.includes(nickname) ? <SidebarContentAdmin /> : <SidebarContentUser />;
};

export default SidebarContent;
